import { useQuery } from 'react-query';
import { useState, useLayoutEffect } from 'react';

import styles from './ItemDetail.module.scss';
// import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import { faLock, faPlus, faSpinner, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import CollectionsService from "../../services/CollectionsService";
import PlayNowButton from "./../Buttons/PlayNowButton";
import StartTrainingButton from "./../Buttons/StartTrainingButton";
import FavButton from "./../Buttons/FavButton";
import ShareButton from "./../Buttons/ShareButton";
import RadicalModal from "../RadicalModal";
import TrainingPlan from "../TrainingPlan";
import RadicalImage from "../RadicalImage";

import useImageSelector from "./../../hooks/useImageSelector";
import useVideoProgress from "../../hooks/useVideoProgress"
import useMultiVideoProgress from "../../hooks/useMultiVideoProgress"
import UxService from "./../../services/UxService";
import ProfileService from "./../../services/ProfileService";
import Environment from "./../../services/Environment";
import { useIntl, FormattedMessage } from "react-intl";
import Metadata from "../Metadata";
import clsx from "clsx";
import DOMPurify from 'dompurify';

function SeasonSelect({ seasons, value, onSeasonChange }) {
    const [selected, setSelected] = useState(value);
    const intl = useIntl();

    const handleChange = (event) => {
        setSelected(event.target.value);
        if (onSeasonChange) {
            onSeasonChange(event.target.value);
        }
    };

    return (
        <select
            id="seasons"
            value={selected}
            onChange={handleChange}
            className={styles.seasonSelect}
        >
            {
                seasons.map((season, index) => (
                    <option key={index} value={index}>{UxService.localeValue(season, "name") || intl.formatMessage({ id: "app.messages.season_ix" }, { index: index + 1 })}</option>
                ))
            }

        </select>
    );
}

function ItemShareButton({ onShare, id, name }) {
    const intl = useIntl();
    const shareTitle = intl.formatMessage({ id: "app.messages.share_message" }, { name });

    if (onShare) {
        return <ShareButton onClick={onShare} {...CollectionsService.shareDataForSlide(id, shareTitle)} />;
    } else {
        return <></>;
    }
}

function SingleItemDetail({ fav, metadata, onFav, id, name, description, image, onClose, imageSrcSet, blurhash, onPlay, onShare, unavailableForGuest }) {
    const progress = useVideoProgress(id);

    return <PureSingleItemDetail unavailableForGuest={unavailableForGuest} imageSrcSet={imageSrcSet} blurhash={blurhash} metadata={metadata} fav={fav} onShare={onShare} onFav={onFav} id={id} progress={progress} description={description} name={name} image={image} onClose={onClose} onPlay={onPlay} />
}

function PureSingleItemDetail({ id, metadata, name, onShare, image, imageSrcSet, blurhash, onClose, fav, onFav, onPlay, progress, description, unavailableForGuest }) {

    return <RadicalModal onClose={onClose} title={name}>
        <div className={styles.header}>
            <RadicalImage sizes="radicalModal" img={image} blurhash={blurhash} imgSrcSet={imageSrcSet} />
            <div className={styles.itemBackground}></div>
            <div style={{ width: progress.progress + "%" }} className={styles.progress}></div>
            <div className={styles.actions}>

                <PlayNowButton className={styles.playButton} onClick={() => (onPlay(id))} />
                {onFav != null && <FavButton checked={fav} className={styles.favButton} onClick={onFav} />}
                <div className={styles.shareButton}><ItemShareButton onShare={onShare} name={name} id={id} /></div>
                {unavailableForGuest && <FontAwesomeIcon icon={faLock} size={'2x'} color={Environment.brandColor} />}
            </div>

        </div>
        <div className={styles.detailContainer}>
            <div className={styles.slideDescription}>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
            </div>
            <Metadata metadata={metadata} />
        </div>

    </RadicalModal >
}

function Chapter({ id, data, index, onPlay, locked, seen }) {
    const progress = useVideoProgress(id);

    return <PureChapter id={id} data={data} index={index} onPlay={locked ? (() => { }) : onPlay} progress={progress} locked={locked} seen={seen} />
}

function PureChapter({ id, data, index, onPlay, progress, locked, seen }) {
    const legacyImage = useImageSelector(data.images);
    const name = UxService.localeValue(data, "name")
    const description = UxService.localeValue(data, "description")
    const image = UxService.localeImageSet(data, "images_video_preview", null, {
        xs: 148,
        s: 222,
        m: 296,
        l: 592,
        xl: 1184,
    });
    const isNew = data.is_new;
    const unavailableForGuest = data.guest_unavailable;

    return (
        <div className={clsx(styles.chapter, locked && styles.chapterLocked, seen && styles.chapterSeen)} onClick={() => onPlay(id)}>
            {/* <div className={styles.index}>{index}</div> */}
            <div className={styles.imageContainer}>
                <RadicalImage img={(image && image.default) || legacyImage} imgSrcSet={image && image.srcset} blurhash={image && image.blurhash} sizes="chapterVideoPreview" />
                {!locked && <FontAwesomeIcon icon={faPlayCircle} className={styles.playIcon} />}
                {seen && <FontAwesomeIcon icon={faCheckCircle} />}
                {locked && <FontAwesomeIcon icon={faLock} />}
                <div style={{ width: progress.progress + "%" }} className={styles.progress}></div>
            </div>
            <div className={styles.chapterTextContainer}>
                <div className={styles.title}>{unavailableForGuest && <div style={{}}><FontAwesomeIcon icon={faLock} size={'1x'} color={Environment.brandColor} /></div>}<span>#{index}:&nbsp;</span>{name}</div>
                {isNew && <div className={styles.isNew}>
                    <FontAwesomeIcon icon={faPlus} />&nbsp;<FormattedMessage id="app.messages.is_new_title_single" />
                </div>}
                <p className={styles.chapterDescription}><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} /></p>
            </div>
        </div>
    );
}

function Seasons({ seasons, selectedSeasson, onPlay, onSeasonChange, isTrainingPlan, trainingPlanSeen, trainingPlanId }) {
    const multiple = seasons.length > 1;
    const seassonToRender = seasons[selectedSeasson || 0];

    const trainingPlanCurrent = isTrainingPlan && CollectionsService.currentVideoForTrainingPlan(seasons, trainingPlanSeen);
    const trainingPlanCurrentId = trainingPlanCurrent && trainingPlanCurrent.id;

    return (
        <div className={styles.seasonsContainer}>
            <div className={styles.seasonsHeaderContainer}>
                {multiple && <div className={styles.seasonSelector}>
                    <span><FormattedMessage id="app.messages.showing_season_videos" />&nbsp;</span>
                    <SeasonSelect seasons={seasons} value={selectedSeasson} onSeasonChange={onSeasonChange} />
                </div>}

            </div>

            <div className={styles.seassonContents}>
                {seassonToRender.chapters.map((chapter, index) => {
                    let locked = false;
                    let seen = false;
                    if (isTrainingPlan) {
                        if (!trainingPlanId) {
                            locked = true;
                        } else {
                            locked = (trainingPlanSeen.indexOf(chapter.id) < 0 && chapter.id !== trainingPlanCurrentId);
                            seen = !locked && chapter.id !== trainingPlanCurrentId
                        }
                    }

                    return <Chapter seen={seen} locked={locked} onPlay={onPlay} index={index + 1} id={chapter.id} key={chapter.id} data={chapter} />
                })}
            </div>
        </div>
    );
}

function MultipleItemHeader({ unavailableForGuest, name, imageSrcSet, blurhash, seasons, trainingPlanId, isTrainingPlan, metadata, id, fav, onFav, description, image, onPlay, nextVideoContinue, nextVideoId, nextVideoName, multiSeason, nextVideoSeasonName, onShare }) {

    const inTraining = isTrainingPlan && (trainingPlanId != null);
    const [submitTrainingPlan, setSubmitTrainingPlan] = useState({ inProgress: false });

    const startPlan = () => {
        setSubmitTrainingPlan({
            ...submitTrainingPlan,
            inProgress: true,
            error: null,
            rv: null,
        });

        ProfileService.startTraining(id).then((rv) => {
            setSubmitTrainingPlan({
                ...submitTrainingPlan,
                inProgress: false,
                error: false,
                rv: rv,
            });
        }).catch(() => {
            setSubmitTrainingPlan({
                ...submitTrainingPlan,
                inProgress: false,
                error: true,
                rv: null,
            });
        });
    };

    const showStartTrainingButton = isTrainingPlan && !inTraining;
    return (
        <div>
            <div className={styles.header}>

                <RadicalImage sizes="radicalModal" img={image} blurhash={blurhash} imgSrcSet={imageSrcSet} />
                <div className={styles.itemBackground}></div>

                <div className={styles.actions}>
                    {!showStartTrainingButton && <PlayNowButton className={styles.playButton} onClick={() => (onPlay(nextVideoId))} />}
                    {showStartTrainingButton && (
                        <StartTrainingButton
                            className={styles.playButton}
                            error={submitTrainingPlan.error}
                            loading={submitTrainingPlan.inProgress}
                            onClick={!unavailableForGuest ? startPlan : () => (onPlay(nextVideoId))}
                        />
                    )}
                    {onFav != null && <FavButton checked={fav} className={styles.favButton} onClick={onFav} />}
                    <div className={styles.shareButton}><ItemShareButton onShare={onShare} name={name} id={id} /></div>
                    {unavailableForGuest && <FontAwesomeIcon icon={faLock} className={styles.lockIcon} color={Environment.brandColor} />}
                </div>
            </div>

            <div className={styles.detailContainer}>
                <div className={styles.nextVideoTextContainer}>
                    <div className={styles.nextVideoIcon}>
                        <FormattedMessage id={nextVideoContinue ? "app.messages.continue" : "app.messages.next"} />
                    </div>
                    <div className={styles.nextVideoName}>{nextVideoName}</div>
                    {multiSeason && <div className={styles.nextVideoWeek}>{nextVideoSeasonName}</div>}
                </div>
                <div className={styles.slideDescription}>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
                </div>
                <Metadata metadata={metadata} />
                {inTraining && <TrainingPlan slideId={id} seasons={seasons} />}
            </div>
        </div>);
}

function MultipleItemHeaderDefault({ image, imageSrcSet, blurhash }) {
    return (
        <div className={styles.header}>
            <RadicalImage img={image} blurhash={blurhash} imgSrcSet={imageSrcSet} />
        </div>);
}

function MultipleItemDetail({ unavailableForGuest, imageSrcSet, blurhash, isTrainingPlan, trainingPlanId, trainingPlanSeen, fav, metadata, onFav, id, name, image, description, onClose, onPlay, season, onSeasonChange, shared, onShare }) {
    const queryKey = !shared ? "slide" : "sharedSlideContents"
    const slideQuery = useQuery([queryKey, id], () => (!shared ? CollectionsService.slide(id) : CollectionsService.sharedSlideContents(id)));

    const ids = slideQuery.isSuccess && slideQuery.data.seasons.map((season) => (season.chapters.map((c) => c.id))).flat();
    const progress = useMultiVideoProgress(ids || [])

    const continueFrom = slideQuery.isSuccess && CollectionsService.startVideoForslide(slideQuery.data, progress, isTrainingPlan && trainingPlanId, trainingPlanSeen);
    const seasonToShow = season || (continueFrom && continueFrom.season) || 0;
    const nextVideoId = continueFrom && continueFrom.videoId;
    const nextVideoContinue = nextVideoId && progress && progress[nextVideoId] && progress[nextVideoId].progress > 0;

    return <RadicalModal onClose={onClose} title={name}>
        {(slideQuery.isLoading || !slideQuery.isSuccess) && <MultipleItemHeaderDefault unavailableForGuest={unavailableForGuest} image={image} imageSrcSet={imageSrcSet} blurhash={blurhash} />}
        {slideQuery.isSuccess && <MultipleItemHeader unavailableForGuest={unavailableForGuest} seasons={slideQuery.data.seasons} imageSrcSet={imageSrcSet} blurhash={blurhash} trainingPlanId={trainingPlanId} isTrainingPlan={isTrainingPlan} metadata={metadata} name={name} id={id} onShare={onShare} description={description} onFav={onFav} fav={fav} multiSeason={slideQuery.data.seasons.length > 1} image={image} onPlay={onPlay} nextVideoContinue={nextVideoContinue} nextVideoId={nextVideoId} nextVideoSeasonName={continueFrom && UxService.localeValue(continueFrom.seasonData, "name")} nextVideoName={continueFrom && UxService.localeValue(continueFrom.video, "name")} />}

        <div className={styles.more}>
            {slideQuery.isLoading && <div className={styles.content_loading}>
                <FontAwesomeIcon icon={faSpinner} pulse />&nbsp;
                <FormattedMessage id="app.messages.loading_item_detail_content" />
            </div>}
            {slideQuery.isError && <div className={styles.content_error}>
                <FontAwesomeIcon icon={faTimesCircle} />&nbsp;
                <FormattedMessage id="app.messages.loading_item_detail_content_error" />
            </div>}
            {slideQuery.isSuccess && <Seasons isTrainingPlan={isTrainingPlan} trainingPlanId={trainingPlanId} trainingPlanSeen={(isTrainingPlan && trainingPlanId) ? trainingPlanSeen : []} selectedSeasson={seasonToShow} onSeasonChange={(season) => (onSeasonChange(season))} onPlay={onPlay} seasons={slideQuery.data.seasons} />}
        </div>
    </RadicalModal>
}

function ItemDetail({ id, name, unavailableForGuest, trainingPlanId, trainingPlanSeen, isTrainingPlan, description, image, imageSrcSet, blurhash, onClose, onPlay, single, season, onSeasonChange, at, fav, onFav, shared, onShare, metadata }) {
    useLayoutEffect(() => {
        // SEE: https://stackoverflow.com/questions/42308209/chrome-browser-automatically-scrolling-down-the-content-when-nobody-asked-it-to
        setTimeout(function () { window.scrollTo(0, 0); }, 1)
    }, [id]);

    if (!image) {
        image = Environment.defaultImages.slide;
    }

    if (single) {
        return <SingleItemDetail unavailableForGuest={unavailableForGuest} id={id} metadata={metadata} image={image} imageSrcSet={imageSrcSet} blurhash={blurhash} name={name} description={description} onShare={onShare} onClose={onClose} onPlay={onPlay} fav={fav} onFav={onFav} shared={shared} />
    } else {
        return <MultipleItemDetail unavailableForGuest={unavailableForGuest} id={id} trainingPlanSeen={trainingPlanSeen} trainingPlanId={trainingPlanId} isTrainingPlan={isTrainingPlan} metadata={metadata} image={image} imageSrcSet={imageSrcSet} blurhash={blurhash} name={name} description={description} onShare={onShare} onClose={onClose} onPlay={onPlay} season={season} onSeasonChange={onSeasonChange} fav={fav} onFav={onFav} shared={shared} />
    }
}

export default ItemDetail;