import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'

import styles from './CloseButton.module.scss';

function CloseButton({ onClick, className }) {
    return (
        <span className={styles.root + " " + (className || "")}  onClick={onClick}>
            <FontAwesomeIcon icon={faTimesCircle} />
        </span>
    );
}

export default CloseButton;