import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { selectors } from "../../store"
import { useSelector } from 'react-redux'
import { useIntl } from "react-intl";
// import { PinInput } from 'react-input-pin-code';
import Button from '@material-ui/core/Button'
import SessionService from '../../services/SessionService';
import backdrop from '../Welcome/res/img/rhf_back.jpg';
import styles from './AppTvLink.module.scss';
import MaterialUiThemeLogin from "../MaterialUiThemeLogin";
import PinField from "react-pin-field"

function AppTvLink({ locale }) {
    const profile = useSelector(selectors.session.profile);
    const intl = useIntl();
    
    const [showCompleteMessage, setShowCompleteMessage] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const [code,setCode] = useState("");
    const buttonRef = useRef();
    const pinRef = useRef();

    const invalid = useMemo(() => {
        const codeAsString = code;
        const valid = (codeAsString.length === 8);
        return !valid;

    },[code]);

    const onClick = useCallback(() => {
        setShowCompleteMessage(invalid);
        if (invalid) return;

        setError(null);
        setSuccess(false);
        setInProgress(false);

        SessionService.linkTv(code).then(() => {
            setError(null);
            setSuccess(true);
            setInProgress(false);
        }).catch((error) => {
            setError(error);
            setSuccess(false);
            setInProgress(false);
            if (pinRef.current) {
                pinRef.current[0].focus();
            }
        });

    }, [code, invalid, setShowCompleteMessage, pinRef]);

    const onChange = useCallback((values) => {
        setShowCompleteMessage(false);
        setError(null);
        setCode(values)
    }, [setCode, setShowCompleteMessage]);

    const onComplete = useCallback(() => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    }, [buttonRef]);

    useEffect(() => {
        if (pinRef.current) {
            pinRef.current[0].focus();
        }
    }, [pinRef]);

   
    
    let contents =  (
        <div>
            <div><p className={styles.textInstructions}>{intl.formatMessage({ id: "app.messages.linking_to_tv_success" })}</p></div>
        </div>
    );

    if (!success) {
        contents = (
            <div>
                <div><p className={styles.textInstructions}>{intl.formatMessage({ id: "app.messages.tv_link_explain" })}</p></div>

                {showCompleteMessage && (
                    <div><p className={styles.error}>{intl.formatMessage({ id: "app.messages.complete_code" })}</p></div>
                )}

                {error && (
                    <div><p className={styles.error}>{intl.formatMessage({ id: "app.messages.linking_to_tv_error" })}</p></div>
                )}

                <div style={{ display: "flex", justifyContent: "center", marginBottom: "2em" }}>
                {/* <PinInput
                    inputStyle={{  borderTopWidth:0,borderLeftWidth:0,borderRightWidth:0,borderRadius:0,borderBottomWidth:2,color:"white" }}
                    values={code}
                    autoFocus 
                    size="lg"
                    disabled={inProgress}
                    onChange={onChange}
                    onComplete={onComplete}
                    focusBorderColor="#5fb1df"
                    validBorderColor="#5fb1df"
                    errorBorderColor="#aa2222"
                /> */}
                 <PinField
                    ref={pinRef}
                    className={styles.pinField}
                    validate="0123456789"
                    inputMode="numeric"
                    length={8}
                    onChange={onChange}
                    onComplete={onComplete}
                />
                </div>

                <div style={{ display: "flex", marginTop: "1em", justifyContent: "center" }}>
                    <Button ref={buttonRef} disabled={inProgress} variant="contained" onClick={onClick}>
                        {intl.formatMessage({ id: !inProgress ? "app.fields.link_tv" : "app.messages.linking_to_tv" })}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <MaterialUiThemeLogin>
            <div className={styles.loginContainer} style={{ background: `url(${backdrop}) no-repeat center center/cover` }}>
                <div className={styles.formContainer}>
                    {contents}
                </div>
            </div>
        </MaterialUiThemeLogin>
    );
}

export default AppTvLink;