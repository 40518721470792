import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import LoadingWithText from "../../components/LoadingWithText";
import ErrorWithText from "../../components/ErrorWithText";
import SessionService from "../../services/SessionService";
import Environment from "../../services/Environment";

function Bad({ tenant, url }) {
    const intl = useIntl();

    return <ErrorWithText
        center={true}
        textSmall={intl.formatMessage({ id: "app.messages.bad_gym" })}
    />;
}

function Retry({ onRetry }) {
    const intl = useIntl();

    return <ErrorWithText
        center={true}
        textSmall={intl.formatMessage({ id: "app.messages.retry_gym" })}
        textBig={intl.formatMessage({ id: "app.messages.retry_click_here" })}
        onBigClick={onRetry}
    />;
}

function InProgress() {
    const intl = useIntl();

    return <LoadingWithText
        center={true}
        textSmall={intl.formatMessage({ id: "app.messages.loading_gym" })}
        textBig={intl.formatMessage({ id: "app.messages.loading_gym_more" })}
    />;
}

function TenantLoader({ childrenRender, fallback }) {
    const { franchiseCode, slug } = useParams();
    const valid = (slug) || fallback;

    const [tenantData, setTenantData] = useState({
        inProgress: false,
        tainted: false,
        error: null,
        data: null,
    });

    const tainted = tenantData.tainted;
    useEffect(() => {
        if (valid && !tainted) {
            setTenantData((tenantData) => ({
                ...tenantData,
                tainted: true,
                inProgress: true
            }));

            let tenantLoad;
            if (Environment.isTv) {
                tenantLoad = fallback && !slug ? SessionService.fallbackLandingData() : SessionService.tenantLandingData(franchiseCode, slug);
            } else {
                tenantLoad = fallback ? SessionService.fallbackLandingData() : SessionService.tenantLandingData(franchiseCode, slug);
            }
            

            tenantLoad.then((data) => {
                setTenantData((tenantData) => ({
                    ...tenantData,
                    tainted: true,
                    inProgress: false,
                    data: data,
                    error: null,
                }));
            }).catch((error) => {
                setTenantData((tenantData) => ({
                    ...tenantData,
                    tainted: true,
                    inProgress: false,
                    data: null,
                    error: error.serialize(),
                }));
            });
        }
    }, [valid, tainted, franchiseCode, slug, setTenantData, fallback])


    if (valid) {
        if (tenantData.inProgress || !tenantData.tainted) {
            return <InProgress />;
        } else if (tenantData.error) {
            if (!tenantData.error.notFound) {
                const onRetry = () => {
                    setTenantData((tenantData) => ({
                        tainted: false,
                        inProgress: false
                    }));
                };

                return <Retry onRetry={onRetry} />;
            } else {
                return <Bad tenant={true} />;
            }
        } else {
            return childrenRender(tenantData.data, () => (window.location.href = '/login'));
        }
    } else {
        return <Bad url={true} />; //<div>INVALID URL</div>;
    }

}

export default TenantLoader;