import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-solid-svg-icons';

import {
    // EmailShareButton,
    FacebookShareButton,
    // HatenaShareButton,
    // InstapaperShareButton,
    // LineShareButton,
    // LinkedinShareButton,
    // LivejournalShareButton,
    // MailruShareButton,
    // OKShareButton,
    // PinterestShareButton,
    // PocketShareButton,
    // RedditShareButton,
    // TelegramShareButton,
    // TumblrShareButton,
    TwitterShareButton,
    // ViberShareButton,
    // VKShareButton,
    WhatsappShareButton,
    // WorkplaceShareButton
} from "react-share";

import {
    // EmailIcon,
    FacebookIcon,
    // FacebookMessengerIcon,
    // HatenaIcon,
    // InstapaperIcon,
    // LineIcon,
    // LinkedinIcon,
    // LivejournalIcon,
    // MailruIcon,
    // OKIcon,
    // PinterestIcon,
    // PocketIcon,
    // RedditIcon,
    // TelegramIcon,
    // TumblrIcon,
    TwitterIcon,
    // ViberIcon,
    // VKIcon,
    // WeiboIcon,
    WhatsappIcon,
    // WorkplaceIcon
} from "react-share";

import styles from './ShareButton.module.scss';
import { FormattedMessage } from "react-intl";
import Popup from "reactjs-popup";

// import radicalLogo from './res/img/radical-logo.png';


function ShareButton({ url, title, className }) {
    return (
        <Popup
            trigger={<span className={styles.share + " " + (className || "")} ><FontAwesomeIcon icon={faShareAlt} /></span>}
            position="right center"
            on="click"
            closeOnDocumentClick
            closeOnEscape
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            contentStyle={{ padding: '0px', border: 'none' }}
            arrow={true}
        >
            <div className={styles.menu}>
                <div className={styles.menuItem}>
                    <FacebookShareButton title={title} url={url}><FacebookIcon size="24px"/></FacebookShareButton>
                </div>
                <div className={styles.menuItem}>
                    <TwitterShareButton title={title} url={url}><TwitterIcon size="24px"/></TwitterShareButton>
                </div>
                <div className={styles.menuItem}>
                    <WhatsappShareButton title={title} url={url}><WhatsappIcon size="24px"/></WhatsappShareButton>
                </div>
                {/* <div className={styles.menuItem}>
                    <EmailShareButton title={title} url={url}><EmailIcon /></EmailShareButton>
                </div> */}
            </div>
        </Popup>
    );
}


// function ExShareButton({ onClick, className }) {
//     return (
//         <span className={styles.root + " " + (className || "")} onClick={onClick}>
//             <FontAwesomeIcon icon={faShareAlt} />
//         </span>
//     );
// }

export default ShareButton;