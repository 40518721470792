import React, { useEffect, useState } from 'react';

import ShareLanding from "./../ShareLanding";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";

import LoadingWithText from "../../components/LoadingWithText";
import ErrorWithText from "../../components/ErrorWithText";
import CollectionsService from "../../services/CollectionsService";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
// TODO: remove duplicated code from TenantLoader

function Bad({ gym, url }) {
    const intl = useIntl();

    return <ErrorWithText
        center={true}
        textSmall={intl.formatMessage({ id: "app.messages.bad_gym" })}
    />;
}

function Retry({ onRetry }) {
    const intl = useIntl();

    return <ErrorWithText
        center={true}
        textSmall={intl.formatMessage({ id: "app.messages.retry_gym" })}
        textBig={intl.formatMessage({ id: "app.messages.retry_click_here" })}
        onBigClick={onRetry}
    />;
}

function InProgress() {
    const intl = useIntl();

    return <LoadingWithText
        center={true}
        textSmall={intl.formatMessage({ id: "app.messages.loading_gym" })}
        textBig={intl.formatMessage({ id: "app.messages.loading_gym_more" })}
    />;
}

const AppShareLanding = ({ gym }) => {
    const { slideId } = useParams();
    const navigation = useRadicalNavigation();

    const [slide, setSlide] = useState({
        inProgress: false,
        tainted: false,
        error: null,
        data: null,
    });

    const tainted = slide.tainted;
    useEffect(() => {
        if (!tainted) {
            setSlide((slide) => ({
                ...slide,
                tainted: true,
                inProgress: true
            }));
            CollectionsService.sharedSlideInfo(slideId).then((data) => {
                setSlide((slide) => ({
                    ...slide,
                    tainted: true,
                    inProgress: false,
                    data: data,
                    error: null,
                }));
            }).catch((error) => {
                // console.log(error.serialize());

                setSlide((slide) => ({
                    ...slide,
                    tainted: true,
                    inProgress: false,
                    data: null,
                    error: error.serialize(),
                }));
            });
        }
    }, [tainted, slideId, setSlide])



    if (slide.inProgress || !slide.tainted) {
        return <InProgress />;
    } else if (slide.error) {
        if (!slide.error.notFound) {
            const onRetry = () => {
                setSlide((slide) => ({
                    tainted: false,
                    inProgress: false
                }));
            };

            return <Retry onRetry={onRetry} />;
        } else {
            return <Bad gym={true} />;
        }
    } else {
        const onLogin = () => {
            navigation.loginTo(gym, slide.data.id);
        }
        return <ShareLanding gym={gym} slide={slide.data} onLogin={onLogin}/>
    }
    

   
}

export default AppShareLanding;