import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import Environment from '../../../services/Environment';

import styles from './PlayNowButton.module.scss';

function PlayNowButton({ onClick, className }) {

    return (
        <div onClick={onClick} className={Environment.brand === "rhf" ? styles.root : styles.rootRftv + " " + (className ? className : "")}><FontAwesomeIcon icon={faPlay} /><FormattedMessage id="app.buttons.play_now" /></div>
    );
}

export default PlayNowButton;