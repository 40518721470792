import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import UserProfile from "../UserProfile";
import ProfileService from "../../services/ProfileService";
import SessionService from "../../services/SessionService";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import { useIntl } from "react-intl";

import { selectors } from "../../store"
import { useSelector } from 'react-redux'


const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

const round = (n, d) => Math.round(n * Math.pow(10, d)) / Math.pow(10, d)

const translateHeight = (mts, value, vin) => {
    if (value === undefined || value === null || !isNumeric(value)) return value;

    if (mts) {
        if (vin) {
            return round(value / 100, 2)
        } else {
            return round(value * 100, 2)
        }
    } else {
        // 100cm = 3.28 ft

        if (vin) {
            return round((value * 3.28) / 100, 2)
        } else {
            return round((value * 100) / 3.28, 2)
        }
    }
};

const translateWeight = (kgs, value, vin) => {
    if (value === undefined || value === null || !isNumeric(value)) return value;

    if (kgs) {
        return value;
    } else {
        // 1kg = 2.20462262185 pounds
        if (vin) {
            return round(value * 2.20462262185, 2);
        } else {
            return round(value / 2.20462262185, 2);
        }
    }
};


const mtsForUser = (intl, franchise) => {
    if (franchise === "USA" || franchise === "UKD") {
        return false;
    } else {
        return true;
    }

    // return (intl.formatMessage({ id: "app.formatMessage.height" }) === "mts");
}

const kgsForUser = (intl, franchise) => {
    if (franchise === "USA" || franchise === "UKD") {
        return false;
    } else {
        return true;
    }

    // return (intl.formatMessage({ id: "app.formatMessage.weight" }) === "kgs");
}

const dateFormatForUser = (intl, franchise) => {
    if (franchise === "TWN") {
        return "MM/dd/yyyy";
    }

    if (franchise === "JPN") {
        return "yyyy/MM/dd";
    }

    if (franchise === "ARG" || franchise === "CAN" || franchise === "FRA" || franchise === "ESP") {
        return "dd/MM/yyyy";
    }

    return intl.formatMessage({ id: "app.formatMessage.date_picker" });
}

const AppUserProfile = (props) => {
    const navigation = useRadicalNavigation();
    const backToLibrary = () => {
        navigation.main();
    };

    const stats = useSelector(selectors.session.profileStats);
    const [inProgress, setInProgress] = useState(false);
    const [errors, setErrors] = useState({});

    const intl = useIntl();
    const franchise = SessionService.tenantFromSessionData().franchiseCode.toUpperCase();
    const mts = mtsForUser(intl, franchise);
    const kgs = kgsForUser(intl, franchise);
    const dateFormat = dateFormatForUser(intl, franchise);

    const onSave = (stats) => {
        setInProgress(true);
        setErrors({});
        let statsToSave = { ...stats };
        if (statsToSave.optIn) {
            statsToSave.empty = false;
            statsToSave.height = translateHeight(mts, statsToSave.height, false);
            statsToSave.weight = translateWeight(kgs, statsToSave.weight, false);
        } else {
          statsToSave = { empty: true };
        }

        ProfileService.updateStats(statsToSave).then(() => {
            navigation.main();
        }).catch(error => {
            setInProgress(false);
            const e = error.serialize();
            setErrors(e.errorData || {global: e.message});
        });
         
    };

    const translatedStats = {
        ...stats,
        height: translateHeight(mts, stats.height, true),
        weight: translateWeight(kgs, stats.weight, true),
    }

    return <CSSTransition appear={true} in={true} timeout={200} classNames="popup-profile-fade">
        <UserProfile dateFormat={dateFormat} kgs={kgs} mts={mts} errors={errors} inProgress={inProgress} stats={translatedStats} onSave={onSave} onCancel={backToLibrary} {...props} />
    </CSSTransition>
}

export default AppUserProfile;