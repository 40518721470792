import Environment from "./Environment";

const SERVER_URL = Environment.serverUrl;

const GuestData = {
    writeField(field, value) {
        return localStorage.setItem(field, value);
    },

    readField(field) {
        return localStorage.getItem(field);
    },

    toggleFav(add, remove) {
        const favsAsSet = new Set(this.favs());
        
        add.forEach((fav) => favsAsSet.add(fav));
        remove.forEach((fav) => favsAsSet.delete(fav));
        localStorage.setItem("guest-favs", JSON.stringify(Array.from(favsAsSet)));
    },

    favs() {
        const favs = JSON.parse(localStorage.getItem("guest-favs"));
        return Symbol.iterator in Object(favs) ? favs : [];
    }


}

let accessToken = null;
let locale = null;
let isGuest = false;

function serializeQuery(params, prefix) {
    const query = Object.keys(params).map((key) => {
        const value = params[key];

        if (params.constructor === Array)
            key = `${prefix}[]`;
        else if (params.constructor === Object)
            key = (prefix ? `${prefix}[${key}]` : key);

        if (typeof value === 'object')
            return serializeQuery(value, key);
        else
            return `${key}=${encodeURIComponent(value)}`;
    });

    return [].concat.apply([], query).join('&');
}

class ServerError extends Error {
    constructor(message, network, status, innerError = null, errorData) {
        super(message); // (1)
        this.name = "ServerError"; // (2)
        this.network = network;
        this.status = status;
        this.innerError = innerError;
        this.errorData = errorData;
    }

    notFound() {
        return !this.network && this.status === 404;
    }

    serialize() {
        return { 
            errorData: this.errorData,
            message: this.message,
            network: this.network,
            status: this.status,
            notFound: this.notFound(),
        }
    }
}

const Server = {
    setLocale(l) {
        locale = l;
    },

    post(path, data, anonymous = false) {
        return this.fetch(path, data, { anonymous, method: 'post' });
    },

    get(path, anonymous = false) {
        return this.fetch(path, null, { anonymous, method: 'get' });
    },

    delete(path, anonymous = false) {
        return this.fetch(path, null, { anonymous, method: 'delete' });
    },

    // - handle unauthenticated erros
    // - try to use refreshtoken if necessary
    fetch(path, data, options) {
        const extraHeaders = { ...(options.extraHeaders || {})};
        const extraOptions = {};

        if (!options.anonymous) {
            extraHeaders.Authorization = `Bearer ${accessToken}`;
        }
        if (data) {
            extraOptions.body = JSON.stringify(data);
        }

        return fetch(`${SERVER_URL}${path}`, {
            method: options.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RHF-LANGUAGE': locale,
                "RHF-MEDIUM": "web",
                "RHF-IS-TV": Environment.isTv,
                ...extraHeaders
            },
            ...extraOptions,
        }).then((response) => {
            return response.json().then((json) => ({
                data: json,
                response: response
            }));
        }).catch((error) => {
            throw new ServerError("Server Error", true, null, error);
        }).then(({ data, response }) => {
            if (response.status === 401 && !options.anonymous && !options.noRetry && !options.bestEffort) {
                if (this.onAuthenticationError) {
                    return this.onAuthenticationError().then(() => {
                        // - retry call
                        return this.fetch(path, data, { ...options, noRetry: true });
                    }).catch((error) => {
                        throw new ServerError(data.error || "Server Error", false, response.status)
                    });
                }
            }

            if (response.status !== 200 && response.status !== 201 && !options.bestEffort) {
                const maybeData = data || {};
                throw new ServerError(maybeData.error || "Server Error", false, response.status, null, maybeData.errorData);
            }

            return { data, response };
        });
    },

    updateAccessToken(newAccessToken, newIsGuest = false) {
        accessToken = newAccessToken;
        isGuest = newIsGuest;
    }
}

const Collections = {
    server: Server,
    page(pageIndex) {
        return this.server.get(`/collections/${pageIndex}`).then(({ data, response }) => {
            return data;
        })
    },
    signedStreamUrl(signedVideoId) {
        return this.server.post(`/collections/videos/sign`, { video: signedVideoId }).then(({ data, response }) => {
            return data;
        })
    },
    slide(slideId) {
        return this.server.get(`/collections/slide/${slideId}`).then(({ data, response }) => {
            return data;
        })
    },
    sharedSlideInfo(slideId) {
        return this.server.get(`/collections/shared/slide/${slideId}/info`).then(({ data, response }) => {
            return data;
        })
    },
    sharedSlideContents(slideId) {
        return this.server.get(`/collections/shared/slide/${slideId}/contents`).then(({ data, response }) => {
            return data;
        })
    },
    suggestions(slideId, videoId) {
        return this.server.get(`/collections/suggestions/${slideId}/${videoId}`).then(({ data, response }) => {
            return data;
        })
    }
};

const Tenants = {
    server: Server,
    defaultLandingData() {
        return this.server.get("/tenants/landing/default", true).then(({ data, response }) => {
            return data;
        })
    },
    sellerlandingData(slug) {
        return this.server.get(`/tenants/landing/${slug}`, true).then(({ data, response }) => {
            return data;
        })
    },
    gymLandingData(franchiseCode, slug) {
        return this.server.get(`/tenants/landing/${franchiseCode}/${slug}`, true).then(({ data, response }) => {
            return data;
        })
    }
}

const Search = {
    server: Server,
    search(page, q, metadata) {

        const qs = serializeQuery({
            ...metadata,
            q,
            page
        });
        return this.server.get(`/search?${qs}`).then(({ data, response }) => {
            return data;
        })
    }
}

const tokensFromHeaders = (headers) => {
    const refreshToken = headers.get("Refresh-Token");
    const expireAt = headers.get("Expire-At");
    const accessToken = headers.get("Access-Token");

    return {
        refreshToken,
        expireAt,
        accessToken
    }
}

const Progress = {
    server: Server,
    report(progress, playSessions, completed) {
        if (!isGuest) {
            return this.server.fetch("/progress/report", { progress, playSessions, completed }, { method: "post", bestEffort: true });
        } else {
            console.log(progress);
            console.log(playSessions);
            console.log(completed);
            return Promise.resolve(true);
        }
    }
}

const Fav = {
    server: Server,
    report(favs) {
        if (!isGuest) {
            return this.server.fetch("/fav/report", { favs: favs }, { method: "post", bestEffort: true });
        } else {
            const add = favs.filter((fav) => (fav.checked)).map((fav) => (fav.slideId));
            const remove = favs.filter((fav) => (!fav.checked)).map((fav) => (fav.slideId));
            GuestData.toggleFav(add, remove);
            return Promise.resolve(true);
        }
    }
}

const Training = {
    server: Server,
    start(slide_id) {
        return this.server.post(`/training/start/${slide_id}`, {});
    },

    close(slide_id) {
        return this.server.post(`/training/close/${slide_id}`, {});
    },

    restart(slide_id) {
        return this.server.post(`/training/restart/${slide_id}`, {});
    }
}

const Profile = {
    server: Server,
    updateStats(stats) {
        return this.server.post("/profile/stats", { stats }).then(({ data }) => {
            return data; 
        });
    },

    acceptTyc(version) {
        if (!isGuest) {
            return this.server.fetch(`/profile/tyc/${version}`, {}, { method: "post", bestEffort: true });
        } else {
            GuestData.writeField("guest-tyc-version", version);
        }
    },

    get() {
        return this.server.get("/profile").then((profile) => {
            if (isGuest) {
                const lastAckTycVersion = GuestData.readField("guest-tyc-version");
                if (profile.data.tyc && profile.data.tyc.version === lastAckTycVersion) {
                    profile.data.tyc.update = false;
                    profile.data.welcomed = true;
                }
                profile.data.fav.slides = GuestData.favs();
                return profile;
            } else {
                return profile;
            }
        });
    },

    dismissNotification(notitificationId) {
        return this.server.fetch(`/profile/notifications/${notitificationId}`, {}, { method: "delete", bestEffort: true });
    },

    notifications() {
        return this.server.get("/profile/notifications");
    },

    history(dateYyyyMmDd) {
        return this.server.get("/history/" + dateYyyyMmDd + "?tz=" + (new Date().getTimezoneOffset()));
    },
}

const Checkout = {
    server: Server,
    plans(sellerSlug) {
        return this.server.get(`/checkout/${sellerSlug}/plans`);
    },

    portal() {
        return this.server.post(`/checkout/portal`, {});
    },

    startAuthenticated(planId, firstName, lastName, coupon, captcha) {
        return this.server.post("/checkout/start/from_free_trial", {
            captcha,
            first_name: firstName,
            last_name: lastName,
            coupon,
            plan_id: planId,
            ...logInFields,
        }).then(({ data, response }) => {
            return data;
        }).catch((error) => {
            throw error;
        });
    },

    startInactive(planId, email, password, hasPassword, firstName, lastName, coupon, captcha) {
        return this.server.post("/checkout/start/from_inactive", {
            captcha,
            email,
            password: hasPassword ? password : "",
            has_password: hasPassword,
            first_name: firstName,
            last_name: lastName,
            coupon,
            plan_id: planId,
            ...logInFields,
        }, true).then(({ data, response }) => {
            return data;
        }).catch((error) => {
            throw error;
        });
    },

    // start(sellerSlug, planId, email, password, hasPassword, firstName, lastName, coupon, captcha, legacyUpgrade) {
    //     return this.server.post("/checkout/start", {
    //         email,
    //         password: password,
    //         has_password: hasPassword,
    //         captcha,
    //         first_name: firstName,
    //         last_name: lastName,
    //         coupon,
    //         plan_id: planId,
    //         legacy_upgrade: legacyUpgrade,
    //         app: Environment.app,
    //         app_version: Environment.appVersion,
    //         app_id: Environment.appId,
    //         seller_slug: sellerSlug,
    //     }, true).then(({ data, response }) => {
    //         return data;
    //     }).catch((error) => {
    //         throw error;
    //     });
    // },
}

const logInFields = {
    app: Environment.app,
    appVersion: Environment.appVersion,
    appId: Environment.appId,
}

const Auth = {
    server: Server,
    signIn(user, password, tenant, noTenant = false) {
        return this.server.post("/auth/users/sign_in", {
            email: user,
            mobile: noTenant,
            password: password,
            ...logInFields,
            tenant,
        }, true).then(({ data, response }) => {
            return tokensFromHeaders(response.headers);
        }).catch((error) => {
            throw error;
        });
    },

    guestSignIn(tenant) {
        return this.server.post("/auth/users/sign_in", {
            guest: true,
            ...logInFields,
            tenant,
        }, true).then(({ data, response }) => {
            return tokensFromHeaders(response.headers);
        }).catch((error) => {
            throw error;
        });
    },

    signOut() {
        return this.server.delete("/auth/users/sign_out");
    },

    resetPassword(franchiseCode, slug, type, password, passwordConfirmation, token) {
        return this.server.fetch("/auth/password/reset", {
            franchiseCode,
            slug,
            type,
            password,
            passwordConfirmation,
            token
        }, {
            anonymous: true,
            method: 'post'
        });
    },

    // SEE: https://kumarabhirup.me/writings/google-recaptcha-react-node-js
    // for captcha
    recoverPassword(franchiseCode, slug, type, email, captcha, noTenant = false) {
        return this.server.fetch("/auth/password/recover", { franchiseCode, slug, type, email, captcha, mobile: noTenant, }, {
            anonymous: true,
            method: 'post',
        });
    },

    refreshToken(token, refreshToken) {
        const extraHeaders = {
            "Authorization": `Bearer ${token}`,
            "Refresh-Token": refreshToken
        }
        
        return this.server.fetch("/auth/users/tokens", {}, {
            anonymous: true,
            extraHeaders: extraHeaders,
            method: 'post'
        }).then(({ data, response }) => {
            return tokensFromHeaders(response.headers);
        }).catch((error) => {
            throw error;
        });
    },

    linkTv(code) {
        return this.server.post("/tv/session/link", { code })
    },

    identify(email, tenant = null, captcha = null) {
        return this.server.post("/login/identify", {
          email,
          captcha,
          tenant,
          ...logInFields,
        }, true).then(({ data, response }) => data).catch((error) => {
          throw error;
        });
      },
    
      createAccount(email, password, captcha = null) {
        return this.server.post("/checkout/free_trial", {
            email,
            password,
            captcha,
          ...logInFields,
        }, true).then(({ data }) => {
            console.log("CREATE ACCOUNT");
            console.log(data);
            return data;
        }).catch((error) => {
            console.log(error, error.errorData);
            throw error;
        });
      },
      
      requestMagicLink(token, email, redirect, tenant = null) {
        return this.server.post("/login/magic_link/request", {
          email,
          token,
          tenant,
          redirect,
          ...logInFields,
        }, true).then(({ data, response }) => data).catch((error) => {
          throw error;
        });
      },
    
      logInWithPassword(token, email, password, tenant = null) {
        return this.server.post("/login/password/do", {
          email,
          token,
          password,
          tenant,
          ...logInFields,
        }, true).then(({ data, response }) => tokensFromHeaders(response.headers)).catch((error) => {
          throw error;
        });
      },
    
      logInWithMagicLink(code) {
        return this.server.post("/login/magic_link/do", {
          code: decodeURIComponent(code),
          ...logInFields,
        }, true).then(({ data, response }) => tokensFromHeaders(response.headers)).catch((error) => {
          throw error;
        });
      },
}

Server.Auth = Auth;
Server.Collections = Collections;
Server.Progress = Progress;
Server.Profile = Profile;
Server.Training = Training;
Server.Fav = Fav;
Server.Tenants = Tenants;
Server.Search = Search;
Server.Checkout = Checkout;

export default Server;