import jwt_decode from "jwt-decode";

import Server from "./Server";
import { resetStore, store, actions, msgErrorForAction, serializeErrorForAction } from "../store";
import CollectionsService from "./CollectionsService";
import InstrumentationService from "./InstrumentationService";
import ProfileService from "./ProfileService";
import UxService from "./UxService";
import Environment from "./Environment";
import { translations } from "./../translations"

const currentSessionData = {};

const resetServices = () => {
    ProfileService.reset();
    InstrumentationService.reset();
    CollectionsService.reset();
}

const clearSessionData = () => {
    localStorage.removeItem("refresh-a");
    localStorage.removeItem("refresh-b");
    // localStorage.removeItem("gym-franchise-code");
    // localStorage.removeItem("gym-slug");
    Server.updateAccessToken(null);
}

const updateLocalTenantData = (tenant) => {
    if (tenant) {
        localStorage.setItem("tenant-franchise-code", tenant.franchiseCode);
        localStorage.setItem("tenant-slug", tenant.slug);
        localStorage.setItem("tenant-type", tenant.type);
    }
};

const updateSessionData = (sessionData, tenant = null, saveRefreshTokens = true) => {
    currentSessionData.refreshToken = sessionData.refreshToken;
    currentSessionData.expireAt = sessionData.expireAt;
    currentSessionData.accessToken = sessionData.accessToken;

    const decoded = jwt_decode(currentSessionData.accessToken);
    Server.updateAccessToken(currentSessionData.accessToken, decoded.guest);

    if (saveRefreshTokens) {
        localStorage.setItem("refresh-a", currentSessionData.accessToken);
        localStorage.setItem("refresh-b", currentSessionData.refreshToken);
    }

    updateLocalTenantData(tenant);

    return decoded;
}

const progressFromResponse = (profileRes) => {
    const rv = {};

    if (profileRes.data && profileRes.data.progress) {
        profileRes.data.progress.forEach((p) => {
            rv[p.videoId] = { progress: p.progress, seconds: p.seconds };
        });
    }

    return rv;
}

const favFromResponse = (profileRes) => {
    const rv = {
        slides: {}
    };

    if (profileRes.data && profileRes.data.fav && profileRes.data.fav.slides) {
        profileRes.data.fav.slides.forEach((slideId) => {
            rv.slides[slideId] = true;
        });
    }

    return rv;
}

const notificationsFromResponse = (profileRes) => {
    if (profileRes.data && profileRes.data.notifications) {
        return profileRes.data.notifications;
    } else {
        return [];
    }
}

const updateStores = (profile) => {
    const sessionProfile = { ...profile };
    delete sessionProfile.progress;
    delete sessionProfile.fav;
    delete sessionProfile.outstanders;
    delete sessionProfile.trainings;

    ProfileService.startUpdate();

    store.dispatch(actions.session.success(sessionProfile));
    store.dispatch(actions.slides.initiaizeTrainings(profile.trainings));
    store.dispatch(actions.progress.initialize(profile.progress));
    store.dispatch(actions.fav.initialize(profile.fav.slides));
    store.dispatch(actions.notifications.initialize(profile.notifications));

    CollectionsService.setOutstanders(profile.outstanders);
}

const profileDataFromResponse = (profile) => {
    const rv = {
        progress: progressFromResponse(profile),
        notifications: notificationsFromResponse(profile),
        fav: favFromResponse(profile),
        guest: profile?.data?.guest,
        outstanders: profile?.data?.outstanders,
        trainings: profile?.data?.trainings,
        tenant: profile?.data?.tenant,
        gym: profile?.data?.tenant, // DEPRECATED
        stats: profile?.data?.stats,
        branding: profile?.data?.branding,
        welcomed: profile && profile.data && profile?.data?.welcomed,
        tyc: profile?.data?.tyc,
        license: profile?.data?.license,
    }

    updateLocalTenantData(rv.tenant);
    ProfileService.setUserInfo(rv.tenant.franchise, rv.guest);
    CollectionsService.setUserInfo(rv.tenant.franchise, rv.guest);

    return rv;
}
const SessionService = {
    getDefaultLocale() {
        return localStorage.getItem("locale");
    },

    forceRelogin(relogin, noTenant) {
        this.relogin = relogin;
        this.noTenant = noTenant;
    },

    setLocale(new_locale) {
        const locale = (translations[new_locale]) ? new_locale : "en";

        // 1 - set services
        InstrumentationService.setLocale(locale);
        Server.setLocale(locale);
        UxService.setLocale(locale);
        CollectionsService.setLocale(locale);

        // 2 - save in local storage
        localStorage.setItem("locale", locale);

        // 3 - dispatch action
        store.dispatch(actions.locale.changeTo(locale));
    },

    recoverSession(tenant) {
        if (this.relogin) {
            return Promise.resolve({
                recovered: false
            });
        }

        const refreshToken = localStorage.getItem('refresh-b');
        const accessToken = localStorage.getItem('refresh-a');

        if (accessToken && refreshToken) {
            return Server.Auth.refreshToken(accessToken, refreshToken).then(sessionData => {
                const session = updateSessionData(sessionData, tenant, !this.relogin);
                return Promise.all([
                    Promise.resolve(session),
                    Server.Profile.get()
                ]);
            }).then(([session, profile]) => {
                return {
                    user: session.email,
                    recovered: true,
                    ...profileDataFromResponse(profile, tenant),
                };
            }).catch((error) => {
                return {
                    recovered: false,
                    error: error
                }
            });
        } else {
            return Promise.resolve({
                recovered: false
            });
        }
    },

    continueSignIn(gym, sessionData) {
        const session = updateSessionData(sessionData, gym, !this.relogin);
        CollectionsService.start();
        return Server.Profile.get().then((profile) => {
            return {
                user: session.email,
                ...profileDataFromResponse(profile, gym),

            }
        });
    },

    guestSignIn(gym) {
        return Server.Auth.guestSignIn(gym).then(sessionData => {
            return this.continueSignIn(gym, sessionData);
        });
    },

    signIn(user, pwd, gym) {
        return Server.Auth.signIn(user, pwd, gym, this.noTenant).then(sessionData => {
            return this.continueSignIn(gym, sessionData);
        });
    },

    linkTv(code) {
        return Server.Auth.linkTv(code);
    },

    reset() {
        const wipeAndRedirect = () => {
            clearSessionData();
        }

        resetStore();
        resetServices();
        wipeAndRedirect();
    },

    signOut() {
        return Server.Auth.signOut().then(sessionData => {
            this.reset();
            return true;
        }).catch((error) => {
            this.reset();
            return true;
        });
    },

    chargeBeeInstance() {
        if (!this.cbInstance) {
            this.cbInstance = window.Chargebee.init({
                site: Environment.chargebee.siteName, // your test site
                iframeOnly: true,
            })
        }

        return this.cbInstance;
    },

    startPortal() {
        return Server.Checkout.portal().then(({ data }) => {
            console.log(data.url);
            return new Promise((resolve, reject) => {
                const cbInstance = this.chargeBeeInstance();
                cbInstance.setPortalSession(function () {
                    return Promise.resolve(data.url);
                });

                const cbPortal = cbInstance.createChargebeePortal();
                // startedCheckout();
                cbPortal.open({
                    close: () => {
                        console.log("portal closed");
                        resolve({ ok: true, event: "close" })
                    },
                });
            });
        });
    },

    startCheckout(sellerSlug, planId, profile, { email, password, hasPassword, firstName, lastName }, coupon, captcha, startedCheckout = () => {}) {
        const startCheckoutPromise = profile
            ? Server.Checkout.startAuthenticated(planId, firstName, lastName, coupon, captcha)
            : Server.Checkout.startInactive(planId, email, password, hasPassword, firstName, lastName, coupon, captcha); 

        return startCheckoutPromise.then((data) => {
            return new Promise((resolve,reject) => {
                const cbInstance = this.chargeBeeInstance();
                startedCheckout();
                let settled = false;
                let settleWithOk = false;
                cbInstance.openCheckout({
                    hostedPage: () => {
                        return Promise.resolve(data.url)
                    },
                    success(hostedPageId) {
                        console.log("checkout success")
                        console.log(hostedPageId);
                        if (!settled) {
                            resolve({ ok: true, hostedPageId});
                            settled = true;
                        }
                    },
                    close: () => {
                        console.log("checkout new closed");
                        if (!settled) {
                            console.log("resolving", settleWithOk);
                            resolve({ ok: settleWithOk, event: "close"});
                            settled = true;
                        }
                    },
                    step(step) {
                        console.log("checkout step:", step);
                        if (step === "thankyou_screen") {
                            console.log("settleWithOk", true);
                            settleWithOk = true;
                        } else if (step === "success") {
                            console.log("settleWithOk", true);
                            settleWithOk = true;
                        }
                    }
                });
            });
        });
    },

    sellerPlans(sellerSlug) {
        return Server.Checkout.plans(sellerSlug).then(({ data }) => (data));
    },

    refreshToken() {
        return Server.Auth.refreshToken(currentSessionData.refreshToken).then(sessionData => {
            updateSessionData(sessionData);
        });
    },

    fallbackLandingData() {
        if (Environment.isTv) {
            return Server.Tenants.defaultLandingData();
        } else {
            return Promise.resolve({
                "name": "RHF-DEFAULT",
                "slug": "rhf-global",
                "img": null,
                "wide_img": null,
            })
        }
    },

    tenantLandingData(franchiseCode, slug) {
        if (franchiseCode) {
            return Server.Tenants.gymLandingData(franchiseCode, slug);
        } else {
            return Server.Tenants.sellerlandingData(slug);
        }
    },

    franchiseFromSessionData() {
        return this.tenantFromSessionData().franchiseCode;
    },

    // gymFromSessionData() {
    //     return {
    //         franchiseCode: localStorage.getItem("gym-franchise-code"),
    //         slug: localStorage.getItem("gym-slug"),
    //     }
    // },

    tenantFromSessionData() {
        return {
            franchiseCode: localStorage.getItem("tenant-franchise-code") || localStorage.getItem("gym-franchise-code"),
            slug: localStorage.getItem("tenant-slug") || localStorage.getItem("gym-slug"),
            type: localStorage.getItem("tenant-type"),
        }
    },

    recoverPassword(franchiseCode, slug, type, email, captcha, noTenant = false) {
        return Server.Auth.recoverPassword(franchiseCode, slug, type, email, captcha, noTenant);
    },

    resetPassword(franchiseCode, slug, type, password, passwordConfirmation, token) {
        return Server.Auth.resetPassword(franchiseCode, slug, type, password, passwordConfirmation, token);
    }

}

const LoginFlow = {
    cancel() {
        store.dispatch(actions.session.signInInitialize());
        store.dispatch(actions.session.magicLinkRequestInitialize());
        store.dispatch(actions.session.identifyInitialize());
        store.dispatch(actions.session.resetReseted());
    },

    registerNonActiveCredentials() {
        store.dispatch(actions.session.registerNonActiveCredentials());
    },

    createAccount(email, pwd, captcha) {
        store.dispatch(actions.session.createAccountStart({ email }));
        // store.dispatch(actions.session.updateLastSignEmail(email));

        return Server.Auth.createAccount(email, pwd, captcha).then((rv) => {
            store.dispatch(actions.session.createAccountSuccess(rv));
        }).catch((error) => {
            store.dispatch(actions.session.createAccountFailed(serializeErrorForAction(error)));
        });
    },

    requestMagicLink(token, email, redirect, tenant = null) {
        store.dispatch(actions.session.magicLinkRequestStart({ email }));

        if ((email || "").trim() === "") {
            const msg = "t:app.errors.email_complete";
            store.dispatch(actions.session.magicLinkRequestFailed(msgErrorForAction(msg)));
            return Promise.reject(msg);
        }

        return Server.Auth.requestMagicLink(token, email, redirect).then((rv) => {
            store.dispatch(actions.session.magicLinkRequestSuccess(rv));
        }).catch((error) => {
            store.dispatch(actions.session.magicLinkRequestFailed(serializeErrorForAction(error)));
            if (error.restartFlow) {
                store.dispatch(actions.session.identifyFailed(serializeErrorForAction(error)));
            }
        });
    },

    identify(email, tenant, captcha, allowNonActive = false) {
        store.dispatch(actions.session.signInInitialize());
        store.dispatch(actions.session.magicLinkRequestInitialize());
        store.dispatch(actions.session.identifyStart({ email }));
        store.dispatch(actions.session.resetReseted());
        // store.dispatch(actions.session.updateLastSignEmail(email));

        if ((email || "").trim() === "") {
            const msg = "t:app.errors.email_format";
            store.dispatch(actions.session.identifyFailed(msgErrorForAction(msg)));
            return Promise.reject(msg);
        }

        return Server.Auth.identify(email, tenant, captcha).then((rv) => {
            const { methods, inactive } = rv;

            let canContinue = true;
            if (methods.length <= 0) {
                canContinue = false;
            }

            if (canContinue) {
                store.dispatch(actions.session.identifySuccess(rv));
                return rv;
            } else {
                if (allowNonActive) {
                    store.dispatch(actions.session.updateNonActiveCredentials({ email, inactive }));
                }
            }

            const msg = "t:app.errors.not_found_login_email";
            throw new Error(msg);
        }).catch((error) => {
            store.dispatch(actions.session.identifyFailed(serializeErrorForAction(error)));
            throw error;
        });
    },

    continueSignIn(signInPromise) {
        return signInPromise.then((sessionData) => {
            const session = updateSessionData(sessionData, !SessionService.relogin);
            CollectionsService.start();

            return Server.Profile.get().then((rv) => {
                const profile = {
                    user: session.email,
                    ...profileDataFromResponse(rv),
                };
                updateLocalTenantData();
                updateStores(profile);
                store.dispatch(actions.session.signInSuccess(profile));
                return profile
            });
        }).catch((error) => {
            store.dispatch(actions.session.signInFailed(serializeErrorForAction(error)));
            throw error;
        });
    },

    logInWithMagicLink(magicLinkCode) {
        store.dispatch(actions.session.signInStart({ recover: false, magicLink: true }));

        const signInPromise = Server.Auth.logInWithMagicLink(magicLinkCode);

        return this.continueSignIn(signInPromise);
    },

    logInWithPassword(token, email, password, tenant) {
        store.dispatch(actions.session.signInStart({ recover: false }));

        if ((email || "").trim() === "") {
            const msg = "t:app.errors.empty_login_email";
            store.dispatch(actions.session.signInFailed(msgErrorForAction(msg)));
            return Promise.reject(msg);
        }

        if ((password || "").trim() === "") {
            const msg = "t:app.errors.empty_login_password";
            store.dispatch(actions.session.signInFailed(msgErrorForAction(msg)));
            return Promise.reject(msg);
        }

        const signInPromise = Server.Auth.logInWithPassword(token, email, password, tenant).catch((error) => {
            if (error.restartFlow) {
                store.dispatch(actions.session.identifyFailed(serializeErrorForAction(error)));
            }
            throw (error);
        })

        return this.continueSignIn(signInPromise);
    },
};

SessionService.LoginFlow = LoginFlow;

Server.onAuthenticationError = () => {
    return SessionService.refreshToken().catch((error) => {
        // ACA RESETEO TODO:
        SessionService.reset();
        throw error;
    });
}

export default SessionService;