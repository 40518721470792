import React from 'react';
import { CSSTransition } from 'react-transition-group';
import History from "../History";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import ProfileService from "../../services/ProfileService";
import { useSelector, useDispatch } from 'react-redux'
import { selectors, actions } from "../../store"
import { useQuery } from 'react-query'

const AppHistory = () => {
    const navigation = useRadicalNavigation();
    const backToLibrary = () => {
        navigation.main();
    };

    const view = useSelector(selectors.history.view);
    const date = useSelector(selectors.history.date);
    const dispatch = useDispatch();

    const onDateChange = (value) => (
        dispatch(actions.history.changeDate(value))
    );

    const onViewChange = (value, date) => (
        dispatch(actions.history.changeView(value, date))
    );

    const queryDate = new Date(date);
    queryDate.setDate(1);

    const historyQuery = useQuery(['history', queryDate], () => {
        return ProfileService.history(queryDate);
    });

    
    return <CSSTransition appear={true} in={true} timeout={200} classNames="popup-profile-fade">
        <History loading={historyQuery.isLoading} error={historyQuery.isError && historyQuery.error} data={historyQuery.data && historyQuery.data.history} view={view} onViewChange={onViewChange} onDateChange={onDateChange} date={date} onCancel={backToLibrary} />
    </CSSTransition>
}

export default AppHistory;