import { createSlice, createSelector } from '@reduxjs/toolkit'

const initialState = {
    locale: ""
};

const localeSlice = createSlice({
    name: 'locale',
    initialState,
    reducers: {
        changeTo: {
            reducer: (state, action) => {
                state.locale = action.payload.locale;
            },
            prepare: (locale) => {
                return { payload: { locale } }
            },
        },
        reset(state) {
            // - i want the locale to remain
        }
    }
})

const selectors = (getRoot) => ({
    currentLocale: createSelector(getRoot, data => data.locale),
});


localeSlice.selectors = selectors;

export default localeSlice;
