import React from 'react';
import { Provider, useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'
import { IntlProvider, useIntl } from "react-intl";
import { translations, getLocale } from "./translations";

import MagicLinkExchanger from "./components/MagicLinkExchanger";
import Checkout from "./components/Checkout";
import AppWelcome from "./components/AppWelcome";
import AppSearch from "./components/AppSearch";
import AppTvLink from "./components/AppTvLink";
import Library from "./components/Library";
import TenantLoader from "./components/TenantLoader";
import Landing from "./components/Landing";
import Login from "./components/Login";
import AppShareLanding from "./components/AppShareLanding";
import PasswordRecover from "./components/PasswordRecover";
import PasswordReset from "./components/PasswordReset";
import Authenticated from "./components/Authenticated";
import { ForceAuthenticated } from "./components/Authenticated/Authenticated";
import ManageYourAccount from "./components/ManageYourAccount";
import CreateYourAccount from "./components/CreateYourAccount";
// import AppHistory from "./components/AppHistory";
import SessionService from "./services/SessionService";
import { store, selectors } from "./store"

import './App.css';

const queryClient = new QueryClient()

const startLocale = getLocale(SessionService.getDefaultLocale());
SessionService.setLocale(startLocale);

function ConnectedApp() {
  const locale = useSelector(selectors.locale.currentLocale);

  return <IntlProvider locale={locale} messages={translations[locale]}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <Switch>
          <Route path="/library">
            <Authenticated>
              <Library />
            </Authenticated>
          </Route>
          {/* <Route path="/history">
                <Authenticated>
                  <AppHistory />
                </Authenticated>
              </Route> */}
          <Route path="/welcome">
            <Authenticated>
              <AppWelcome />
            </Authenticated>
          </Route>

          <Route path="/search">
            <Authenticated>
              <AppSearch />
            </Authenticated>
          </Route>

          <Route exact path="/tv">
            <TenantLoader fallback childrenRender={(tenant, goToRoot) => (
              <ForceAuthenticated redirectTo="tv-link" relogin onlyRtv noTenant goToRoot={goToRoot} tenant={tenant}>
                <AppTvLink locale={locale} />
              </ForceAuthenticated>
            )} />
          </Route>

          <Route exact path="/share/:franchiseCode/:slug/:slideId">
            <TenantLoader childrenRender={(tenant) => (<AppShareLanding gym={tenant} />)} />
          </Route>

          <Route exact path="/share/:slug/:slideId">
            <TenantLoader childrenRender={(tenant) => (<AppShareLanding gym={tenant} />)} />
          </Route>

          <Route exact path="/browse/:slug">
            <TenantLoader childrenRender={(tenant) => (<Login continueAsGuest tenant={tenant} locale={locale} />)} />
          </Route>

          <Route exact path="/recover/:franchiseCode/:slug/token/:token">
            <TenantLoader childrenRender={(tenant) => (<PasswordReset tenant={tenant} />)} />
          </Route>
          <Route exact path="/recover/:slug/token/:token">
            <TenantLoader childrenRender={(tenant) => (<PasswordReset tenant={tenant} />)} />
          </Route>

          <Route exact path="/recover/:franchiseCode/:slug">
            <TenantLoader childrenRender={(tenant) => (<PasswordRecover gym={tenant} locale={locale} />)} />
          </Route>
          <Route exact path="/recover/:slug">
            <TenantLoader childrenRender={(tenant) => (<PasswordRecover gym={tenant} locale={locale} />)} />
          </Route>
          <Route exact path="/recover">
            <TenantLoader fallback childrenRender={(tenant) => (<PasswordRecover noTenant gym={tenant} locale={locale} />)} />
          </Route>

          <Route exact path="/account/checkout/:slug?">
            <TenantLoader fallback childrenRender={(tenant, goToRoot) => (
              <ForceAuthenticated title={{ id: "app.titles.account_checkout" }} redirectTo="checkout-account" relogin onlyRtv allowInactive tenant={tenant} goToRoot={goToRoot}>
                <Checkout tenant={tenant} locale={locale} goToRoot={goToRoot} />
              </ForceAuthenticated>
            )}
            />
          </Route>

          {/* Manage your account */}
          <Route exact path="/account/manage">
            <TenantLoader fallback childrenRender={(tenant, goToRoot) => (
              <ForceAuthenticated title={{ id: "app.titles.manage_your_account" }} redirectTo="manage-account" relogin onlyRtv allowNonActive tenant={tenant} goToRoot={goToRoot}>
                <ManageYourAccount locale={locale} tenant={tenant} />
              </ForceAuthenticated>
            )} />
          </Route>

          <Route exact path="/account/create">
            <TenantLoader fallback childrenRender={(tenant, goToRoot) => (
              <ForceAuthenticated title={{ id: "app.fields.create_account" }} subtitle={{ id: "app.messages.create_account_text" }} redirectTo="create-account" relogin onlyRtv identifyOnly allowNonActive tenant={tenant} goToRoot={goToRoot}>
                <CreateYourAccount locale={locale} tenant={tenant} />
              </ForceAuthenticated>
            )} />
          </Route>

          {/* Magic Links  */}
          <Route exact path="/magic/all/:code">
            <MagicLinkExchanger />
          </Route>

          <Route exact path="/magic/web/:code">
            <MagicLinkExchanger />
          </Route>

          <Route exact path="/login/:franchiseCode/:slug">
            <TenantLoader childrenRender={(tenant, goToRoot) => (<Login goToRoot={goToRoot} tenant={tenant} locale={locale} />)} />
          </Route>

          <Route exact path="/login/:slug">
            <TenantLoader childrenRender={(tenant) => (<Login tenant={tenant} locale={locale} />)} />
          </Route>

          <Route exact path="/login">
            <TenantLoader fallback childrenRender={(tenant) => (<Login tenant={tenant} locale={locale} />)} />
          </Route>

          <Route path="/">
            <Landing />
          </Route>
        </Switch>
      </Router>
    </QueryClientProvider>
  </IntlProvider>;
}

function App() {
  return (
    <Provider store={store}>
      <ConnectedApp />
    </Provider>
  );
}

export default App;
