import styles from './Metadata.module.scss';
// import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useSliderStyle from "../../hooks/useSliderStyle";
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faRunning } from '@fortawesome/free-solid-svg-icons'
import { faMale } from '@fortawesome/free-solid-svg-icons'
import { faDumbbell } from '@fortawesome/free-solid-svg-icons'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { faSignal } from '@fortawesome/free-solid-svg-icons'

import { useIntl, FormattedMessage } from "react-intl";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

// SEE: https://www.timo-ernst.net/blog/2020/09/12/arrows-in-react-swiper-js-how-to-customize-prev-next-button/

SwiperCore.use([Navigation, Pagination, Scrollbar]);

function Metadata({ metadata, wide }) {
    const metadataToShow = [];
    const intl = useIntl();
    const sliderStyle = useSliderStyle("metadata" + (wide ? "_wide" : ""));

    if (metadata && !metadata.no_exercise) {
        if (metadata.duration_min != null && metadata.duration_max != null) {
            if (metadata.duration_min !== metadata.duration_max) {
                metadataToShow.push({
                    icon: faClock,
                    title: "durations",
                    values: [metadata.duration_min + "'", metadata.duration_max + "'"],
                    separator: " - ",
                })
            } else {
                metadataToShow.push({
                    icon: faClock,
                    title: "duration",
                    values: [metadata.duration_min + "'"]
                })
            }
        }

        if ((metadata.difficulty || []).length > 0) {
            metadataToShow.push({
                icon: faSignal,
                title: "difficulty",
                values: metadata.difficulty.map((v) => (intl.formatMessage({ id: `app.metadata.difficulty.${v}` }))),
                separator: " - ",
            })
        }

        if ((metadata.body_focus || []).length > 0) {
            metadataToShow.push({
                icon: faMale,
                title: "body_focus",
                values: metadata.body_focus.map((v) => (intl.formatMessage({ id: `app.metadata.body_focus.${v}` }))),
            })
        }

        if ((metadata.workout_type || []).length > 0) {
            metadataToShow.push({
                icon: faRunning,
                title: "workout_type",
                values: metadata.workout_type.map((v) => (intl.formatMessage({ id: `app.metadata.workout_type.${v}` }))),
            })
        }

        if ((metadata.impact || []).length > 0) {
            metadataToShow.push({
                icon: faChartLine,
                title: "impact",
                values: metadata.impact.map((v) => (intl.formatMessage({ id: `app.metadata.impact.${v}` }))),
                separator: " - ",
            })
        }


        if ((metadata.equipment || []).length > 0) {
            metadataToShow.push({
                icon: faDumbbell,
                title: "required_equipment",
                values: metadata.equipment.map((v) => (intl.formatMessage({ id: `app.metadata.equipment_required.${v}` }))),
            })
        }
    }

    if (metadataToShow.length > 0) {
        const navigation = (metadataToShow.length > sliderStyle.slidesPerView)
        return <div className={styles.metadataContainer}>
            <Swiper
                navigation={navigation}
                spaceBetween={sliderStyle.spaceBetween}
                slidesPerView={Math.min(sliderStyle.slidesPerView, metadataToShow.length)}
                key={sliderStyle.key}
            >
                {metadataToShow.map(({ icon, title, values, separator }, ix) =>  (<SwiperSlide key={title + ix}>
                    <div className={styles.metadata}>
                        <div className={styles.metadataIcon}><FontAwesomeIcon icon={icon} /></div>
                        <div className={styles.metadataTitle}><FormattedMessage id={`app.fields.${title}`} /></div>
                        <div className={styles.metadataValues}>
                            {values.join(separator || ", ")}
                        </div>
                    </div>
                </SwiperSlide>))}
                </Swiper>
        </div>
    } else {
        return <div/>
    }
}

export default Metadata;