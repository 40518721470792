import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import SessionService from "../services/SessionService";
import InstrumentationService from "../services/InstrumentationService";
import { selectors } from "../store"


function report(location) {
    InstrumentationService.Navigation.track(location);
};

// function useQueryString() {
//     return new URLSearchParams(useLocation().search);
// }

function useRadicalNavigation() {
    const history = useHistory();
    const { welcomed, tyc } = (useSelector(selectors.session.profile) || {});

    const showPopUp = (location) => {
        navigate(location);
    }

    const navigate = (location) => {
        report(location);
        history.push(location);
    }

    const nav =  {
        main: () => {
            navigate("/library")
        },
        checkout: (redirect = false) => {// , license) => {
            if (!redirect) {
                navigate(`/account/checkout`);
            } else {
                window.location =`/account/checkout`;
            }

        },
        loginTo: (gym, cont = null) => {
            navigate(`/login/${gym.franchiseCode}/${gym.slug}` + (cont ? ("?show=" + cont) : ""));
        },
        loginToTenant: (tenant, redirect = false) => {
            if (!redirect) {
                if (tenant.type === "seller") {
                    navigate(`/login/${tenant.slug}`)
                } else if (tenant.type === "gym") {    
                    navigate(`/login/${tenant.franchiseCode}/${tenant.slug}`)
                } 
            } else {
                if (tenant.type === "seller") {
                    window.location =(`/login/${tenant.slug}`)
                } else if (tenant.type === "gym") {    
                    window.location =(`/login/${tenant.franchiseCode}/${tenant.slug}`)
                } 
            }
        },
        loginDefault: () => {
            navigate(`/login`);
        },
        login: (tryRedirectTo = null, relogin = false, noTenant = false, account = null) => {
            SessionService.forceRelogin(relogin, noTenant);
            const tenant = SessionService.tenantFromSessionData();
            const redirectTo = () => {
                const args = [];

                if (tryRedirectTo) {
                    args.push(`redirect=${encodeURIComponent(tryRedirectTo)}`);
                }

                if (account) {
                    args.push(`account=${encodeURIComponent(account)}`);
                }

                if (args.length > 0) {
                    return `?${args.join("&")}`
                } else {
                    return "";
                }
            }

            if (tenant.type === "seller") {
                navigate(`/login/${tenant.slug}${redirectTo()}`)
            } else if (tenant.type === "gym") {    
                navigate(`/login/${tenant.franchiseCode}/${tenant.slug}${redirectTo()}`)
            } else {
                navigate(`/login${redirectTo()}`);
            }
        },
        recoverPassword: (franchiseCode, slug) => {
            if (franchiseCode) {
                navigate(`/recover/${franchiseCode}/${slug}`)
            } else {
                navigate(`/recover/${slug}`)
            }
        },
        manageYourAccount: (external = false, email = null) => {
            if (!external) {
                navigate("/account/manage");
            } else {
                window.open(`/account/manage`, "_blank");
            }
        },
        createYourAccount: (external = false) => {
            if (!external) {
                navigate("/account/create");
            } else {
                window.open(`/account/create`, "_blank");
            }
        },
        tvLink: () => {
            navigate("/tv");
        },
        landing: () => {
            (welcomed && !tyc.update) ? navigate("/library") : navigate("/welcome/");
        },
        rtvLanding: (tenant) => {
           //  window.location =(`/rtv/${tenant.slug}`)
           window.location =(`/`);
        },
        welcome: () => {
            navigate("/welcome");
        },
        history: () => {
            navigate("/library/history");
        },
        profile: () => {
            navigate("/library/profile");
        },
        offer: () => {
            navigate("/library/offer");
        },
        subscription: (email = null, local = false) => {
            if (local) {
                navigate("/account/manage");
            } else {
                window.open(`/account/manage${email ? `?account=${encodeURIComponent(email)}` : ""}`, "_blank");
            }
        },
        search: () => {
            navigate("/search");
        },
        notifications: () => {
            navigate("/library/notifications");
        },
        library: {
            profile: () => {
                navigate("/library/profile");
            },
            item: (slideId) => {
                showPopUp(`/library/item/${slideId}`);
            },
            play: (slideId, videoId, restart) => {
                showPopUp(`/library/play/${slideId}/${videoId}` + (restart ? "?restart=true" : ""));
            },
            continue: (slideId) => {
                showPopUp(`/library/continue/${slideId}`);
            },
            notifications: () => {
                navigate("/library/notifications");
            },
            history: () => {
                navigate("/library/history");
            },

        }
    }

    nav.link = (link) => {
        if (link.slide) {
            nav.library.item(link.slide);
        }
    };
        
    return nav;
}

export default useRadicalNavigation;