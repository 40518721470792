import { useEffect, useRef, useState, useLayoutEffect } from "react";
import Vimeo from '@u-wave/react-vimeo';
import YouTube from '@u-wave/react-youtube';
import { Stream } from "@cloudflare/stream-react";

import CloseButton from "./../Buttons/CloseButton";
import CollectionsService from "../../services/CollectionsService";

import styles from './FreeTrialOffer.module.scss';
import Metadata from "../Metadata";
import DOMPurify from 'dompurify';

function backdropKeyDown(onClose) {
    return (event) => {
        if (event.key === "Esc" || event.key === "Escape") {
            if (onClose) {
                onClose();
            }
        }
    }
};

// - me tiene que mandar un bitset con 1 donde sabe que reproducjo
function FreeTrialOffer({ onClose }) {
    // EXPLAIN: Focus the div on the first render in
    // order to catch keyboard events and thus
    // allowing the user to close with "esc" key
    const rootRef = useRef(null);
    useEffect(() => {
        rootRef.current.focus();
    }, []);

    useLayoutEffect(() => {
        // SEE: https://stackoverflow.com/questions/42308209/chrome-browser-automatically-scrolling-down-the-content-when-nobody-asked-it-to
        setTimeout(function () { window.scrollTo(0, 0); }, 1)
    }, []);

    return (
        <div className={styles.root} tabIndex={0} ref={rootRef} onKeyDown={backdropKeyDown(onClose)} >
                <CloseButton onClick={onClose} className={styles.btnPlayRight} />
        
                <div className={styles.contents}>
                    ACA VA LA INFO
                </div>
        </div>
    );
}

export default FreeTrialOffer;