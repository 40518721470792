import { useEffect, useRef, useState, useLayoutEffect } from "react";
import Vimeo from '@u-wave/react-vimeo';
import YouTube from '@u-wave/react-youtube';
import { Stream } from "@cloudflare/stream-react";

import CloseButton from "./../Buttons/CloseButton";
import CollectionsService from "../../services/CollectionsService";

import styles from './ItemPlay.module.scss';
import Metadata from "../Metadata";
import DOMPurify from 'dompurify';

// import YouTube2 from 'react-youtube';
// function YouTubePlayer2({ type, video, onTrack }) {
//     const lastKnownState = useRef(null);
//     const target = useRef(null);

//     useEffect(() => {
//         const timer = setInterval(function () {
//             console.log("TIMEOUT!");
//             console.log(target.current);
//             console.log(lastKnownState.current);

//             if (target.current != null && lastKnownState.current === 1) {  
//                 target.current.getCurrentTime().then((currentTime) => {
//                     console.log(currentTime);
//                 });
//             }
//         }, 1000);

//         return () => {
//             console.log("CELANEUP")
//             clearTimeout(timer);
//         }
//     });

//     const onStateChange = (event) => {
//         console.log("onStateChange");
//         console.log(event);
//         lastKnownState.current = event.data;
//     };

//     const onReady = (event) => {
//         console.log("onReady");
//         console.log(event);
//         target.current = event.target;
//     };

//     const opts = {
//         height: '100%',
//         width: '100%',
//         playerVars: {
//             // https://developers.google.com/youtube/player_parameters
//             autoplay: 1,
//             origin: Environment.hostUrl,
//         },
//     };

//     return <YouTube2
//         videoId={video}
//         opts={opts}
//         onStateChange={onStateChange}
//         onReady={onReady}
//         style={{ height: "95%" }}
//     />;
// }

function YouTubePlayer({ type, video, onTrack, at, onComplete }) {
    const lastKnownState = useRef(null);
    const target = useRef(null);

    useEffect(() => {
        const lastUpdate = {
            percent: null
        }

        const timer = setInterval(function () {
            if (target.current != null && lastKnownState.current === 1) {
                const current = target.current.getCurrentTime();
                const total = target.current.getDuration();
                const percent = Math.floor((current / total) * 100);
                
                if (lastUpdate.percent !== percent) {
                    lastUpdate.percent = percent;
                    onTrack && onTrack({ percent, seconds: current });
                }
            }
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
    });

    const onStateChange = (event) => {
        lastKnownState.current = event.data;
    };

    const onReady = (event) => {
        target.current = event.target;
    };

    return <YouTube
        video={video}
        autoplay
        height="100%"
        width="100%"
        allowFullscreen
        modestBranding
        onStateChange={onStateChange}
        onReady={onReady}
        showRelatedVideos={false}
        style={{ height: "95%" }}
        startSeconds={parseInt(at) || 0}
        onEnd={onComplete}
    />;
};

function VimeoPlayer({ type, video, onTrack, at, onComplete }) {
    const lastUpdate = useRef(null);

    const onTimeUpdate = (event) => {
        const percent = Math.floor(event.percent * 100);
        if (lastUpdate.current !== percent) {
            lastUpdate.current = percent;
            onTrack && onTrack({ ...event, percent });
        }
    }

    return <Vimeo
        video={video}
        autoplay
        onTimeUpdate={onTimeUpdate}
        className={styles.vimeoPlayer}
        onEnd={ onComplete }
        start={ parseInt(at) || 0 }
    />;
}

function CloudflarePlayer({ type, video, onTrack, at, onComplete }) {
    const [signedUrl, setSignedUrl] = useState({ tainted: false });
    const streamRef = useRef(null);
    const lastUpdate = useRef(null);
    useEffect(() => {
        if (!signedUrl.tainted) {
            setSignedUrl({ tainted: true, inProgress: true });
            CollectionsService.signedStreamUrl(video).then((url) => {
                console.log("pepepep " );
                console.log(url);
                setSignedUrl({ tainted: true, url: url.url, ok: url.ok });
            }).catch(() => {
                setSignedUrl({ tainted: true, ok: false });
            });
        }
    },[signedUrl.tainted, video]);

    if (signedUrl.inProgress || !signedUrl.tainted) {
        return <div>Loading</div>;
    } else if (!signedUrl.ok) {
        return <div>FAILED</div>;
    }

    console.log("AT:" + (parseInt(at) || 0) + "->" + video)
    console.log(signedUrl);

    const onTimeUpdate = (event, a) => {
        if (streamRef.current) {
            const time = { 
                duration: streamRef.current.duration,
                seconds: streamRef.current.currentTime,
            }
            
            if (time.duration) {
                time.percent = Math.floor((time.seconds / time.duration) * 100);
                if (lastUpdate.current !== time.percent) {
                     lastUpdate.current = time.percent;
                     onTrack && onTrack( time );
                }
            }
        }
    }

    return (
        <Stream 
            controls 
            streamRef={streamRef}
            src={signedUrl.url} 
            onTimeUpdate={ onTimeUpdate }
            onEnded={ onComplete }
            startTime={ parseInt(at) || 0 }
            autoplay
        />
    );
}
function UnknownSourcePlayer({ type, video, onTrack }) {
    return <div>Bad Source</div>;
}

function Player({ type, video, onTrack, at, onComplete }) {
    let SourcePlayer = UnknownSourcePlayer;

    if (type === "youtube") {
        SourcePlayer = YouTubePlayer;
    } else if (type === "vimeo") {
        SourcePlayer = VimeoPlayer;
    } else if (type === "cloudflare") {
        SourcePlayer = CloudflarePlayer;
    }

    return <SourcePlayer type={type} video={video} onTrack={onTrack} at={at} onComplete={onComplete}/>;
}

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

function backdropKeyDown(onClose) {
    return (event) => {
        if (event.key === "Esc" || event.key === "Escape") onClose();
    }
};

// - me tiene que mandar un bitset con 1 donde sabe que reproducjo
function ItemPlay({ id, source, sourceLang, onTrack, onClose, at, onComplete, metadata, title, description, richDescription }) {
    const [playSessionId] = useState(uuidv4());

    const handleTrack = (data) => (onTrack && onTrack({
        ...data,
        lang: sourceLang,
        playSessionId,
    }));

    // EXPLAIN: Focus the div on the first render in
    // order to catch keyboard events and thus
    // allowing the user to close with "esc" key
    const rootRef = useRef(null);
    useEffect(() => {
        rootRef.current.focus();
    }, []);

    useLayoutEffect(() => {
        // SEE: https://stackoverflow.com/questions/42308209/chrome-browser-automatically-scrolling-down-the-content-when-nobody-asked-it-to
        setTimeout(function () { window.scrollTo(0, 0); }, 1)
    },[id]);

    return <div className={styles.root} tabIndex={0} ref={rootRef} onKeyDown={backdropKeyDown(onClose)} >
            <CloseButton onClick={onClose} className={styles.btnPlayRight} />
    
            <div className={styles.contents}>
                <Player type={source.type} onTrack={handleTrack} video={source.video} at={at} onComplete={onComplete} />
            </div>
            
            <div className={styles.videoInfoContainer}>
                <div className={styles.videoInfoTitle}>
                    {title}
                </div>
                     
                {metadata && <div className={styles.metadataContainer}>
                    <Metadata metadata={metadata} wide={true}/>
                </div>}
            
            {richDescription && <div className={styles.videoInfoDescription} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(richDescription) }} />}
                {!richDescription && description && <div className={styles.videoInfoDescription}>{description}</div>}
            </div>

    </div>
}

export default ItemPlay;