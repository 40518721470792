import RadicalModal from "../RadicalModal";
import { useSelector } from 'react-redux'
import { selectors } from "../../store"
import UxService from "./../../services/UxService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faTimesCircle,faTrash } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage, FormattedDate } from "react-intl";
import clsx from "clsx";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";

import styles from './AppNotifications.module.scss';
import ProfileService from "./../../services/ProfileService"

function NotificationRow({ notification, onDismiss, onClose }) {
    const navigation = useRadicalNavigation();

    const showImg = notification.link && notification.link.slide_data;
    const image = showImg && UxService.localeImageSet(notification.link.slide_data, "images_slide", null, {
        xs: 148,
        s: 222,
        m: 296,
        l: 592,
        xl: 1184,
    });

    const follow = () => {
        if (notification.link) {
            if (notification.link.internal) {
                navigation.link(notification.link);
            } else {
                window.open(notification.link.to, "_blank");
            }
        }
    }

    return <div className={clsx(styles.notificationRow, notification.link && styles.linked)}>
        <div className={styles.text}>
            <div className={styles.date}><FormattedDate value={UxService.formatDate(notification.date)} /></div>
            <div className={styles.title} onClick={follow}>{UxService.localeValue(notification, "title")}</div>
            <div className={styles.body} onClick={follow}>{UxService.localeValue(notification, "body")}</div>
        </div>
        {showImg && <div className={styles.img} onClick={follow}>
            <img src={image.default} srcSet={image.srcset} alt="preview" sizes="20vw" />
        </div>}
        <div className={styles.actions}>
            <FontAwesomeIcon icon={faTrash} onClick={() => onDismiss(notification.id)} />
        </div>
    </div>;

}

function AppNotifications() {
    const all = useSelector(selectors.notifications.all);
    const navigation = useRadicalNavigation();

    const onClose = () => {
        navigation.main();
    };

    const onDismiss = (notificationId) => {
        // - it's a best effor thing
        ProfileService.dismissNotification(notificationId);
    };

    return <RadicalModal onClose={onClose} title={<FormattedMessage id="app.titles.notifications" />}>
        <div className={styles.notificationsContainer}>
            {all.length > 0 && <div className={styles.notificationList}>
                {all.map((notification) => {
                    return <NotificationRow notification={notification} onDismiss={onDismiss} onClose={onClose} key={notification.id} />;
                })}
            </div>}

            {all.length <= 0 && <div className={styles.noNotifications}>
                <div><FontAwesomeIcon icon={faBell}/></div>
                <FormattedMessage id="app.messages.no_notifications_1" /><br/>
                <FormattedMessage id="app.messages.no_notifications_2" />
            </div>}
        </div>
    </RadicalModal>
}

export default AppNotifications;