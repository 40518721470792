import { useIntl } from "react-intl";
import Button from '@material-ui/core/Button'

import useRadicalNavigation from "../../hooks/useRadicalNavigation";

const CheckoutButton = ({ type }) => {
    const intl = useIntl();
    const navigation = useRadicalNavigation();
    const valid = type === "seller";

    if (valid) {
        return (
            <Button variant="contained" onClick={() => navigation.checkout()}>
                {intl.formatMessage({ id: "app.fields.subscribe_me" })}
            </Button>
        );
    } else {
        return <div></div>;
    }
}

export default CheckoutButton;