import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import CloseButton from "./../Buttons/CloseButton";
import styles from "./AppSearch.module.scss";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import { FormattedMessage } from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { DebounceInput } from 'react-debounce-input';
import { useInfiniteQuery } from 'react-query'
import SwiperCard from "../SwiperCard";
import useImageSelector from '../../hooks/useImageSelector';
import UxService from "../../services/UxService";
import SearchService from "../../services/SearchService";
import MultiSelect from "../MultiSelect";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useIntl } from "react-intl";
import Environment from '../../services/Environment';

import useSlideFromId from '../../hooks/useSlideFromId';
import useSliderStyle from "../../hooks/useSliderStyle";

const useStyles = makeStyles({
    input: {
        color: '#e5e5e5',
        fontSize: '50px',
        textAlign: 'center',
        '@media (pointer:coarse)': {
            fontSize: "20px"
        }
    }

});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: Environment.brand === "rhf" ? "#97bd45" : "#5fb1df",

        },
        text: {
            primary: "#fff"
        },
    },
    typography: {
        fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif;",
    },

    overrides: {
        // Style sheet name ⚛️
        MuiFormControl: {
            // Name of the rule
            root: {
                // Some CSS
                width: "80%",//////////////////
            },
            marginNormal: {
                marginTop: "0px",
                marginBottom: "0px",
                width: "100%",
            },
        },
        MuiInputLabel: {
            // Name of the rule
            root: {
                // Some CSS
                color: '#e5e5e5',
                fontSize: '16px',
            },
        },
        MuiInputBase: {
            // Name of the rule
            // input: {
            //     // Some CSS
            //     color: '#e5e5e5',
            //     fontSize: '50px',
            //     textAlign: 'center',
            //     '@media (pointer:coarse)': {
            //         fontSize: "20px"
            //     }
            // },
            root: {
                //fontSize: '50px',
            }
        },
        MuiInputAdornment: {
            root: {
                fontSize: '30px',
                '@media (pointer:coarse)': {
                    fontSize: "20px"
                }
            },
        },
        MuiFormLabel: {
            filled: {
                backgroundColor: "#222",
            },
            root: {
                fontSize: "16px",
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: "#333",
                fontSize: "16px",
                maxWidth: "200px",
            }
        },
        MuiMenuItem: {
            root: {
                backgroundColor: "#333",
                fontSize: "16px",
            }
        },
        MuiFormHelperText: {
            root: {
                marginTop: "0px",
            },
        },
        MuiTypography: {
            body1: {
                fontSize: "16px",
            }
        },
        MuiSlider: {
            valueLabel: {
                fontSize: "12px",
                fontWeight: "700",
            },
            markLabelActive: {
                fontSize: "14px",
            },
            markLabel: {
                fontSize: "14px",
                color: "#666",
            },

        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: "2px solid #e5e5e5",
                }
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#e5e5e5",
                fontSize: "16px",
            }
        },
        MuiIconButton: {
            root: {
                padding: "0px",
            }
        },
        MuiButton: {
            label: {
                fontSize: "12px",
            }
        },
        MuiSelect: {
            selectMenu: {
                minHeight: "0px !important",
            }
        }
    },
});

const options = {
    "equipment": [
        { "label": <FormattedMessage id="app.fields.equipment_is_required" />, "value": "true" },
        { "label": <FormattedMessage id="app.fields.equipment_is_not_required" />, "value": "false" }],
    "impact": [
        { "label": <FormattedMessage id="app.metadata.impact.none" />, "value": "none" },
        { "label": <FormattedMessage id="app.metadata.impact.low" />, "value": "low" },
        { "label": <FormattedMessage id="app.metadata.impact.high" />, "value": "high" }],
    "difficulty": [
        { "label": <FormattedMessage id="app.difficulty.advance" />, "value": "advance" },
        { "label": <FormattedMessage id="app.difficulty.beginner" />, "value": "beginner" },
        { "label": <FormattedMessage id="app.difficulty.intermediate" />, "value": "intermediate" }],
    "workout_type": [
        { "label": <FormattedMessage id="app.metadata.workout_type.aerobics_workouts" />, "value": "aerobics_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.barbell_workouts" />, "value": "barbell_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.body_mind_workouts" />, "value": "body_mind_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.boxing_bag_workouts" />, "value": "boxing_bag_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.boxing_workouts" />, "value": "boxing_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.cardio_workouts_fat_burner" />, "value": "cardio_workouts_fat_burner" },
        { "label": <FormattedMessage id="app.metadata.workout_type.chair_yoga" />, "value": "chair_yoga" },
        { "label": <FormattedMessage id="app.metadata.workout_type.core_workouts" />, "value": "core_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.dance_workouts" />, "value": "dance_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.glutes_workouts" />, "value": "glutes_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.hiit" />, "value": "hiit" },
        { "label": <FormattedMessage id="app.metadata.workout_type.high_intensity_workouts" />, "value": "high_intensity_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.indoor_cycling_workouts" />, "value": "indoor_cycling_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.interval_training_workouts" />, "value": "interval_training_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.kickboxing_workouts" />, "value": "kickboxing_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.latin_dance_workouts" />, "value": "latin_dance_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.legs_workouts" />, "value": "legs_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.low_intensity_workouts" />, "value": "low_intensity_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.mini_trampoline_workouts" />, "value": "mini_trampoline_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.mix_of_cardio_toning_and_body_mind" />, "value": "mix_of_cardio_toning_and_body_mind" },
        { "label": <FormattedMessage id="app.metadata.workout_type.over_40_workouts" />, "value": "over_40_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.overweight_workouts" />, "value": "overweight_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.pilates" />, "value": "pilates" },
        { "label": <FormattedMessage id="app.metadata.workout_type.senior_workouts" />, "value": "senior_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.step_workouts" />, "value": "step_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.stretching_workouts" />, "value": "stretching_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.suspension_training_workouts" />, "value": "suspension_training_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.toning_workouts" />, "value": "toning_workouts" },
        { "label": <FormattedMessage id="app.metadata.workout_type.yoga_stretching_workouts" />, "value": "yoga_stretching_workouts" }],
    "body_focus": [
        { "label": <FormattedMessage id="app.metadata.body_focus.abs" />, "value": "abs" },
        { "label": <FormattedMessage id="app.metadata.body_focus.arms" />, "value": "arms" },
        { "label": <FormattedMessage id="app.metadata.body_focus.body_mind" />, "value": "body_mind" },
        { "label": <FormattedMessage id="app.metadata.body_focus.core" />, "value": "core" },
        { "label": <FormattedMessage id="app.metadata.body_focus.glutes" />, "value": "glutes" },
        { "label": <FormattedMessage id="app.metadata.body_focus.legs" />, "value": "legs" },
        { "label": <FormattedMessage id="app.metadata.body_focus.total_body" />, "value": "total_body" },
        { "label": <FormattedMessage id="app.metadata.body_focus.upper_body" />, "value": "upper_body" }],
    "equipment_required": [
        { "label": <FormattedMessage id="app.metadata.equipment_required.aerobic_step" />, "value": "aerobic_step" },
        { "label": <FormattedMessage id="app.metadata.equipment_required.barbell" />, "value": "barbell" },
        { "label": <FormattedMessage id="app.metadata.equipment_required.boxing_bag" />, "value": "boxing_bag" },
        { "label": <FormattedMessage id="app.metadata.equipment_required.boxing_gloves" />, "value": "boxing_gloves" },
        { "label": <FormattedMessage id="app.metadata.equipment_required.chair" />, "value": "chair" },
        { "label": <FormattedMessage id="app.metadata.equipment_required.mat" />, "value": "mat" },
        { "label": <FormattedMessage id="app.metadata.equipment_required.mini_trampoline" />, "value": "mini_trampoline" },
        { "label": <FormattedMessage id="app.metadata.equipment_required.stationary_bike" />, "value": "stationary_bike" },
        { "label": <FormattedMessage id="app.metadata.equipment_required.suspension_training" />, "value": "suspension_training" },
        { "label": <FormattedMessage id="app.metadata.equipment_required.weighted_plates" />, "value": "weighted_plates" }]
}

const SearchInput = (props) => {
    return <DebounceInput
        {...props} minLength={2}
        debounceTimeout={300} />
}

function getExpandedResults(collection, large, sliderStyle) {

    var result = collection.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / sliderStyle)

        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)
        return resultArray
    }, [])

    for (var i = 0; i < result.length; i++) {
        for (var j = result[i].length; j < sliderStyle; j++) {
            result[i].push("");
        }
    }

    return result.map((item, index) => {
        return <div className={styles.results}>
            {item.map((slideId, ix) => {
                if (slideId != "") {
                    return <div className={styles.hit} key={slideId.slide_id}>
                        <RadicalSwiperCard large={large} index={ix} slideId={slideId.slide_id} enumerate={collection.enumerate} />
                    </div>
                } else {
                    return <div className={styles.hit}></div>

                }
            })}

        </div>
    })
}

const Hit = ({ slide }) => {
    const name = UxService.localeValue(slide, "name");
    const description = UxService.localeValue(slide, "description");

    const legacyImg = useImageSelector(slide.images);
    const img = UxService.localeImage(slide, "images_slide") || legacyImg;

    return <div className={styles.hit}>
        <SwiperCard metadata={slide.metadata} key={slide.slide_id} img={img} slideId={slide.slide_id} name={name} description={description} alwaysVisible={false} />
    </div>
}

function RadicalSwiperCard({ large, slideId, index, enumerate, isVisible, cardsPerSwiper }) {

    const slide = useSlideFromId(slideId);
    const name = UxService.localeValue(slide, "name");
    const description = UxService.localeValue(slide, "description");

    const legacyImg = useImageSelector(slide.images, { large: large });
    // const img = UxService.localeImage(slide, (!large ? "images_slide" : "images_slide_vertical")) || legacyImg;
    const img = UxService.localeImageSet(slide, (!large ? "images_slide" : "images_slide_vertical"), null, {
        xs: 148,
        s: 222,
        m: 296,
        l: 592,
        xl: 1184,
    });
    const externalOpen = slide.live && slide.stream;

    return (<SwiperCard blurhash={img && img.blurhash} cardsPerSwiper={cardsPerSwiper} img={(img && img.default) || legacyImg} imgSrcSet={img && img.srcset} metadata={slide.metadata} showIsNewBand={slide.is_new} isVisible={isVisible} index={index} large={large} name={name} description={description} slideId={slideId} enumerate={enumerate} externalOpen={externalOpen} showLiveBand={!!externalOpen} />);
}

const SearchResults = ({ data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status }) => {

    const sliderStyle = useSliderStyle("main");
    const loader = useRef(null);
    const mutableHandleObserver = useRef(null);

    mutableHandleObserver.current = () => {
        if (status !== 'loading' && status !== 'error' && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }

    useEffect(() => {
        var options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0
        };
        const handleObserver = (entities) => {
            const target = entities[0];
            if (target.isIntersecting && mutableHandleObserver.current) {
                mutableHandleObserver.current()
            }
        }

        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current)
        }
    }, []);

    const show = (status !== 'loading') && data;
    const no_hits = data && data.pages && data.pages.length > 0 && data.pages[0].hits.length <= 0;

    return <div>
        {(status === 'loading') && <div className={styles.searchLoading}>
            <FormattedMessage id="app.messages.search_loading" />
        </div>}
        {no_hits && <div className={Environment.brand === "rhf" ? styles.noResultsText : styles.noResultsTextRfTv}>
            <FormattedMessage id="app.messages.no_hits" />
        </div>}

        {show && !data.localNoData && <div>
            <div >
                {data.pages.length > 0 && data.pages.map((group, i) => (
                    getExpandedResults(group.hits, false, sliderStyle.slidesPerView)
                    /*group.hits.map(c => (
                        <Hit slide={c} />
                    ))*/

                ))}



                {isFetchingNextPage && <div className={styles.hit}>
                    <FormattedMessage id="app.messages.search_loading_more" />
                </div>}

                {!no_hits && !isFetching && !hasNextPage && <div className={styles.hit}>
                </div>}
            </div>

            {/* <div>
                <button
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}
                >
                    {isFetchingNextPage
                        ? 'Loading more...'
                        : hasNextPage
                            ? 'Load More'
                            : 'Nothing more to load'}
                </button>
            </div> */}

            <div>
                {isFetching && !isFetchingNextPage ? 'Fetching...' : null}
            </div>
        </div>}

        {(status === 'error') && <div className={styles.error}><FormattedMessage id="app.messages.error_search" /></div>}

        <div style={{ width: "50px", height: "50px" }} ref={loader}></div>
    </div>
}

const validQuery = (q, metadata) => {
    const anyMetadata = (metadata.impact && metadata.impact.length > 0)
        || (metadata.difficulty && metadata.difficulty.length > 0)
        || (metadata.body_focus && metadata.body_focus.length > 0)
        || (metadata.workout_type && metadata.workout_type.length > 0)
        || (metadata.equipment_required && metadata.equipment_required.length > 0)
        || (metadata.equipment && metadata.equipment.length > 0);

    return (q && q.length >= 2) || anyMetadata;
}

const fetchQuery = (fetchParams) => {
    const q = fetchParams.queryKey[fetchParams.queryKey.length - 2];
    const metadata = fetchParams.queryKey[fetchParams.queryKey.length - 1];

    if (validQuery(q, metadata)) {
        return SearchService.search((fetchParams.pageParam || 1), q, metadata);
    } else {
        return Promise.resolve({ localNoData: true });
    }
}

const Search = ({ onCancel, onProfile }) => {
    const [q, setQ] = React.useState("");
    const [metadata, setMetadata] = React.useState({});
    const [showAdvancedOptions, setShowAdvancedOptions] = React.useState(false);

    useLayoutEffect(() => {
        // SEE: https://stackoverflow.com/questions/42308209/chrome-browser-automatically-scrolling-down-the-content-when-nobody-asked-it-to
        setTimeout(function () { window.scrollTo(0, 0); }, 1)
    }, []);

    const handleMetadataChange = (field, value) => {
        setMetadata((metadata) => ({
            ...metadata,
            [field]: value
        }))
    }

    const handleChange = (event) => {
        const newTerm = event.target.value;
        if (newTerm !== q) {
            setQ(newTerm);
        }
    };


    // # https://github.com/tannerlinsley/react-query/issues/307
    // https://react-query.tanstack.com/guides/infinite-queries
    const query = useInfiniteQuery(['projects', q, metadata], fetchQuery, {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage && lastPage.nextPage) {
                return (lastPage.nextPage);
            }
        },
        refetchOnWindowFocus: false,
    });

    const adornment = q ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faSearch} />;
    const adornmentClick = () => {
        if (q) {
            setQ("");
        }
    };

    const isValidQuery = validQuery(q, metadata);

    const intl = useIntl();

    const classes = useStyles();

    return <ThemeProvider theme={theme}>
        <div className={styles.root}>
            <CloseButton onClick={onCancel} />

            {/*<div className={styles.titleContainer}>
                <div className={styles.titleSearch}><FormattedMessage id="app.titles.search" /></div>
            </div>

            <div className={styles.explain}>
                <FormattedMessage id="app.messages.search_explain" />
            </div>*/}

            <div className={styles.searchControls}>
                {/*<GroupBox>*/}
                <div className={styles.formControlContainer}>
                    <FormControl className={styles.margin}>
                        {/*<InputLabel htmlFor="search">
                                <FormattedMessage id="app.fields.search" />
                            </InputLabel>*/}
                        <Input
                            id="search"
                            value={q}
                            placeholder={intl.formatMessage({ id: "app.titles.search_short" })}
                            inputComponent={SearchInput}
                            onChange={handleChange}
                            autoComplete="off"
                            classes={{ input: classes.input }}
                            startAdornment={<InputAdornment onClick={adornmentClick} position="start">{adornment}</InputAdornment>}
                        />
                    </FormControl>
                </div>

                {!showAdvancedOptions && <div className={Environment.brand === "rhf" ? styles.showMoreOptions : styles.showMoreOptionsRfTv} onClick={() => (setShowAdvancedOptions(true))}>
                    <FormattedMessage id="app.messages.show_advanced_search_options" />
                </div>}
                {showAdvancedOptions && <div className={styles.moreOptionsContiner}>
                    {/*<div className={styles.advancedSearchtext}><FormattedMessage id="app.fields.advanced_options" /></div>*/}
                    {/*<GroupBox>*/}
                    <div className={styles.moreOptions}>
                        <div className={styles.fieldGroupOfThree}>
                            <div className={styles.advancedOptionFirst}><MultiSelect label={<FormattedMessage id="app.fields.impact" />} options={options.impact} value={metadata.impact} onChange={(value) => {
                                handleMetadataChange("impact", value);
                            }} /></div>
                            <div className={styles.advancedOption}><MultiSelect label={<FormattedMessage id="app.fields.difficulty" />} options={options.difficulty} value={metadata.difficulty} onChange={(value) => {
                                handleMetadataChange("difficulty", value);
                            }} /></div>
                            <div className={styles.advancedOption}><MultiSelect label={<FormattedMessage id="app.fields.body_focus" />} options={options.body_focus} value={metadata.body_focus} onChange={(value) => {
                                handleMetadataChange("body_focus", value);
                            }} /></div>
                        </div>
                        <div className={styles.fieldGroupOfThree}>
                            <div className={styles.advancedOption}><MultiSelect label={<FormattedMessage id="app.fields.workout_type" />} options={options.workout_type} value={metadata.workout_type} onChange={(value) => {
                                handleMetadataChange("workout_type", value);
                            }} /></div>
                            <div className={styles.advancedOption}><MultiSelect label={<FormattedMessage id="app.fields.equipment" />} options={options.equipment} value={metadata.equipment} onChange={(value) => {
                                handleMetadataChange("equipment", value);
                            }} /></div>
                            <div className={styles.advancedOption}><MultiSelect label={<FormattedMessage id="app.fields.equipment_required" />} options={options.equipment_required} value={metadata.equipment_required} onChange={(value) => {
                                handleMetadataChange("equipment_required", value);
                            }} /></div>
                        </div>
                    </div>
                    {/*</GroupBox>*/}
                    <div className={Environment.brand === "rhf" ? styles.showMoreOptions : styles.showMoreOptionsRfTv} onClick={() => {
                        setMetadata({});
                        setShowAdvancedOptions(false);
                    }}>
                        <FormattedMessage id="app.messages.hide_advanced_search_options" />
                    </div>
                </div>}
                {/*</GroupBox>*/}
            </div>


            <div className={styles.resultsContainer}>
                {!isValidQuery && <div className={styles.start}>

                </div>}
                {isValidQuery && <SearchResults {...query} />}
            </div>
        </div>
    </ThemeProvider>;
}

const AppSearch = () => {
    const navigation = useRadicalNavigation();
    const backToLibrary = () => {
        navigation.main();
    };

    return <CSSTransition appear={true} in={true} timeout={200} classNames="popup-profile-fade">
        <Search onCancel={backToLibrary} />
    </CSSTransition>
}

export default AppSearch;

// 1 - search
// 2 - mostrar resultados
// 3 - infinite scroll
// 4 - end of road