import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import ja from "./ja.json";
import zh from "./zh.json";

const flatten = (obj, path = '') => {
    if (!(obj instanceof Object)) return { [path.replace(/\.$/g, '')]: obj };

    return Object.keys(obj).reduce((output, key) => {
        return obj instanceof Array ?
            { ...output, ...flatten(obj[key], path + '[' + key + '].') } :
            { ...output, ...flatten(obj[key], path + key + '.') };
    }, {});
}

const fallbackTo = (lang, fallback) => {
    Object.keys(fallback).forEach((key) => {
        lang[key] = lang[key] || fallback[key];
    });

    return lang;
}

const fallback = flatten(en);

export const translations = {
    en: fallback,
    es: fallbackTo(flatten(es), fallback),
    fr: fallbackTo(flatten(fr), fallback),
    ja: fallbackTo(flatten(ja), fallback),
    zh: fallbackTo(flatten(zh), fallback),
};

export function getLocale(defaultLocale) {
    if (defaultLocale) {
        if (translations[defaultLocale]) {
            return defaultLocale;
        }
    }

    const browser = window.navigator.userLanguage || window.navigator.language;
    if (browser) {
        const candidate = browser.substring(0, 2)
        if (translations[candidate]) {
            return candidate;
        }
    }

    return 'en';
}
