import styles from "./History.module.scss";
import ErrorWithText from "../ErrorWithText";
import UxService from "../../services/UxService";
import { FormattedMessage, FormattedDate } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faRunning, faAngleRight, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import BitSet from "bitset";
import clsx from 'clsx';
import RadicalModal from "../RadicalModal";
import radicalLogo from '../../assets/logo_apaisado_RH.png';
import rfTvLogo from '../../assets/logo_apaisado_RF.png';
import Environment from '../../services/Environment';

const calculateCalories = (calories, gender, videoLength, trainingLength) => {
    let forVideo = 0;

    if (calories) {
        if (gender === "M" || gender === "O") {
            forVideo = calories.male;
        } else if (gender === "F") {
            forVideo = calories.male;
        } else {
            forVideo = Math.floor((parseInt(calories.male) + parseInt(calories.female)) / 2);
        }
    } else {
        let sampleLength = 3600;
        let sampleCalories = 800;

        // - try with length
        if (videoLength <= 60) {
            sampleLength = 60;
            sampleCalories = 5
        } else if (videoLength <= 300) {
            sampleLength = 300;
            sampleCalories = 40
        } else if (videoLength <= 900) {
            sampleLength = 900;
            sampleCalories = 150
        } else if (videoLength <= 1800) {
            sampleLength = 1800;
            sampleCalories = 300
        }

        forVideo = Math.floor((sampleCalories * videoLength) / sampleLength);
    }

    return Math.floor((forVideo * trainingLength) / videoLength);
};

function serializeDate(d) {
    if (!d) return null;

    return d.toISOString().substring(0, 10);
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function addMonths(date, months) {
    var result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
}

function firstOfMonth(date) {
    var result = new Date(date);
    result.setDate(1);
    return result;
}

function firstOfWeek(date) {
    return addDays(date, -date.getDay() + 1);
}

const Title = ({ onNext, onPrev, title, showNext }) => {
    return <h3 className={styles.daySelectedTitle}>
        <span onClick={onPrev} className={styles.clickable}>
            <FontAwesomeIcon icon={faAngleLeft} />
        </span>
        &nbsp;
        {<FormattedMessage id="app.titles.training_summary" values={{ date: title }} />}
        &nbsp;
        {showNext && <span onClick={onNext} className={styles.clickable}>
            <FontAwesomeIcon icon={faAngleRight} />
        </span>}
    </h3>
}

const MonthWeekDay = ({ loading, onDateChange, date, monthDate, data, selected, selectedDate }) => {
    const hasData = data.videos > 0;
    const today = new Date();
    const future = today <= date;

    if (date.getMonth() === monthDate.getMonth()) {
        return <div className={clsx(styles.monthDay, hasData && styles.monthDayWithData, selected && styles.monthDaySelected, future && styles.monthDayFuture)} onClick={() => (!future && onDateChange(date))}>
            <span className={styles.monthDayNumber}>{date.getDate()}</span>
            {!loading && !hasData && !future && <span className={styles.monthDayCross}>
                <FontAwesomeIcon icon={faTimes} />
            </span>}
            {!loading && hasData && !future && <span className={Environment.brand === "rhf" ? styles.monthDayCheck : styles.monthDayCheckRfTv}>
                <FontAwesomeIcon icon={faRunning} />
            </span>}
            {loading && <span className={styles.monthDayCross}>
                <FontAwesomeIcon icon={faSpinner} pulse />
            </span>}
        </div>
    } else {
        return <div className={clsx(styles.monthDay, styles.monthDayOutside)}></div>;
    }
};

const MonthWeek = ({ onDateChange, date, monthDate, data, selectedDate, loading }) => {
    return <div className={styles.monthWeek}>
        {[0, 1, 2, 3, 4, 5, 6].map((day) => {
            const dateDay = addDays(date, day);
            return <MonthWeekDay loading={loading} selected={dateDay.getTime() === selectedDate.getTime()} key={day} data={dayResume(data, dateDay)} onDateChange={onDateChange} monthDate={monthDate} date={dateDay} />
        })}
    </div>
}

const Month = ({ loading, data, date, onDateChange }) => {
    const monthDate = firstOfMonth(date);
    const monthDateFirstOfWeek = firstOfWeek(monthDate);
    const endOfMonth = addDays(addMonths(monthDate, 1), -1);
    const today = new Date();

    const calories = data.total.calories;
    const exercises = data.total.exercises;
    const seconds = data.total.seconds;

    return <div className={styles.root}>

        <div className={styles.logoRadicalContainer}><img className={styles.logoRadical} src={Environment.brand === "rhf" ? radicalLogo : rfTvLogo} alt="logo"></img></div>

        <Title
            loading={loading}
            onNext={() => (onDateChange(addMonths(monthDate, 1)))}
            onPrev={() => (onDateChange(addMonths(monthDate, -1)))}
            showNext={firstOfMonth(addMonths(monthDate, 1)) <= today}
            title={<FormattedDate
                value={monthDate}
                year="numeric"
                month="long"
            />}
        />

        <div className={Environment.brand === "rhf" ? styles.summaryContainer : styles.summaryContainerRfTv}>
            <div>
                <h3><FormattedMessage id="app.fields.calories" /></h3>
                <p>{calories}</p>
            </div>
            <div className={styles.separator} >
                <h3><FormattedMessage id="app.fields.sessions" /></h3>
                <p>{exercises}</p>
            </div>
            <div>
                <h3><FormattedMessage id="app.fields.time_trained" /></h3>
                <p>{formatTime(seconds)}</p>
            </div>
        </div>
        <div className={styles.calendarRoot}>
            <div className={styles.monthRoot}>
                <div className={styles.monthWeek}>

                    <div className={styles.monthWeekHeader}><FormattedMessage id="app.days.monday" /></div>
                    <div className={styles.monthWeekHeader}><FormattedMessage id="app.days.tuesday" /></div>
                    <div className={styles.monthWeekHeader}><FormattedMessage id="app.days.wedensday" /></div>
                    <div className={styles.monthWeekHeader}><FormattedMessage id="app.days.thursday" /></div>
                    <div className={styles.monthWeekHeader}><FormattedMessage id="app.days.friday" /></div>
                    <div className={styles.monthWeekHeader}><FormattedMessage id="app.days.saturday" /></div>
                    <div className={styles.monthWeekHeader}><FormattedMessage id="app.days.sunday" /></div>
                </div>
                {[0, 1, 2, 3, 4, 5].map((week) => {
                    return (endOfMonth >= addDays(monthDateFirstOfWeek, week * 7)) && <MonthWeek loading={loading} data={data} selectedDate={date} onDateChange={onDateChange} monthDate={monthDate} date={addDays(monthDateFirstOfWeek, week * 7)} />
                })
                }
            </div>
        </div>


    </div>;
}

const dayResume = (data, date) => {
    const day = data && data[serializeDate(date)];

    if (!day) {
        return {
            seconds: 0,
            videos: 0,
            calories: 0,
            list: [],
        }
    } else {
        return {
            calories: day.map((e) => (e.calories)).reduce((sum, x) => sum + x),
            seconds: day.map((e) => (e.seconds)).reduce((sum, x) => sum + x),
            videos: [...new Set(day.map((e) => (e.videoId)))].length,
            list: day.sort((a, b) => (a.started - b.started)),
        }
    }
}

const parseData = (data) => {
    if (!data) return {
        total: {
            seconds: 0,
            calories: 0,
            exercises: 0
        }
    };

    const rv = {
        total: {
            seconds: 0,
            calories: 0,
            exercises: 0
        }
    };

    data.played.forEach((p) => {
        const d = new Date(parseInt(p.started));
        const sd = serializeDate(d);

        const playedBitSet = BitSet.fromHexString(p.played);
        let seconds = 0;
        for (let b of playedBitSet) {
            seconds += (b === 1 ? 10 : 0);
        }

        const slide = data.elements.find((e) => p.slide_id === e.id);
        const slideInfo = data.slides.find((s) => (s.id === p.slide_id));

        if (slideInfo) {
            let video = null;
            let name = "";
            if (p.slide_id !== p.video_id) {
                const allVideos = slide && slide.seasons.map((s) => (s.chapters)).flat();
                video = slide && allVideos.find((e) => p.video_id === e.id);
                name = UxService.videoName(video, slideInfo);
            } else {
                video = slide && slide.seasons && slide.seasons[0].chapters[0];
                name = UxService.slideName(slideInfo);
            }
            if (video) {
                let calories = 0;
                if (!video.no_exercise) {
                    calories = calculateCalories(video.calories, p.calories_stats && p.calories_stats.gender, (video.duration * 60) || seconds, seconds)
                }

                const dateInfo = rv[sd] || [];
                dateInfo.push({
                    seconds,
                    name,
                    //            images: video.images,
                    slideId: p.slide_id,
                    videoId: p.video_id,
                    started: parseInt(p.started),
                    calories: calories,
                    difficulty: video.difficulty
                });

                rv.total.seconds += seconds;
                rv.total.calories += calories;
                rv.total.exercises += 1;
                rv[sd] = dateInfo;
            }
        }
    });

    console.log(rv);
    return rv;
}

const formatTime = (s) => {
    const hours = Math.floor(s / 3600);
    const minutes = Math.floor((s % 3600) / 60);
    const seconds = s % 60;

    const strH = (hours > 0) ? `${hours}H ` : "";
    const strM = (minutes < 10 && strH) ? `0${minutes}` : `${minutes}`;
    const strS = (seconds < 10) ? `0${seconds}` : `${seconds}`;

    return strH + strM + "M " + strS + "S";
}

const Sample = ({ loading, date, data }) => {
    const hasData = data.videos > 0;

    return <div className={styles.dataContainer}>
        <div>
            <h3 className={styles.daySelectedTitle}>
                <FormattedMessage id="app.titles.training" values={{
                    date: <FormattedDate
                        value={date}
                        year="numeric"
                        month="long"
                        day="2-digit"
                    />
                }} />
            </h3>
        </div>

        {!hasData && <div className={Environment.brand === "rhf" ? styles.summaryContainer : styles.summaryContainerRfTv}>
            <h4><FormattedMessage id="app.messages.no_training_today" /></h4>
        </div>}

        {hasData && <div className={Environment.brand === "rhf" ? styles.summaryContainer : styles.summaryContainerRfTv}>
            <div>
                <h3><FormattedMessage id="app.fields.calories" /></h3>
                <p>{data.calories}</p>
            </div>
            <div className={styles.separator} >
                <h3><FormattedMessage id="app.fields.sessions" /></h3>
                <p>{data.list.length}</p>
            </div>
            <div>
                <h3><FormattedMessage id="app.fields.time_trained" /></h3>
                <p>{formatTime(data.seconds)}</p>
            </div>
        </div>}

        {hasData && data && data.list.map((v, ix) => {
            return <div className={styles.trainingSessionContainer} key={ix}>
                <h3>{v.name}</h3>
                <div className={styles.dayInfoContainer}>
                    <div>
                        <p><FormattedMessage id="app.fields.calories" /></p>
                        <p className={styles.dataHighlight}>{v.calories}</p>
                    </div>
                    <div>
                        <p><FormattedMessage id="app.fields.time_trained" /></p>
                        <p className={styles.dataHighlight}>{formatTime(v.seconds)}</p>
                    </div>
                    <div>
                        <p><FormattedMessage id="app.fields.difficulty" /></p>
                        <p className={styles.dataHighlight}>
                            {v.difficulty && <FormattedMessage id={`app.difficulty.${v.difficulty}`} />}
                            {!v.difficulty && "-"}
                        </p>
                    </div>
                </div>

            </div>


        })}
    </div>;
}
const History = ({ loading, error, data, onCancel, date, view, onDateChange }) => {
    const parsedData = parseData(data);

    return <RadicalModal onClose={onCancel} title={<FormattedMessage id="app.titles.history" />}>
        <div className={styles.modalRoot}>
            {!error && <Month loading={loading} date={date} data={parsedData} onDateChange={onDateChange} />}

            {!error && !loading && <Sample loading={loading} date={date} data={dayResume(parsedData, date)} />}

            {error && <ErrorWithText
                textBig={<FormattedMessage id="app.messages.error_history_big" />}
                textSmall={<FormattedMessage id="app.messages.error_history_small" />}
                onBigClick={onCancel}
            />}
        </div>
    </RadicalModal>
}

export default History;