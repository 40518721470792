import CloseButton from "./../Buttons/CloseButton";
import styles from './ItemCompleted.module.scss';
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faFire } from '@fortawesome/free-solid-svg-icons'
import Environment from '../../services/Environment';

function ItemCompleted({ children, stats, onClose }) {
    return <div className={styles.root}>
        <div className={styles.statsWrapper}>
            <CloseButton onClick={onClose} className={styles.statsCloseButton} />
            {!stats.no_exercise && <div className={styles.stats}>
                <div className={Environment.brand === "brand" ? styles.titleGreatJob : styles.titleGreatJobRfTv}><FormattedMessage id="app.messages.stats_title" /></div>
                <div className={styles.textStats}><FormattedMessage id="app.messages.stats_text" /></div>
                <div className={styles.statsContainer}>
                    <div className={styles.statsContainerItem}>
                        <div className={styles.statsContainerItemIcon}><FontAwesomeIcon icon={faFire} /></div>
                        <div className={styles.statsContainerItemTitle}>Burned calories</div>
                        <div className={Environment.brand === "brand" ? styles.statsContainerItemValue : styles.statsContainerItemValueRfTv}>{stats.calories}</div>
                    </div>
                    <div className={styles.statsContainerItem}>
                        <div className={styles.statsContainerItemIcon}><FontAwesomeIcon icon={faClock} /></div>
                        <div className={styles.statsContainerItemTitle}>Exercise time</div>
                        <div className={Environment.brand === "brand" ? styles.statsContainerItemValue : styles.statsContainerItemValueRfTv}>{stats.time}</div>
                    </div>
                </div>
            </div>}
            {children}
        </div>

    </div>
}

export default ItemCompleted;