import React, { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { useIntl } from "react-intl";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import styles from './PasswordRecover.module.scss';
import Environment from "../../services/Environment";
import SessionService from "../../services/SessionService";
import MaterialUiThemeLogin from "../MaterialUiThemeLogin";
import Button from '@material-ui/core/Button';
import ReCAPTCHA from "react-google-recaptcha"
import rhfLogo from '../../assets/logo_apaisado_RH.png';
import backdrop from '../Welcome/res/img/rhf_back.jpg';



function Form({ locale, success, onClick, inProgress, error, onReturn, noTenant }) {
    const intl = useIntl();

    const recaptchaRef = useRef();
    const [email, setEmail] = useState('');
    const [captcha, setCaptcha] = useState('');

    const captchaReset = () => {
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
            setCaptcha("");
        }
    }

    if (success) {
        return <div className={styles.form}>
            <div className={styles.field}>
                <p>{intl.formatMessage({ id: "app.messages.check_mail_to_recover_password" })}</p>
            </div>
            <div className={styles.field}>
                <Button variant="contained" onClick={onReturn}>{intl.formatMessage({ id: "app.fields.back_to_sign_in" })}</Button>
            </div>
        </div>
    }

    return (<div className={styles.form}>
        <div className={styles.field}>
            <p className={styles.textInstructions}>{intl.formatMessage({ id: "app.messages.enter_mail_to_recover_password" })}</p>

            <TextField
                fullWidth
                required id="standard-required"
                label={intl.formatMessage({ id: "app.fields.email" })}
                disabled={inProgress}
                value={email}
                onChange={(event) => setEmail(event.target.value)}

            />
        </div>
        <div className={styles.field}>
            <ReCAPTCHA hl={locale} ref={recaptchaRef} sitekey={Environment.recaptcha} onChange={(value) => setCaptcha(value)} />
        </div>

        <div className={styles.fieldSignIn}>
            {!inProgress && error && <div className={styles.message}>{error.message || error}</div>}
            {!inProgress && <Button variant="contained" onClick={() => onClick(captchaReset, email, captcha)}>{intl.formatMessage({ id: "app.fields.recover_password" })}</Button>}
            {inProgress && <Button disabled variant="contained">{intl.formatMessage({ id: "app.messages.checking_email_in_progress" })}</Button>}
        </div>
    </div>);
}

function PasswordRecover({ gym, locale, noTenant }) {
    const navigation = useRadicalNavigation();
    const [post, setPost] = useState({ inProgress: false, tainted: false });

    const onReturn = () => {
        navigation.login();
    }

    const clickHandler = (captchaReset, email, captcha) => {
        setPost({ inProgress: true });
        SessionService.recoverPassword(gym.franchiseCode, gym.slug, gym.type, email, captcha, noTenant).then((data) => {
            setPost({ inProgress: false, success: true });
        }).catch(error => {
            captchaReset();
            setPost({ inProgress: false, success: false, error: error });
        });
    };

    return (
        <MaterialUiThemeLogin>
            <div className={styles.loginContainer} style={{ background: `url(${backdrop}) no-repeat center center/cover` }}>
                {Environment.brand === "rhf" ? <img src={rhfLogo} className={styles.logo} alt="logo" /> : <></>}

                <div className={styles.formContainer}>
                    {gym.img && (
                        <div className={styles.logoContainer}>
                            <img src={gym.img} alt="gym" className={styles.logoImgSize} />
                        </div>
                    )}
                    <div className={styles.fieldsContainer}>
                        <Form noTenant={noTenant} locale={locale} onReturn={onReturn} success={post.success} inProgress={post.inProgress} error={post.error} onClick={clickHandler} />
                    </div>
                </div>
            </div>
        </MaterialUiThemeLogin>
    );
}

export default PasswordRecover;