import { useRef, useEffect } from 'react';

import CloseButton from "./../Buttons/CloseButton";

import styles from './RadicalModal.module.scss';


function backdropClose(onClose) {
    return (event) => {
        if (event.target.classList && event.target.classList.contains(styles.radicalModal)) {
            if (onClose) {
                onClose();
            }
        }
    }
};

function backdropKeyDown(onClose) {
    return (event) => {
        if (event.key === "Esc" || event.key === "Escape") {
            if (onClose) {
                onClose();
            }
        }
    }
};

function RadicalModal({ title, children, onClose }) {
    const rootRef = useRef(null);

    // EXPLAIN: Focus the div on the first render in
    // order to catch keyboard events and thus
    // allowing the user to close with "esc" key
    useEffect(() => {
        rootRef.current.focus();
    }, []);

    return <div className={styles.radicalModal} onClick={backdropClose(onClose)} tabIndex={0} onKeyDown={backdropKeyDown(onClose)} ref={rootRef}>
        <div className={styles.contents}>
            <div className={styles.titleContainer}>
                <h3>{title}</h3>
                {onClose && <CloseButton onClick={onClose} />}
            </div>

            {children}
        </div>
    </div>
}

export default RadicalModal;