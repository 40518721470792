function useImageSelector(images) {
    if (!images) {
        return null;
    }
        
    // console.log(images);
    if (images.en) {
        return images.en[0];
    } else if (images.es) {
        return images.es[0];
    } else {
        return null;
    }
}

export default useImageSelector;