import React, { useState, useRef, useEffect, useCallback } from 'react';
import TextField from '@material-ui/core/TextField';
import { useIntl, FormattedMessage } from "react-intl";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import styles from './Checkout.module.scss';
import Environment from "../../services/Environment";
import SessionService from "../../services/SessionService";
import MaterialUiThemeLogin from "../MaterialUiThemeLogin";
import Button from '@material-ui/core/Button';
import ReCAPTCHA from "react-google-recaptcha"
import rtvLogo from '../Login/res/img/RTV-logo-relleno.png';
import backdrop from '../Welcome/res/img/rhf_back.jpg';
import { makeStyles } from '@material-ui/core/styles';
import * as EmailValidator from "email-validator";
import backgroundImg from './res/img/rftv-back-singup.jpg';
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import rfLogo from '../../assets/logo_apaisado_RF.png';
import UxService from '../../services/UxService';
import LoginContainer from '../LoginContainer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// x - seleccion de plans
// - validar form
// - submit + redirect

const useStyles = makeStyles({
    root: {
        //minWidth: 275,
        backgroundColor: "#e5e5e5",
        marginBottom: 5,
        borderRadius: 5,
        padding: 5,
        minHeight: "70px",
        border: "10px solid #333",
        cursor: "pointer",
    },
    rootSelected: {
        // backgroundColor: "#343434",
        border: "10px solid #5fb1df",

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        color: "white",
        fontSize: 14,
    },
    pos: {
        color: "white",
        marginBottom: 12,
    },
    cardContainer: {
        flexDirection: 'row',
        //display: "flex"
    },
    label: {
        color: "#eb3423",
        fontSize: "99px"
    },
    fullWidth: {
        marginBottom: 20
    }
});

function Prices({ plans, currency, value, onChange, inProgress }) {
    return <div className={styles.plansWrapper}>
        {plans.map((plan) => (
            <PriceCard key={plan.id} disabled={inProgress} plan={plan} currency={currency} selected={value === plan.id} onClick={() => onChange(plan.id)} />
        ))}
    </div>;
}

function PriceCard({ plan, currency, selected, onClick, disabled }) {
    const intl = useIntl();
    const classes = useStyles();
    const freeTrialSpan = intl.formatMessage({ id: `app.checkout.periods.${plan.trial.time}` }, { x: plan.trial.length })

    const price = intl.formatNumber(plan.price, { style: 'currency', currency });

    return (
        <div className={styles.priceWrapper}>
            <div className={clsx(classes.root, selected && classes.rootSelected)} onClick={onClick}>
                <div style={{ display: 'flex', flexDirection: "column", alignContents: "center", alignItems: "center", justifyContent: 'space-between' }}>

                    <p style={{ margin: "5px", fontSize: 20, textAlign: "center", color: '#333' }}> {intl.formatMessage({ id: `app.checkout.periods.${plan.period}` })}</p>
                    <p style={{ margin: "5px", fontSize: 24, textAlign: "center", fontWeight: 'bold', color: '#333' }}>{price}</p>
                    {/* {intl.formatMessage({ id: `app.checkout.explain.${plan.period}` })} */}
                </div>
            </div>
            {/* <div style={{ fontStyle: 'italic', textAlign: 'center', fontSize: 12, color: "#e5e5e5" }}>
                {intl.formatMessage({ id: `app.checkout.messages.include_free_trial` }, { span: freeTrialSpan })}
            </div> */}
        </div>
    );
}

const testAutoFill = false;
function Form({ locale, email, inactiveUser, success, onClick, inProgress, error, onReturn, errorsToShow }) {
    const intl = useIntl();

    // const cizoEmail = !testAutoFill ? "" : `cizo${Date.now().toString()}@example.com`;

    const classes = useStyles();
    const recaptchaRef = useRef();
    const [firstName, setFirstName] = useState(!testAutoFill ? null : 'TestFirstName');
    const [lastName, setLastName] = useState(!testAutoFill ? null : 'TestLastName');
    const [coupon, setCoupon] = useState('');
    const [password, setPassword] = useState(!testAutoFill ? null : '123456');
    const [passwordConfirmation, setPasswordConfirmation] = useState(!testAutoFill ? null : '123456');
    const [captcha, setCaptcha] = useState('');
    const [hasPassword, sethasPassword] = useState(false);
    const onhasPasswordChange = useCallback((event) => {
        sethasPassword(event.target.checked);
    }, [sethasPassword])

    const isEmpty = (val) => {
        return (!val || val.trim() === "");
    }

    // const validateEmail = (errors) => {
    //     if (isEmpty(email) || isEmpty(emailConfirmation)) {
    //         errors.push("email_and_confirmation_complete")
    //     } else if (email?.trim() !== emailConfirmation?.trim()) {
    //         errors.push("email_and_confirmation_match")
    //     } else if (!EmailValidator.validate(email)) {
    //         errors.push("email_format")
    //     }

    // }

    const validatePassword = (errors) => {
        if (!inactiveUser || !hasPassword) {
            return;
        }

        if (isEmpty(password) || isEmpty(passwordConfirmation)) {
            errors.push("password_and_confirmation_complete")
        } else if (password?.trim() !== passwordConfirmation?.trim()) {
            errors.push("password_and_confirmation_match")
        }
    }

    const validateNames = (errors) => {
        if (isEmpty(firstName)) {
            errors.push("first_name_complete")
        } else if (isEmpty(lastName)) {
            errors.push("last_name_complete")
        }
    }

    const onOk = () => {
        const errors = [];

        // 0 - validate first + last name
        validateNames(errors);

        // 1 - validate email
        // validateEmail(errors);

        // 2 - validate password
        validatePassword(errors);


        onClick(captchaReset, email, password, hasPassword, firstName, lastName, coupon, captcha, errors);
    }
    const captchaReset = () => {
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
            setCaptcha("");
        }
    }

    if (success) {
        return <div className={styles.form}>
            <div className={styles.field}>
                <p>{intl.formatMessage({ id: "app.messages.redirecting_to_checkout" })}</p>
            </div>
        </div>
    }

    return (<div className={styles.form}>
        <div className={styles.field}>
            <p className={styles.textInstructions}>{intl.formatMessage({ id: "app.messages.create_account_msg" })}</p>
            <TextField
                fullWidth
                required id="standard-required"
                label={intl.formatMessage({ id: "app.fields.first_name" })}
                disabled={inProgress}
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                className={clsx(classes.fullWidth)}
            />

            <TextField
                fullWidth
                required id="standard-required"
                label={intl.formatMessage({ id: "app.fields.last_name" })}
                disabled={inProgress}
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                className={clsx(classes.fullWidth)}

            />

            {inactiveUser && (
                <div>
                    <div className={{}}>{intl.formatMessage({ id: "app.messages.create_account_magic_link_text" })}</div>

                    <FormControlLabel
                        control={<Checkbox color="primary" checked={hasPassword} onChange={onhasPasswordChange} />}
                        label={<FormattedMessage id="app.messages.create_new_password" />}
                    />
                </div>
            )}

            {inactiveUser && hasPassword && (
                <TextField
                    fullWidth
                    required id="standard-required"
                    type="password"
                    label={intl.formatMessage({ id: "app.fields.password" })}
                    disabled={inProgress}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    className={clsx(classes.fullWidth)}

                />
            )}

            {inactiveUser && hasPassword && (
                <TextField
                    fullWidth
                    required id="standard-required"
                    type="password"
                    label={intl.formatMessage({ id: "app.fields.password_confirmation" })}
                    disabled={inProgress}
                    value={passwordConfirmation}
                    onChange={(event) => setPasswordConfirmation(event.target.value)}
                    className={clsx(classes.fullWidth)}
                />
            )}

            <TextField
                fullWidth
                id="standard-required"
                label={intl.formatMessage({ id: "app.fields.coupon" })}
                disabled={inProgress}
                value={coupon}
                onChange={(event) => setCoupon(event.target.value)}
                className={clsx(classes.fullWidth)}
            />

        </div>
        <div className={styles.recaptcha}>
            <ReCAPTCHA hl={locale} ref={recaptchaRef} sitekey={Environment.recaptcha} onChange={(value) => setCaptcha(value)} />
        </div>
        <div className={styles.fieldSignIn} style={{ marginTop: 0 }}>
            {!inProgress && error && <div className={styles.message}>{error.message || error}</div>}


            {errorsToShow && errorsToShow.length > 0 && (
                <div >
                    {errorsToShow.map((err) => (
                        <div className={styles.errorMessage}>{err}</div>
                    ))}
                </div>
            )}
        </div>
        <div className={styles.fieldSignIn}>
            <div style={{ flexDirection: 'row', justifyContent: 'space-evenly', display: 'flex' }}>
                {!inProgress && <div><Button style={{ paddingRight: 10 }} variant="contained" onClick={onOk}>{intl.formatMessage({ id: "app.fields.start_checkout" })}</Button></div>}
                {!inProgress && <div><Button variant="contained" onClick={onReturn}>{intl.formatMessage({ id: "app.fields.cancel" })}</Button></div>}
            </div>
            {inProgress && <Button disabled variant="contained">{intl.formatMessage({ id: "app.messages.checking_email_in_progress" })}</Button>}
        </div>
    </div>);
}


function Success({ tenant }) {
    const navigation = useRadicalNavigation();
    const intl = useIntl();
    return (
        <>
            <p className={styles.textCongrats}>{intl.formatMessage({ id: "app.messages.checkout_success_1" })}</p>
            <p className={styles.textInstructions}>{intl.formatMessage({ id: "app.messages.checkout_success_2" })}</p>
            <p className={styles.textInstructions}>{intl.formatMessage({ id: "app.messages.checkout_success_3" })}</p>
            <Button onClick={() => {
                SessionService.reset();
                navigation.loginToTenant(tenant, true);
            }} variant="contained">
                {intl.formatMessage({ id: "app.messages.back_to_sign_in" })}
            </Button>
        </>
    );
}

function InCheckout({ }) {
    return <div>Checkout in progress</div>
}

function Failed({ }) {
    return <div>FAILED</div>
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}


function ContinueCheckout({ tenant, locale, email, profile }) {
    console.log("CONTINUECHECKOUT", email);
    const navigation = useRadicalNavigation();
    const intl = useIntl();
    const query = useQuery();
    const lang = query.get("lang");

    useEffect(() => {
        if (lang) {
            SessionService.setLocale(lang);
        }
    }, [lang])

    const translatePostErrors = (postErrors) => {
        if (postErrors) {
            return Object.entries(postErrors).map(([key, val]) => {
                if (val === true) {
                    return intl.formatMessage({ id: `app.errors.${key}` });
                } else {
                    return intl.formatMessage({ id: `app.fields.${key}` }) + ": " + val.toString();
                }
            })
        } else {
            return [];
        }
    }

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [post, setPost] = useState({ inProgress: false, tainted: false });
    const [plans, setPlans] = useState({ inProgress: false, tainted: false });
    const [localErrors, setLocalErrors] = useState([]);
    const [checkoutOpened, setCheckoutOpenend] = useState(false);

    const postErrors = post.error && translatePostErrors(post.error.errorData);

    const showInitializing = !plans.tainted || plans.inProgress;
    const showInitializingError = plans.error;
    const showForm = plans.data && !post.response && !checkoutOpened;
    const showSuccess = post.response && post.success;
    const showFailed = post.response && !post.success;

    // initializing (loading plans) -> initialized failed 
    //                              -> initialized Ok   -> showForm

    const onReturn = () => {
        navigation.manageYourAccount();
    }

    const clickHandler = (captchaReset, email, password, hasPassword, firstName, lastName, coupon, captcha, errors) => {
        const allLocalErrors = [...errors];
        if (!selectedPlan) {
            allLocalErrors.push("plan_select")
        }
        setLocalErrors(allLocalErrors);

        if (allLocalErrors.length > 0) {
            setPost({ inProgress: false, success: false, error: { message: intl.formatMessage({ id: `app.errors.local` }) } });
            return;
        }

        setPost({ inProgress: true });
        SessionService.startCheckout(tenant.slug, selectedPlan, profile, { email, password, hasPassword, firstName, lastName }, coupon, captcha, () => (setCheckoutOpenend(true))).then((response) => {
            setLocalErrors([]);
            setPost({ inProgress: false, response, success: response.ok });
        }).catch(error => {
            captchaReset();
            setPost({ inProgress: false, success: false, error: error });
        }).finally(() => {
            setCheckoutOpenend(false);
        });
    };

    useEffect(() => {
        setPlans({ inProgress: true, tainted: true });
        SessionService.sellerPlans(tenant.slug).then((data) => {
            setPlans({ inProgress: false, success: true, data, tainted: true });
        }).catch(error => {
            setPlans({ inProgress: false, success: false, error: error, tainted: true });
        });
    }, [tenant]);

    const formErrorsToShow = [...localErrors.map((err) => {
        return intl.formatMessage({ id: `app.errors.${err}` });
    }), ...(postErrors || [])];

    return (
        <div className={styles.loginContainer} >
            <div className={styles.fieldsContainer}>
                {showInitializing && (
                    <div>
                        {intl.formatMessage({ id: `app.checkout.messages.loading_plans` })}
                    </div>
                )}

                {showInitializingError && (
                    <div>
                        <div>{intl.formatMessage({ id: `app.checkout.messages.loading_plans_failed` })}</div>
                        <div>{plans.error?.message || plans.error?.toString()}</div>
                        <Button variant="contained" onClick={onReturn}>{intl.formatMessage({ id: "app.fields.cancel" })}</Button>
                    </div>
                )}


                {checkoutOpened && (
                    <InCheckout />
                )}

                {showSuccess && (
                    <Success tenant={tenant} />
                )}

                {showFailed && (
                    <Failed />
                )}

                {showForm && (
                    <div className={styles.contentWrapper}>
                        <Prices inProgress={post.inProgress} plans={plans.data.plans} currency={plans.data.currency} value={selectedPlan} onChange={(planId) => {
                            setSelectedPlan(planId);
                        }} />
                        <Form inactiveUser={!profile} email={email} errorsToShow={formErrorsToShow} locale={locale} onReturn={onReturn} success={post.success} inProgress={post.inProgress} error={post.error} onClick={clickHandler} />
                    </div>
                )}
            </div>
        </div>


    );
}

function ActiveAccount() {
    const intl = useIntl();
    const navigation = useRadicalNavigation();

    const onClick = useCallback(() => {
        navigation.manageYourAccount();
    }, [navigation]);

    return (
        <div>
            <h3>{intl.formatMessage({ id: "app.messages.cant_checkout_active" })}</h3>

            <Button variant="contained" type="submit" onClick={onClick}>
                {intl.formatMessage({ id: "app.fields.manage_account" })}
            </Button>
        </div>
    )
}

function Checkout({ tenant, locale, goToRoot }) {
    const intl = useIntl();

    const authenticated = UxService.Session.hooks.useAuthenticated();
    const profile = UxService.Session.hooks.useProfile();
    const nonActiveCredentials = UxService.Session.hooks.useNonActiveCredentials(); // useSelector(selectors.session.nonActiveCredentials);
    console.log("profile", profile, nonActiveCredentials, authenticated);
    const inFreeTrial = profile && profile.license.cardless_free_trial;
    const email = profile ? profile.user : nonActiveCredentials.email;

    return (
        <LoginContainer tenant={tenant} goToRoot={goToRoot} justifyContent>
            {nonActiveCredentials && (
                <ContinueCheckout tenant={tenant} email={email} />
            )}

            {authenticated && !inFreeTrial && (
                <ActiveAccount profile={profile} />
            )}

            {authenticated && inFreeTrial && (
                <ContinueCheckout tenant={tenant} email={email} profile={profile} ended={profile.license.cardless_free_trial_ended} />
            )}
        </LoginContainer>
    );
}

export default Checkout;