import { useEffect } from "react";

import PlayNowButton from "./../Buttons/PlayNowButton";
import MoreInfoButton from "./../Buttons/MoreInfoButton";
import Velocity from "velocity-animate";
import styles from './Outstander.module.scss';
import UxService from "./../../services/UxService";
import backdrop from './res/img/fitness.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft, faArrowCircleRight,faChevronRight,faChevronLeft } from '@fortawesome/free-solid-svg-icons'

function Outstander({ pause, slides, selectedIndex, onNext, onPrev, onPlay, onMoreInfo }) {
    const slide = slides[selectedIndex];

    useEffect(() => {
        const timer = setTimeout(() => {
            if (slides.length > 1 && !pause) {
                Velocity(document.getElementById("outstander"), { opacity: 0.3 }, { duration: 250 }).then(() => {
                    onNext();
                    Velocity(document.getElementById("outstander"), { opacity: 1 }, { duration: 250 })
                });
            }
        }, 5000);
        return () => clearTimeout(timer);
    }, [slides, onNext, pause]);

    const animateNext = () => {
        Velocity(document.getElementById("outstander"), { opacity: 0.3 }, { duration: 250 }).then(() => {
            onNext();
            Velocity(document.getElementById("outstander"), { opacity: 1 }, { duration: 250 })
        });
    }

    const animatePrev = () => {
        Velocity(document.getElementById("outstander"), { opacity: 0.3 }, { duration: 250 }).then(() => {
            onNext();
            Velocity(document.getElementById("outstander"), { opacity: 1 }, { duration: 250 })
        });
    }

    if (slides.length <= 0) return <div style={{ marginBottom: "60px" }}/>;

    const textInImage = UxService.localeValue(slide, "outstander_includes_text");
    const name = UxService.localeValue(slide, "name")
    const description = UxService.localeValue(slide, "description");
    const image = UxService.localeImageSet(slide, "images_outstander", backdrop, {
        xs: 960,
        s: 1920,
        m: 2880,
        l: 3840,
        xl: 7680
    });
    const imageMobile = UxService.localeImageSet(slide, "images_mobile_outstander", backdrop, {
        xs: 640,
        s: 960,
        m: 1280,
        l: 1920,
        xl: 2560
     });

    // console.log(image.srcset);

    return (<div id="outstander" className={styles.outstander}>
        <img className={styles.img} src={image.default} srcSet={image.srcset} alt="backdrop2" sizes="100vw"/>
        <img className={styles.imgMobile} src={imageMobile && imageMobile.default} srcSet={imageMobile && imageMobile.srcset} alt="backdrop2" sizes="100vw" />
        <div className={styles.arrowsContainer}>
            {slides.length > 1 && <span className={styles.prev} onClick={animatePrev}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </span>}

            {slides.length > 1 && <span className={styles.next} onClick={animateNext}>
                <FontAwesomeIcon icon={faChevronRight} />
            </span>}

        </div>
        <div className={styles.controlsContainers}>
            {!textInImage && <h3 className={styles.title}>{name}</h3>}
            {!textInImage && <p className={styles.description}>{description}</p>}
            <div className={styles.buttons}>
                <PlayNowButton onClick={() => onPlay(slide)}/>
                <MoreInfoButton onClick={() => onMoreInfo(slide)} />             
            </div>
        </div>
    </div>);
}

export default Outstander;