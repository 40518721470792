import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import styles from './LoadingRadicalSwipers.module.scss';

function LoadingRadicalSwipers({ fetching }) {
    return <div className={styles.root}>
        <FontAwesomeIcon icon={faSpinner} pulse />&nbsp;
        <FormattedMessage id="app.messages.loading_content" />
    </div>
}

export default LoadingRadicalSwipers;