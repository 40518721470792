import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useIntl } from "react-intl";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import Environment from "../../services/Environment";
import styles from './PasswordReset.module.scss';

import SessionService from "../../services/SessionService";
import MaterialUiThemeLogin from "../MaterialUiThemeLogin";
import Button from '@material-ui/core/Button';
import { useParams } from "react-router-dom";

import rhfLogo from '../../assets/logo_apaisado_RH.png';

import backdrop from '../Welcome/res/img/rhf_back.jpg';



function Form({ success, onClick, inProgress, error, onReturn }) {
    const intl = useIntl();
    let passwordError = null;
    let passwordConfirmationError = null;
    let showGlobalError = false;
    if (error) {
        const serialized = error.serialize();

        if (serialized.errorData) {
            passwordError = serialized.errorData && serialized.errorData.password && serialized.errorData.password.join(", ");
            passwordConfirmationError = serialized.errorData && serialized.errorData.password_confirmation && serialized.errorData.password_confirmation.join(", ");
        } else {
            showGlobalError = true;
        }

    }

    const [password, setPassword] = React.useState('');
    const [passwordConfirmation, setPasswordConfirmation] = React.useState('');

    if (success) {
        return <div className={styles.form}>
            <div className={styles.field}>
                <p>{intl.formatMessage({ id: "app.messages.new_password_set" })}</p>
            </div>
            <div className={styles.field}>
                <Button variant="contained" onClick={onReturn}>{intl.formatMessage({ id: "app.fields.back_to_sign_in" })}</Button>
            </div>
        </div>
    }

    return (<div className={styles.form}>
        <div className={styles.field}>
            <p className={styles.textInstructions}>{intl.formatMessage({ id: "app.messages.enter_new_password" })}</p>

            <div className={styles.field}>
                <TextField
                    fullWidth
                    required id="standard-required"
                    label={intl.formatMessage({ id: "app.fields.password" })}
                    disabled={inProgress}
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    helperText={passwordError}
                    error={passwordError}

                />
            </div>
            <div className={styles.field}>
                <TextField
                    fullWidth
                    required id="standard-required"
                    label={intl.formatMessage({ id: "app.fields.password_confirmation" })}
                    disabled={inProgress}
                    type="password"
                    value={passwordConfirmation}
                    onChange={(event) => setPasswordConfirmation(event.target.value)}
                    helperText={passwordConfirmationError}
                    error={passwordConfirmationError}
                />
            </div>
        </div>

        <div className={styles.fieldSignIn}>
            {!inProgress && error && showGlobalError && <div className={styles.message}>{error.message || error}</div>}
            {!inProgress && <Button color="primary" variant="contained" onClick={() => onClick(password, passwordConfirmation)}>{intl.formatMessage({ id: "app.fields.reset_password" })}</Button>}
            {inProgress && <Button color="primary" disabled variant="contained">{intl.formatMessage({ id: "app.messages.reset_password_in_progress" })}</Button>}
        </div>
    </div>);
}

function PasswordReset({ tenant }) {
    const navigation = useRadicalNavigation();
    const { token } = useParams();
    const [post, setPost] = useState({ inProgress: false, tainted: false });

    const onReturn = () => {
        navigation.loginToTenant(tenant);
    }

    const clickHandler = (password, passwordConfirmation) => {
        setPost({ inProgress: true });
        SessionService.resetPassword(tenant.franchiseCode, tenant.slug, tenant.type, password, passwordConfirmation, token).then((data) => {
            setPost({ inProgress: false, success: true });
        }).catch(error => {
            setPost({ inProgress: false, success: false, error: error });
        });
    };

    return (
        <MaterialUiThemeLogin>
            <div className={styles.loginContainer} style={{ background: `url(${backdrop}) no-repeat center center/cover` }}>
                {/*<h2><FormattedMessage id="app.radical_home_fitness_title" /></h2>*/}
                {Environment.brand === "rhf" ? <img src={rhfLogo} className={styles.logo} alt="logo" /> : <></>}

                <div className={styles.formContainer}>
                    <div className={styles.logoContainer}>
                        <img src={tenant.img} alt="tenant" className={styles.logoImgSize} />
                    </div>
                    <div className={styles.fieldsContainer}>
                        <Form onReturn={onReturn} success={post.success} inProgress={post.inProgress} error={post.error} onClick={clickHandler} />
                    </div>
                </div>
            </div>
        </MaterialUiThemeLogin>
    );
}

export default PasswordReset;