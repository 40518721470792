
import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { useState } from "react";
import useSliderStyle from "../../hooks/useSliderStyle";
import { faHeart } from '@fortawesome/free-solid-svg-icons';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import styles from "./RadicalSwiper.module.scss";
import useSlideFromId from '../../hooks/useSlideFromId';
import useImageSelector from '../../hooks/useImageSelector';
import SwiperTitle from '../SwiperTitle';
import SwiperCard from '../SwiperCard';
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import UxService from "./../../services/UxService";

SwiperCore.use([Navigation, Pagination, Scrollbar]);

// https://front-back.com/how-to-make-absolute-positioned-elements-overlap-their-overflow-hidden-parent/
function RadicalSwiperCard({ large, slideId, index, enumerate, isVisible, cardsPerSwiper }) {
    const slide = useSlideFromId(slideId);
    const name = UxService.localeValue(slide, "name");
    const description = UxService.localeValue(slide, "description");
    const unavailableForGuest = slide.guest_unavailable;

    const legacyImg = useImageSelector(slide.images, { large: large });
   // const img = UxService.localeImage(slide, (!large ? "images_slide" : "images_slide_vertical")) || legacyImg;
    const img = UxService.localeImageSet(slide, (!large ? "images_slide" : "images_slide_vertical"), null, {
        xs: 148,
        s: 222, 
        m: 296,
        l: 592,
        xl: 1184,
    });
    const externalOpen = slide.live && slide.stream;

    return (<SwiperCard unavailableForGuest={unavailableForGuest} blurhash={img && img.blurhash} cardsPerSwiper={cardsPerSwiper} img={(img && img.default) || legacyImg} imgSrcSet={img && img.srcset} metadata={slide.metadata} showIsNewBand={slide.is_new} isVisible={isVisible} index={index} large={large} name={name} description={description} slideId={slideId} enumerate={enumerate} externalOpen={externalOpen} showLiveBand={!!externalOpen} />);
}

function getExpandedResults(collection,large,sliderStyle){
    
    var result = collection.slides.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/sliderStyle)

        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)
        return resultArray
    }, [])

    for (var i = 0; i < result.length; i++)
    {
        for (var j =  result[i].length; j < sliderStyle; j++)
        {
            result[i].push("");
        }
    }
    return result.map((item, index) => {
       return   <div className={styles.results}>
                {item.map((slideId, ix) => {
                if (slideId!="") {
                    return <div className={styles.hit} key={slideId}>
                        <RadicalSwiperCard large={large} index={ix} slideId={slideId} enumerate={collection.enumerate} />
                     </div>
                } else {
                    return <div className={styles.hit}></div>
                    
                }})}
                
    </div>})
}

function RadicalSwiper({ large, collection }) {
    //console.log("COLLECTION");
    //console.log(collection);
    const [expanded, setExpanded] = useState(false);
    const sliderStyle = useSliderStyle("main");

    if (collection.slides.length <= 0) return <></>;
    const name = UxService.localeValue(collection, "name");
    const showFavIcon = collection.code === "FV" ? true:false;
    const icon = collection.code;

    const onTitleClick = () => {
        setExpanded((expanded) => (!expanded));
    };

    const showExpansion = collection.slides.length > sliderStyle.slidesPerView;

    const showExpendedResults = getExpandedResults(collection, large, sliderStyle.slidesPerView);
    
    if (!expanded) {
        const style = large ? {paddingBottom: "0em"} : { }
        return <div className="rhf-swiper-container" style={style}>
            <SwiperTitle onClick={onTitleClick} isNew={collection.is_new} title={name} expanded={expanded} showExpansion={showExpansion} showFavIcon={showFavIcon}  />
            <Swiper
                navigation
                watchSlidesVisibility
                watchSlidesProgress
                {...sliderStyle}
                // spaceBetween={4}
                // pagination={{ clickable: true }}
                // slidesPerView={"auto"}
                // freeMode={true}
                // scrollbar={{ draggable: true }}
                //onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
            >
                {collection.slides.map((slideId, ix) => {
                    return <SwiperSlide className={large && "swiper-slide-large"} key={slideId}>
                        {({ isVisible, isActive }) => {
                            return <RadicalSwiperCard cardsPerSwiper={sliderStyle.slidesPerView} isVisible={isVisible} large={large} index={ix} slideId={slideId} enumerate={collection.enumerate} />
                        }}
                    </SwiperSlide>
                })}
            </Swiper>
        </div>;
    } else {
        return <div className={styles.resultsContainer}>          
            <SwiperTitle onClick={onTitleClick} isNew={collection.is_new} title={name} expanded={expanded} showExpansion={true} />
            {showExpendedResults}
        </div>
    }
}

export default RadicalSwiper;