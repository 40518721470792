import React, { useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearch, faUser, faBell } from '@fortawesome/free-solid-svg-icons'
// import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from 'react-redux'

import './Landing.scss';
import { selectors } from "../../store"
import useRadicalNavigation from "../../hooks/useRadicalNavigation";

// import radicalLogo from './res/img/radical-logo.png';

function Landing({ noGym }) {
    const authenticated = useSelector(selectors.session.authenticated);
    const navigation = useRadicalNavigation();

    useEffect(() => {
        if (!authenticated) {
            navigation.login();
        } else {
            navigation.landing();
        }
    }, [authenticated, navigation]);

    if (noGym) {
        return <div className="landing-container">Info for gym</div>;
    } else {
        return <div className="landing-container">LANDING CONTAINER REDIRECTING {noGym}</div>;
    }
}

export default Landing;