
import log from 'loglevel';

import InstrumentationService from "./InstrumentationService";
import CollectionsService from "./CollectionsService";
import Server from "./Server";
import { store, actions } from "../store";

const favQueue = {};
const timers = {};
const userInfo = { franchise: "USA ", guest: true };

const ProfileService = {
    setUserInfo(franchise, guest) {
        userInfo.current = franchise;
        userInfo.guest = guest;
    },
    
    reset() {
        const wipe = (obj) => {
            for (const field in obj) {
                if (obj.hasOwnProperty(field)) {
                    delete obj[field];
                }
            }
        }

        wipe(favQueue);

        if (timers.fav) {
            clearTimeout(timers.fav);
            timers.fav = null;
        }

        if (timers.notifications) {
            clearTimeout(timers.notifications);
            timers.fav = null;
        }
    },

    favSlide(slideId, checked) {
        log.info("[Instrumentation] slide " + slideId + " fav:" + checked);

        this.queueFav(slideId, checked);
        store.dispatch(actions.fav.updateSlide(slideId, checked));
        if (!userInfo.guest) {
            if (checked) {
                store.dispatch(actions.collections.updateFavoritesWith(slideId));
            } else {
                store.dispatch(actions.collections.removeFromFavorites(slideId));
            }
        }
        InstrumentationService.Collection.fav(slideId, checked);
    },

    queueFav(slideId, checked) {
        favQueue[slideId] = { slideId, checked };
    },

    startUpdate() {
        timers.fav = setInterval(() => {
            this.updateFav();
        }, 10000);

        timers.notifications = setInterval(() => {
            this.updateNotifications();
        }, 60000);
    },

    acceptTyc(version) {
        // best effor thing
        return Server.Profile.acceptTyc(version);
    },

    history(date) {
        return Server.Profile.history(date.toISOString().substring(0, 10)).then(({ data }) => {
            return data;
        });
    },

    updateStats(stats) {
        return Server.Profile.updateStats(stats).then(({data}) => {
            store.dispatch(actions.session.updateStats(stats));
            return data;
        });
    },

    dismissNotification(notificationId) {
        store.dispatch(actions.notifications.dismiss(notificationId));

        return Server.Profile.dismissNotification(notificationId).then(({ data }) => {
            // - nothing to do here as we preemptively dismissed the notification from the ui
        }).catch(error => {
            // - nothing to do here, is a best effor thing 
            log.warn("[Instrumentation] Notificaiont dismiss Failed");
            log.warn(error)
        });
    },

    updateNotifications() {
        if (userInfo.guest) {
            return Promise.resolve(true);
        }

        return Server.Profile.notifications().then(({ data }) => {
            store.dispatch(actions.notifications.initialize(data.notifications));
            const slides = data.notifications.map((notification) => {
                if (notification.link && notification.link.slide) {
                    return notification.link.slide_data;
                } else {
                    return null;
                }
            }).filter((slide) => (slide != null));
            CollectionsService.appendSlides(slides);
            return data;
        }).catch(error => {
            // - nothing to do here, is a best effor thing 
            log.warn("[Instrumentation] Notificaiont update Failed");
            log.warn(error)
        });
    },

    updateFav() {
        const toUpdate = { ...favQueue };

        const data = []
        for (var field in toUpdate) {
            if (favQueue.hasOwnProperty(field)) {
                data.push(toUpdate[field]);
            }
        }

        if (data.length > 0) {
            Server.Fav.report(data).then(() => {
                // - reset reported
                for (var field in toUpdate) {
                    if (favQueue.hasOwnProperty(field)) {
                        delete favQueue[field];
                    }
                }
            }).catch(error => {
                // - nothing to do here, is a best effor thing 
                log.warn("[Instrumentation] Fav Report Failed");
                log.warn(error);
            });
        }
    },

    startTraining(slideId) {
        return Server.Training.start(slideId).then(({ data }) => {
            store.dispatch(actions.slides.addTraining(slideId, data.id));
            InstrumentationService.Video.resetSlideVideoProgress(slideId);
            return data.id;
        })
    },

    stopTraining(slideId) {
        return Server.Training.close(slideId).then(({ data }) => {
            store.dispatch(actions.slides.removeTraining(slideId));
            return true;
        })
    },

    restartTraining(slideId) {
        return Server.Training.restart(slideId).then(({ data }) => {
            store.dispatch(actions.slides.addTraining(slideId, data.id));
            InstrumentationService.Video.resetSlideVideoProgress(slideId);
            return data.id;
        })
    },
}

export default ProfileService;