import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { selectors } from "../../store"
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import { useLocation } from "react-router-dom";
import './Authenticated.scss';
import UxService from '../../services/UxService';
import Login from '../Login';
import SessionService from '../../services/SessionService';
import { useIntl } from 'react-intl';

/*
<Route /subscription>
    <Authenticated redirectTo="manageMySubscription" allowNonActive>
        <ManageMySubscription /> 
    </Autnethicated>
</Route>

<Route /create>
    <Authenticated redirectTo="createAccount" allowNonActive>
        <CreateAccount />       Inactivo -> redirije a /subscription con nonActiveCredentials { email, status } set
    </Authenticated>
</Route>

el redirecTo de Login es el que define si acepto nonAactive
*/

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Authenticated({ children, redirectTo, relogin, noTenant, allowNonActive }) {
    const authenticated = UxService.Session.hooks.useAuthenticated(); // useSelector(selectors.session.authenticated);
    const nonActiveCredentials = UxService.Session.hooks.useNonActiveCredentials(); // useSelector(selectors.session.nonActiveCredentials);
    const navigation = useRadicalNavigation();

    const allow = authenticated || (allowNonActive && nonActiveCredentials);

    useEffect(() => {
        if (!allow) {
            navigation.login(redirectTo, relogin, noTenant);
        }
    }, [allow, navigation, redirectTo, relogin, noTenant])

    if (!allow) {
        return (
            <div className="authenticated-container">
            </div>
        );
    } else {
        return children;
    }
}


export function ForceAuthenticated({ justifyContent, title, subtitle, redirectTo, children, identifyOnly, relogin, noTenant, tenant, allowNonActive, allowInactive, goToRoot }) {
    const authenticated = UxService.Session.hooks.useAuthenticated();
    const nonActiveCredentials = UxService.Session.hooks.useNonActiveCredentials();
    const identify = UxService.Session.hooks.useIdentify();
    const query = useQuery();
    const prefillEmail = query.get("account");
    const intl = useIntl();

    SessionService.forceRelogin(relogin, noTenant);

    let allow = false;
    if (identifyOnly) {
        allow = !!identify.current || nonActiveCredentials;
    } else {
        allow = authenticated || (allowNonActive && nonActiveCredentials) || (allowInactive && nonActiveCredentials?.inactive);
    }

    console.log("ForceAuth", justifyContent, allowInactive, authenticated, nonActiveCredentials);
    if (!allow) {
        return (
            <Login
                justifyContent={justifyContent}
                title={title && intl.formatMessage(title)}
                subtitle={subtitle && intl.formatMessage(subtitle)}
                prefillEmail={prefillEmail}
                forceRedirect={redirectTo}
                tenant={tenant}
                goToRoot={goToRoot}
                relogin={relogin}
                allowNonActive={allowNonActive || allowInactive}
            />
        );
    } else {
        return (
            children
        );
    }
}

export default Authenticated;