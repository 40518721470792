import { useState } from "react"
import styles from "./Welcome.module.scss";
import { FormattedMessage } from "react-intl";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProfileService from "../../services/ProfileService";
import backdrop from './res/img/rhf_back.jpg';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import DOMPurify from 'dompurify';
import clsx from "clsx";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import MaterialUiThemeLogin from "../MaterialUiThemeLogin";
import Environment from '../../services/Environment';


SwiperCore.use([Navigation, Pagination, Scrollbar]);

const welcomneTheme = createMuiTheme({
    overrides: {
        // Style sheet name ⚛️
        MuiTypography: {
            body1: {
                fontSize: "18px",
            },
        },
    },
});



const Tyc = ({ tycRaw, accepted, version, onContinue, onNext }) => {
    const [checked, setChecked] = useState(false);

    return <div className={clsx(styles.tyc, styles.textShadow)}>


        <div className={clsx(Environment.brand === "rhf" ? styles.tycTitle : styles.tycTitleRfTv)}><FormattedMessage id="app.fields.tyc" /></div>

        <div className={styles.tycText}
            dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(tycRaw)
            }}></div>
        <div className={styles.accept}>
            {!accepted && <div><FormControlLabel
                control={<Checkbox color="primary" checked={checked} onChange={(event) => (setChecked(event.target.checked))} />}
                label={<FormattedMessage id="app.fields.accept_tyc" />}
            /></div>}
            {!accepted && <Button variant="contained" color="secondary" disabled={!checked} onClick={onContinue}>
                <FormattedMessage id="app.fields.save_tyc" />
            </Button>}
            {accepted && <div className={styles.tycAccepted}>
                <div className={styles.accepted}><FormattedMessage id="app.fields.tycs_accepted" /></div>
                <div>
                    <Button variant="contained" color="primary" onClick={onNext}>
                        <FormattedMessage id="app.messages.next" />
                    </Button>
                </div>
            </div>
            }

        </div>

    </div>;
}

const Slide1 = ({ onNext }) => {
    return <div className={clsx(styles.profile, styles.textShadow)} >
        <div className={Environment.brand === "rhf" ? styles.profileTitle : styles.profileTitleRfTv}>{Environment.brand === "rhf" ? <FormattedMessage id="app.welcome.welcome_to_rhf" /> : <FormattedMessage id="app.welcome.welcome_to_rftv" />}</div>
        <div className={styles.profileExplain}><FormattedMessage id="app.welcome.welcome_to_rhf_details" /></div>
        <Button variant="contained" color="primary" onClick={onNext}>
            <FormattedMessage id="app.messages.next" />
        </Button>
    </div>;
}

const SlideProfile = ({ onProfile, onCancel, tycAccepted }) => {
    if (!tycAccepted) {
        return <div className={clsx(styles.profile, styles.textShadow)} >
            <div className={Environment.brand === "rhf" ? styles.profileTitle : styles.profileTitleRfTv}><FormattedMessage id="app.welcome.accept_tyc_to_continue" /></div>
        </div>
    }

    return <div className={clsx(styles.profile, styles.textShadow)} >
        <div className={Environment.brand === "rhf" ? styles.profileTitle : styles.profileTitleRfTv}><FormattedMessage id="app.welcome.profile_title" /></div>
        <div className={styles.profileExplain}>{Environment.brand === "rhf" ? <FormattedMessage id="app.welcome.profile_title_explain" /> : <FormattedMessage id="app.welcome.profile_title_explain_rftv" />}</div>
        <div className={styles.profileButtons}>
            <div className={styles.profileButton}>
                <Button variant="contained" color="primary" onClick={onProfile}>
                    <FormattedMessage id="app.messages.yes_to_profile" />
                </Button>
            </div>
            <div className={styles.profileButton}>
                <Button variant="contained" color="primary" onClick={onCancel}>
                    <FormattedMessage id="app.messages.no_to_profile" />
                </Button>
            </div>
        </div>
    </div>;
}


const Welcome = ({ tycRaw, onCancel, onProfile, onlyTyc, tycVersion, isGuest }) => {
    const [tycAccepted, setTycAccepted] = useState(false);
    const [swiper, setSwiper] = useState(null);

    const onTycContinue = () => {
        ProfileService.acceptTyc(tycVersion);
        setTycAccepted(true);
        if (onlyTyc) {
            onCancel();
        }
    }
    const onNext = () => {
        if (swiper) {
            swiper.slideNext();
        }
    }

    return <MaterialUiThemeLogin>
        <div className={styles.root} style={{ backgroundImage: `url(${backdrop})` }}>
            {!onlyTyc && <Swiper
                pagination={{ clickable: false }}
                slidesPerView={1}
                onSwiper={setSwiper}
                allowTouchMove={false}
            >
                <SwiperSlide>
                    <Slide1 onNext={onNext} />
                </SwiperSlide>
                <SwiperSlide>
                    <Tyc onNext={!isGuest ? onNext : onCancel} tycRaw={tycRaw} accepted={tycAccepted} version={tycVersion} onContinue={onTycContinue} />
                </SwiperSlide>
                {!isGuest && (
                    <SwiperSlide>
                        <SlideProfile onProfile={onProfile} onCancel={onCancel} tycAccepted={tycAccepted} />
                    </SwiperSlide>
                )}
            </Swiper>}

            {onlyTyc && <Swiper
                slidesPerView={1}
            >
                <SwiperSlide>
                    <Tyc tycRaw={tycRaw} version={tycVersion} onContinue={onTycContinue} />
                </SwiperSlide>
            </Swiper>}
        </div>
    </MaterialUiThemeLogin>
}

export default Welcome;