// martin.burak@cipherastudio.com", "e1bc28"

import React, { useCallback, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from "react-intl";
import date from 'date-and-time';
import 'date-fns';
import styles from './ManageYourAccount.module.scss';
import LoginContainer from '../LoginContainer';
import UxService from '../../services/UxService';
import SelfPanelableButton from '../SelfPanelableButton';
import Button from '@material-ui/core/Button';
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import { startOfWeekYearWithOptions } from 'date-fns/fp';

// TODO: poner offer para free trial no vencido

function CheckoutButton({ }) {
    const intl = useIntl();
    const navigation = useRadicalNavigation();

    const onClick = useCallback(() => {
        navigation.checkout(false);
    }, [navigation]);

    return <div>
        <Button variant="contained" type="submit" onClick={onClick} style={{ width: "100%" }}>
            {intl.formatMessage({ id: "app.fields.checkout_button" })}
        </Button>
    </div>
}

function SelfServeButton() {
    const branding = UxService.Session.hooks.useBranding();
    const license = UxService.Session.hooks.useLicense();

    if (license.license_data?.self_panelable) {
        return (
            <SelfPanelableButton
                license={license}
                slug={branding.slug}
                type={branding.type}
                franchiseCode={branding.franchise_code}
            />
        );
    } else {
        return <div />;
    }
}


function InactiveAcccount({ email }) {
    const intl = useIntl();

    return (
        <div className={styles.containerMessage}>
            <div className={styles.inactiveAcccountText}>{intl.formatMessage({ id: "app.messages.create_account_user_inactive_text_1" })}</div>
            <div className={styles.inactiveAcccountText}>{intl.formatMessage({ id: "app.messages.create_account_user_inactive_text_2" })}</div>
            <CheckoutButton />
        </div>
    )
}

function NonExistsAccount({ email }) {
    const intl = useIntl();
    const navigation = useRadicalNavigation();

    const onClick = useCallback(() => {
        navigation.createYourAccount();
    }, [navigation]);

    return (
        <div className={styles.containerMessage}>
            <div className={styles.textMessage}>{intl.formatMessage({ id: "app.messages.user_does_not_exist" })}</div>
            <Button variant="contained" type="submit" onClick={onClick}>
                {intl.formatMessage({ id: "app.fields.create_account" })}
            </Button>
        </div>
    )
}

function ActiveAccount({ }) {
    return (
        <div className={styles.containerMessage}>
            <UserInfo />
            <LicenseInfo />
            <SelfServeButton />
        </div>
    )
}

function FreeTrialAccount({ }) {
    return (
        <div className={styles.containerMessage}>
            <UserInfo />
            <LicenseInfo />
            <CheckoutButton />
        </div>
    )

}

function UserInfo() {
    const intl = useIntl();
    const branding = UxService.Session.hooks.useBranding();
    const profile = UxService.Session.hooks.useProfile();

    return <div>
        <div className={styles.textSectionTitle}>{intl.formatMessage({ id: "app.titles.user_info" })}</div>
        <div className={styles.itemContainer}>
            <div className={styles.textItemTitle}><FormattedMessage id="app.profile.user" /></div>
            <div className={styles.textItemValue}>{profile.user}</div>
        </div>
        <div className={styles.itemContainer}>
            <div className={styles.textItemTitle}><FormattedMessage id="app.profile.provided_by" /></div>
            <div className={styles.textItemValue}>{branding.name}</div>
        </div>
    </div>
}

function LicenseInfo() {
    const intl = useIntl();
    const license = UxService.Session.hooks.useLicense();

    const formatDate = (dateYMD) => {
        return intl.formatDate(date.parse(dateYMD, 'YYYY-MM-DD'), {
            dateStyle: "long"
        })
    }

    return <div>
        <div className={styles.textSectionTitle}>{intl.formatMessage({ id: "app.titles.license_info" })}</div>
        <div className={styles.itemContainer}>
            <div className={styles.textItemTitle}><FormattedMessage id="app.profile.account_type" /></div>
            <div className={styles.textItemValue}><FormattedMessage id={`app.profile.license_type.${license.license_type}`} /></div>
        </div>

        {license.cardless_free_trial && (
            <>
                {!license.cardless_free_trial_ended && (
                    <div className={styles.itemContainer}>
                        {/* <div className={styles.textItemTitle}><FormattedMessage id="app.profile.license_in_trial" /></div> */}
                        <div className={styles.textItemValue}><FormattedMessage id="app.profile.free_trial_will_end_on" values={{ end: formatDate(license.with_dates.to) }} /></div>
                    </div>
                )}
                {license.cardless_free_trial_ended && (
                    <div className={styles.itemContainer}>
                        <div className={styles.textItemValue}><FormattedMessage id="app.profile.license_in_trial_ended" /></div>
                    </div>
                )}
            </>
        )}

        {license.with_dates && !license.cardless_free_trial &&
            <div className={styles.itemContainer}>
                <div className={styles.textItemTitle}><FormattedMessage id="app.profile.license_period" /></div>
                <div className={styles.textItemValue}>{formatDate(license.with_dates.from)} - {formatDate(license.with_dates.to)}</div>
                {license.with_dates?.renewal && (
                    <div className={styles.textAccountStatus}>
                        <FormattedMessage id="app.profile.license_renewal" values={{ date: formatDate(license.with_dates.renewal) }} />
                    </div>
                )}
                {!license.with_dates?.renewal && (
                    <p>
                        <FormattedMessage id="app.profile.license_will_end" values={{ date: formatDate(license.with_dates.to) }} />
                    </p>
                )}
            </div>
        }
    </div>
}

function ManageYourAccount({ tenant, goToRoot }) {
    const intl = useIntl();

    const authenticated = UxService.Session.hooks.useAuthenticated();
    const profile = UxService.Session.hooks.useProfile();
    const nonActiveCredentials = UxService.Session.hooks.useNonActiveCredentials(); // useSelector(selectors.session.nonActiveCredentials);
    console.log("profile", profile, nonActiveCredentials, authenticated);
    const inFreeTrial = profile && profile.license.cardless_free_trial;
    const email = profile ? profile.user : nonActiveCredentials.email;

    return (
        <LoginContainer tenant={tenant} goToRoot={goToRoot}>
            <div className={styles.textTitle}>
                {intl.formatMessage({ id: "app.titles.manage_your_account" })}
            </div>
            <div className={styles.textUser}>
                {email}
            </div>

            {nonActiveCredentials && nonActiveCredentials.inactive && (
                <InactiveAcccount credentials={nonActiveCredentials} />
            )}

            {nonActiveCredentials && !nonActiveCredentials.inactive && (
                <NonExistsAccount credentials={nonActiveCredentials} />
            )}

            {authenticated && !inFreeTrial && (
                <ActiveAccount profile={profile} />
            )}

            {authenticated && inFreeTrial && (
                <FreeTrialAccount profile={profile} ended={profile.license.cardless_free_trial_ended} />
            )}
        </LoginContainer>
    );
}

export default ManageYourAccount;