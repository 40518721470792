import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import './MoreInfoButton.scss';

function MoreInfoButton({onClick}) {
    return (
        <div onClick={onClick} className="button button-more"><FontAwesomeIcon icon={faInfoCircle} /><FormattedMessage id="app.buttons.more_info" /></div>
    );
}

export default MoreInfoButton;