import InstrumentationService from './InstrumentationService';
import Server from './Server';
import Environment from "../services/Environment";
import SessionService from "../services/SessionService";

import { store, actions } from "../store";

const dispatch = store.dispatch;

const videos = {};
const slideVideos = {};
const state = { paused: false };
const locale = { current: "en" };
const userInfo = { franchise: "USA ", guest: true };

const ColectionsService = {
    setLocale(newLocale) {
        locale.current = newLocale;
    },

    setUserInfo(franchise, guest) {
        userInfo.current = franchise;
        userInfo.guest = guest;
    },

    appendPage(page) {
        if (state.paused) {
            return;
        }

        
        InstrumentationService.Collection.page(page);
        dispatch(actions.collections.appendPage(page)).then((data) => {
            if (data && data.payload && data.payload.collections) {
                data.payload.collections.forEach((c) => {
                    this.updateVideosFromCollection(c);
                });
            }
            return data;
        });
    },

    appendSlides(slides) {
        this.updateVideosFromSlides(slides);
        dispatch(actions.collections.appendSlides(slides));   
    },

    setOutstanders(outstanders) {
        this.updateVideosFromSlides(outstanders);
        dispatch(actions.collections.updateOutstandersWith(outstanders));
    },

    start() {
        state.paused = false;
    },

    reset() {
        for (const field in videos) {
            if (videos.hasOwnProperty(field)) {
                delete videos[field];
            }
        }

        for (const field in slideVideos) {
            if (slideVideos.hasOwnProperty(field)) {
                delete slideVideos[field];
            }
        }

        state.paused = true
    },

    signedStreamUrl(signedVideoId) {
        return  Server.Collections.signedStreamUrl(signedVideoId).then((data) => {
            return data;
        })
    },

    suggestions(slideId, videoId) {
        return Server.Collections.suggestions(slideId, videoId).then((slides) => {
            return slides;
        })
    },

    slide(slideId) {
        // TODO: justificar porque collections va por redus y esto no
        InstrumentationService.Collection.slide(slideId);
        return Server.Collections.slide(slideId).then((slide) => {
            this.updateVideosFromSlide(slide);
            // slide.guest_unavailable = this.unavailableForGuest(slide);
            return slide;
        })
    },

    findRecursiveVisibilityEntry(slide, locale, franchise) {
        // console.log(slide.id);
        // console.log(slide.recursive_visibility);
        const brand = Environment.brand;
        if (slide.recursive_visibility) {
            return slide.recursive_visibility.find((entry) => {
                let match = true;
                match = entry.app.includes(brand);
                match = match && entry.langs.includes(locale);
                match = match && (entry.franchises.includes("all") || entry.franchises.includes(franchise));
                    
                return match
            });
        }

        return null;
    },


    shareUrlForSlide(tenant, slideId) {
        if (tenant.type === "seller") {
            return `${Environment.hostUrl}/share/${tenant.slug}/${slideId}`
        } else {
            return `${Environment.hostUrl}/share/${tenant.franchiseCode}/${tenant.slug}/${slideId}`
        }
    },

    shareDataForSlide(slideId, title) {
        const tenant = SessionService.tenantFromSessionData();
        const url = this.shareUrlForSlide(tenant, slideId);

        return {
            url,
            title
        }
    },
    
    sharedSlideInfo(slideId) {
        // TODO: justificar porque collections va por redus y esto no
        InstrumentationService.Collection.sharedSlideInfo(slideId);
        return Server.Collections.sharedSlideInfo(slideId).then((slide) => {
            return slide;
        })
    },

    sharedSlideContents(slideId) {
        // TODO: justificar porque collections va por redus y esto no
        InstrumentationService.Collection.sharedSlideContents(slideId);
        return Server.Collections.sharedSlideContents(slideId).then((slide) => {
            return slide;
        })
    },

    videoIdsForSlide(slideId) {
        return slideVideos[slideId];
    },

    updateVideosFromSlide(slide) {
        let lastId = null;
        let lastSeason = null;

        slideVideos[slide.id] = [];

        slide.seasons.forEach((season) => {
            season.chapters.forEach((video) => {
                videos[video.id] = {
                    ...video,
                };
                slideVideos[slide.id].push(video.id);
                if (lastId) {
                    videos[lastId].next = {
                        ...videos[lastId].next,
                        [slide.id]: {
                            videoId: video.id,
                            season: lastSeason
                        }
                    }
                }
                lastId = video.id;
                lastSeason = season;
            });
        });
    },

    updateVideosFromCollection(collection) {
        this.updateVideosFromSlides(collection.slides);
    },

    updateVideosFromSlides(slides) {
        slides.forEach((slide) => {
            if (slide.type === "video") {
                slideVideos[slide.id] = [slide.id];

                videos[slide.id] = {
                    ...slide,
                    next: {},
                }
            }
        });
    },

    next(slideId, itemId) {
        const v = this.video(itemId);
        if (v && v.next) {
            return v.next[slideId];
        } else {
            return null;
        }
    },

    video(itemId) {
        return videos[itemId];
    },

    currentVideoForTrainingPlan(seasons, trainingPlanSeen) {
        let trainingPlanCurrent = null;
        seasons.forEach((season) => {
            season.chapters.forEach((chapter) => {
                if (!trainingPlanCurrent && trainingPlanSeen.indexOf(chapter.id) < 0) {
                    trainingPlanCurrent = chapter;
                }
            });
        });
        return trainingPlanCurrent;
    },

    startVideoForslide(slideData, progress, isTrainingPlan, trainingPlanSeen) {
        const cut = 95;
        let rv = null;
        let next = null;
        let fromTrainingPlanId = null;

        const defaultValue = {
            season: 0,
            seasonData: slideData.seasons[0],
            videoId: slideData.seasons[0].chapters[0].id,
            video: slideData.seasons[0].chapters[0],
            progress: progress[slideData.seasons[0].chapters[0].id]
        };

        if (isTrainingPlan) {
            const trainingPlanCurrent = this.currentVideoForTrainingPlan(slideData.seasons, trainingPlanSeen);
            if (trainingPlanCurrent) {
                fromTrainingPlanId = trainingPlanCurrent.id;
            }
        }

        // - find first video with progress < 95% ||
        // the one selected from the training plan
        slideData.seasons.forEach((season, index) => {
            season.chapters.forEach((chapter, chapterIx) => {
                if (rv && !next) {
                    next = {
                        season: index,
                        seasonData: season,
                        videoId: chapter.id,
                        video: chapter,
                        progress: progress[chapter.id]
                    };
                }
                if (!rv && (progress[chapter.id].progress < cut || chapter.id === fromTrainingPlanId)) {
                    rv = {
                        season: index,
                        seasonData: season,
                        videoId: chapter.id,
                        video: chapter,
                        progress: progress[chapter.id]
                    };
                }
            });
        });

        if (rv) {
            rv.next = next;
        }

        return rv || defaultValue;
    }
}

export default ColectionsService;