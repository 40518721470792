import { createSlice, createSelector } from '@reduxjs/toolkit'
import { asyncActionsDefaultValue, asyncActions } from "./utils";

const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        profile: null,
        tainted: false,
        inProgress: false,
        success: false,
        error: null,
        reseted: false,
        nonActiveCredentials: null,
        ...asyncActionsDefaultValue("identify"),
        ...asyncActionsDefaultValue("signIn"),
        ...asyncActionsDefaultValue("magicLinkRequest"),
        ...asyncActionsDefaultValue("createAccount"),
        ...asyncActionsDefaultValue("recoverPassword"),
    },
    reducers: {
        ...asyncActions("identify"),
        ...asyncActions("signIn"),
        ...asyncActions("createAccount"),
        ...asyncActions("recoverPassword"),
        ...asyncActions("magicLinkRequest"),
        reset: (state) => {
            state.nonActiveCredentials = null;
            state.reseted = true;
            state.profile = null;
            state.tainted = true;
            state.inProgress = false;
            state.success = false;
            state.error = null;
        },
        recoverSessionFailed: (state) => {
            state.tainted = true;
            state.inProgress = false;
            state.error = null;
        },
        startSignIn: (state) => {
            state.reseted = false;
            state.profile = null;
            state.inProgress = true;
            state.tainted = true;
        },
        resetReseted: (state) => {
            state.reseted = false;
        },
        failed:{
            reducer: (state, action) => {
                state.inProgress = false;
                state.success = false;
                state.error = action.error;
            },
            prepare: (error) => {
                return { error }
            },
        },
        updateStats: {
            reducer: (state, action) => {
                state.profile.stats = action.payload.stats;
            },
            prepare: (stats) => {
                return { payload: { stats } }
            },
        },
        updateNonActiveCredentials: {
            reducer: (state, action) => {
                state.nonActiveCredentials = action.payload.nonActiveCredentials;
            },
            prepare: (nonActiveCredentials) => {
                return { payload: { nonActiveCredentials } }
            },
        },
        success: {
            reducer: (state, action) => {
                state.inProgress = false;
                state.success = true;
                state.error = null;
                state.profile = action.payload.profile;
            },
            prepare: (profile) => {
                return { payload: { profile }}
            },
        }
    }
});


const selectors = (getRoot) => ({
    authenticated: createSelector(getRoot, data => data.profile != null),
    reseted: createSelector(getRoot, data => data.reseted),
    profile: createSelector(getRoot, data => data.profile),
    isGuest: createSelector(getRoot, data => data.profile?.guest),
    license: createSelector(getRoot, data => data.profile?.license),
    branding: createSelector(getRoot, data => data.profile?.branding),
    inProgress: createSelector(getRoot, data => data.inProgress),
    error: createSelector(getRoot, data => data.error),
    tainted: createSelector(getRoot, data => data.tainted),
    profileStats: createSelector(getRoot, data => data.profile.stats),
    nonActiveCredentials: createSelector(getRoot, data => data.nonActiveCredentials),
    freeTrialEnded: createSelector(getRoot, data => data.profile?.license && data.profile.license.cardless_free_trial && data.profile.license.cardless_free_trial_ended),
    signIn: createSelector(getRoot, (data) => data.signIn),
    identify: createSelector(getRoot, (data) => data.identify),
    createAccount: createSelector(getRoot, (data) => data.createAccount),
    magicLinkRequest: createSelector(getRoot, (data) => data.magicLinkRequest),
});

sessionSlice.selectors = selectors;


export default sessionSlice;
