import { createSlice, createSelector } from '@reduxjs/toolkit'

const initialState = {
    progress: { },
    playSessions: { }
};

const progressSlice = createSlice({
    name: 'progress',
    initialState,
    reducers: {
        resetForVideos: {
            reducer: (state, action) => {
                try {
                    action.payload.videoIds.forEach((videoId) => {
                        
                        delete state.progress[videoId];
                    });
                } catch (e) {
                    console.log(e);
                    debugger;
                }
                
            },
            prepare: (videoIds) => {
                return { payload: { videoIds } }
            },
        },
        update: {
            reducer: (state, action) => {
                state.progress[action.payload.videoId] = { progress: action.payload.progress, seconds: action.payload.seconds };
                state.playSessions[action.payload.playSession.playSessionId] = action.payload.playSession;
            },
            prepare: (videoId, progress, seconds, playSession) => {
                return {
                    payload: {
                        videoId,
                        progress,
                        seconds,
                        playSession: {
                            ...playSession,
                            played: playSession.played.toString(16)
                        }
                    }
                }
            },
        },
        initialize: {
            reducer: (state, action) => {
                state.progress = {...action.payload.progress};
            },
            prepare: (progress) => {
                return { payload: { progress } }
            },
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    }
})

const selectors = (getRoot) => ({
});

const propertySelectors = (getRoot) => ({
    forVideo: () => {
        return createSelector(getRoot, (_, videoId) => (videoId), (data, videoId) => data.progress[videoId] || {progress: 0});
    },
    forVideos: () => {
        return createSelector(getRoot, (_, videoIds) => (videoIds), (data, videoIds) => videoIds.reduce(function (o, k) { o[k] = data.progress[k] || { progress: 0 }; return o; }, {}));
    }

});

progressSlice.selectors = selectors;
progressSlice.propertySelectors = propertySelectors;

export default progressSlice;
