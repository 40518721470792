import { useState, useRef, useEffect } from 'react';
import { useIntl, FormattedMessage } from "react-intl";
import RadicalModal from "./../RadicalModal";
import styles from "./UserProfile.module.scss";
import date from 'date-and-time';
import clsx from "clsx";
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { formatError } from "../utils";
import MaterialUiTheme from "../MaterialUiTheme"
import { default as Select2 } from 'react-select';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import Environment from '../../services/Environment';

function GroupBox({ className, children, error, label, dontShowErrorText, inline }) {
    const showError = !!error;

    return (
        <div className={clsx(styles.groupBoxRoot, className)}>
            <div className={clsx(styles.control, inline && styles.controlInline, showError && styles.controlError)}>
                <label className={clsx(styles.label, showError && styles.labelError)}>{label}</label>
                {children}
            </div>
            {showError && !dontShowErrorText && <div className={styles.error}>
                {error}
            </div>}
        </div>
    );
}

const NumberField = (props) => {
    const intl = useIntl();
    const decimalSeparator = (intl.formatMessage({ id: "app.formatMessage.decimal_separator" }));

    return <NumberFormat {...props} decimalSeparator={decimalSeparator} allowNegative={false} fixedDecimalScale={true} decimalScale={2} />
}
const Field = ({ error, disabled, value, onChange, label, decorator, id }) => {
    const intl = useIntl();
    const decimalSeparator = (intl.formatMessage({ id: "app.formatMessage.decimal_separator" }));

    const fixDecimalSeparator = (value, out) => {
        if (value.replace) {
            if (out) {
                return value.replace(decimalSeparator, ".");
            } else {
                return value.replace(".", decimalSeparator);
            }
        } else {
            return value;
        }
    }

    const fixedValue = fixDecimalSeparator(value || "", false);
    return <div className={styles.control}>
        <FormControl error={!!error} fullWidth>
            <InputLabel htmlFor={id} shrink={!!fixedValue}>
                <FormattedMessage id={label} />
            </InputLabel>
            <Input
                id={id}
                value={fixedValue}
                error={!!error}
                disabled={disabled}
                onChange={(event) => (onChange(fixDecimalSeparator(event.target.value, true)))}
                endAdornment={
                    <InputAdornment position="end">
                        <FormattedMessage id={decorator} />
                    </InputAdornment>
                }
                inputComponent={NumberField}
            />
            <FormHelperText>{formatError(error)}</FormHelperText>
        </FormControl>
    </div>;
}

const Weight = ({ error, disabled, value, onChange, kgs }) => {
    return <Field error={error} disabled={disabled} id="weight" value={value} onChange={onChange} label="app.fields.weight" decorator={kgs ? "app.fields.kgs" : "app.fields.lbs"} />;
}

const Birthday = ({ error, disabled, value, onChange, dateFormat }) => {
    const intl = useIntl();
    const pickerdDateFormat = dateFormat || (intl.formatMessage({ id: "app.formatMessage.date_picker" }));
    let valueAsDate = value && date.parse(value, 'YYYY-MM-DD');
    if (isNaN(valueAsDate)) {
        valueAsDate = null;
    }

    return <div className={styles.birthdayContainer}><MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker

            error={!!error}
            helperText={formatError(error)}
            format={pickerdDateFormat}
            margin="normal"
            id="date-picker-inline"
            animateYearScrolling
            label={<FormattedMessage id="app.fields.birthday" />}
            value={valueAsDate}
            onChange={(value) => {
                if (isNaN(value)) {
                    return onChange(null);
                } else {
                    return onChange(value && date.format(value, 'YYYY-MM-DD'));
                }
            }}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
        />
    </MuiPickersUtilsProvider></div>
}

const SingleSelect = ({ label, error, disabled, value, onChange, options }) => {
    const id = label;
    const labelId = `${label}-id`;

    return <div className={styles.control}>
        <FormControl disabled={disabled} error={!!error} fullWidth>
            <InputLabel id={labelId}>
                {label}
            </InputLabel>
            <Select
                labelId={labelId}
                id={id}
                value={value || ""}
                onChange={(event) => (onChange(event.target.value))}
            >
                {options.map((o) => (
                    <MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>
                ))}
            </Select>
            <FormHelperText>{formatError(error)}</FormHelperText>
        </FormControl>
    </div>;

}

const MultiSelect = ({ label, value, options, error, onChange, disabled, placeholder }) => {
    const showError = error;
    const curatedValue = value || [];

    const customStyles = {
        control: (provided, { isFocused, isSelected, }) => {
            const rv = { ...provided };
            rv.background = "#222222";

            if (showError) {
                rv.borderColor = "#f44336"
            }
            rv.borderBottomWidth = "2px";
            if (isFocused) {
                rv.borderBottomColor = Environment.brand === "rhf" ? "#97bd45" : "#5fb1df";
                rv["&:hover"] = Environment.brand === "rhf" ? "#97bd45" : "#5fb1df";
            }
            if (isSelected) {
                rv.borderBottomColor = Environment.brand === "rhf" ? "#97bd45" : "#5fb1df";
                rv["&:hover"] = Environment.brand === "rhf" ? "#97bd45" : "#5fb1df";
            }
            rv.borderTop = "0px";
            rv.borderLeft = "0px";
            rv.borderRight = "0px";
            rv.borderRadius = "0px";
            rv.boxShadow = 'none';
            return rv;
        },

        valueContainer: (provided) => {
            const rv = { ...provided };
            rv.paddingLeft = 0;
            rv.overflow = "inherit";
            return rv;
        },

        placeholder: (provided, { isFocused }) => {
            const rv = { ...provided };
            if (showError) {
                rv.color = "#f44336"
            }
            rv.fontSize = "16px";
            return rv;
        },

        multiValueRemove: (provided) => {
            const rv = { ...provided };
            rv.color = "black"
            rv.cursor = "pointer"
            return rv;
        },
        multiValueLabel: (provided) => {
            const rv = { ...provided };
            rv.fontSize = "16px";
            return rv;
        },

        multiValue: (provided) => {
            const rv = { ...provided };
            rv.fontSize = "16px";
            return rv;
        },
        input: (provided) => {
            const rv = { ...provided };
            rv.fontSize = "16px";
            return rv;
        },
        container: (provided) => {
            const rv = { ...provided };
            rv.paddingTop = "12px";
            return rv;
        },

        option: (provided, { data, isDisabled, isFocused, isSelected }) => {
            const rv = { ...provided };
            rv.background = isFocused ? "#444444" : "#333333";
            return rv;
        },

        menu: (provided) => {
            const rv = { ...provided };
            rv.background = "#333333";
            rv.zIndex = "100000";
            rv.fontSize = "16px";
            return rv;
        },

        menuList: (provided) => {
            const rv = { ...provided };
            rv.background = "#333333";
            rv.zIndex = "100000";
            return rv;
        }
    }

    const handleChange = (values) => {
        onChange(values.map((e) => (e.value)));
    }

    const optionValues = curatedValue.map((v) => (options.find((o) => (o.value == v))));
    return <div className={styles.multiSelect}>
        {curatedValue.length > 0 && <span className={clsx(styles.multiSelectLabel, showError && styles.multiSelectLabelError)}>
            {label}
        </span>}
        <Select2
            disabled
            styles={customStyles}
            isMulti
            value={optionValues}
            onChange={handleChange}
            options={options}
            placeholder={placeholder || label}
            className="basic-multi-select"
            classNamePrefix="select"
            isSearchable={false}
        />
        {showError && <div className={styles.multiSelectError}>
            {error}
        </div>}
    </div>
}

const Gender = ({ error, disabled, value, onChange }) => {
    const options = [
        { value: "M", label: <FormattedMessage id="app.fields.gender_male" /> },
        { value: "F", label: <FormattedMessage id="app.fields.gender_female" /> },
        { value: "O", label: <FormattedMessage id="app.fields.gender_other" /> }
    ]

    return <SingleSelect
        label={<FormattedMessage id="app.fields.gender" />}
        error={error}
        disabled={disabled}
        value={value}
        onChange={onChange}
        options={options}
    />
}

const TrainningPreferences = ({ error, disabled, value, onChange }) => {
    const options = [
        { value: "alone", label: <FormattedMessage id="app.fields.training_preferences_alone" /> },
        { value: "friends", label: <FormattedMessage id="app.fields.training_preferences_friends" /> },
        { value: "personal_trainer", label: <FormattedMessage id="app.fields.training_preferences_personal_trainer" /> }
    ]

    return <MultiSelect
        label={<FormattedMessage id="app.fields.training_preferences" />}
        placeholder={<FormattedMessage id="app.fields.training_preferences_placeholder" />}
        error={error}
        disabled={disabled}
        value={value}
        onChange={onChange}
        options={options}
    />
}

const DaysInWeek = ({ error, disabled, value, onChange }) => {
    const marks = [
        {
            value: 1,
            label: "1",
        },
        {
            value: 2,
            label: "2"
        },
        {
            value: 3,
            label: "3"
        },
        {
            value: 4,
            label: "4"
        },
        {
            value: 5,
            label: "5"
        },
        {
            value: 6,
            label: "6"
        },
        {
            value: 7,
            label: "7"
        }
    ];

    return <div className={styles.sliderContainer}>
        <Typography gutterBottom>
            <FormattedMessage id="app.fields.days_in_week" />
        </Typography>
        <div className={styles.slider}>
            <Slider
                disabled={!!disabled}
                value={value}
                onChange={(event, newValue) => ((newValue !== value) && onChange(newValue))}
                marks={marks}
                valueLabelDisplay="on"
                min={1}
                max={7}
            />
        </div>
        {!!error && <div className={styles.multiSelectError}>
            {error}
        </div>}
    </div>
}

const FitnessLevel = ({ error, disabled, value, onChange }) => {
    const options = [
        { value: "beginner", label: <FormattedMessage id="app.fields.fitness_level_beginner" /> },
        { value: "intermediate", label: <FormattedMessage id="app.fields.fitness_level_intermediate" /> },
        { value: "advance", label: <FormattedMessage id="app.fields.fitness_level_advance" /> }
    ]

    return <SingleSelect
        label={<FormattedMessage id="app.fields.fitness_level" />}
        error={error}
        disabled={disabled}
        value={value}
        onChange={onChange}
        options={options}
    />
}

const Workout = ({ error, disabled, value, onChange }) => {
    const options = [
        { value: "workout_high_intesity", label: <FormattedMessage id="app.fields.workout_high_intesity" /> },
        { value: "workout_low_intesity", label: <FormattedMessage id="app.fields.workout_low_intesity" /> },
        { value: "workout_toning", label: <FormattedMessage id="app.fields.workout_toning" /> },
        { value: "workout_fat_burner", label: <FormattedMessage id="app.fields.workout_fat_burner" /> },
        { value: "workout_core", label: <FormattedMessage id="app.fields.workout_core" /> },
        { value: "workout_glutes", label: <FormattedMessage id="app.fields.workout_glutes" /> },
        { value: "workout_legs", label: <FormattedMessage id="app.fields.workout_legs" /> },
        { value: "workout_hitt", label: <FormattedMessage id="app.fields.workout_hitt" /> },
        { value: "workout_mind", label: <FormattedMessage id="app.fields.workout_mind" /> },
        { value: "workout_dance", label: <FormattedMessage id="app.fields.workout_dance" /> },
        { value: "workout_latin_dance", label: <FormattedMessage id="app.fields.workout_latin_dance" /> },
        { value: "workout_indoor_cylcing", label: <FormattedMessage id="app.fields.workout_indoor_cylcing" /> },
        { value: "workout_suspension", label: <FormattedMessage id="app.fields.workout_suspension" /> },
        { value: "workout_yoga", label: <FormattedMessage id="app.fields.workout_yoga" /> },
        { value: "workout_chair_yoga", label: <FormattedMessage id="app.fields.workout_chair_yoga" /> },
        { value: "workout_pilates", label: <FormattedMessage id="app.fields.workout_pilates" /> },
        { value: "workout_kickboxing", label: <FormattedMessage id="app.fields.workout_kickboxing" /> },
        { value: "workout_boxing", label: <FormattedMessage id="app.fields.workout_boxing" /> },
        { value: "workout_barbell", label: <FormattedMessage id="app.fields.workout_barbell" /> },
        { value: "workout_step", label: <FormattedMessage id="app.fields.workout_step" /> },
        { value: "workout_aerobics", label: <FormattedMessage id="app.fields.workout_aerobics" /> },
        { value: "workout_mini_trampoline", label: <FormattedMessage id="app.fields.workout_mini_trampoline" /> },
        { value: "workout_interval", label: <FormattedMessage id="app.fields.workout_interval" /> },
        { value: "workout_boxing_bag", label: <FormattedMessage id="app.fields.workout_boxing_bag" /> },
        { value: "workout_senior", label: <FormattedMessage id="app.fields.workout_senior" /> },
        { value: "workout_barbell", label: <FormattedMessage id="app.fields.workout_barbell" /> },
        { value: "workout_overweight", label: <FormattedMessage id="app.fields.workout_overweight" /> },
        { value: "workout_over_40", label: <FormattedMessage id="app.fields.workout_over_40" /> },
        { value: "workout_mix", label: <FormattedMessage id="app.fields.workout_mix" /> },
        { value: "workout_no_equipment", label: <FormattedMessage id="app.fields.workout_no_equipment" /> }
    ]

    return <MultiSelect
        label={<FormattedMessage id="app.fields.workout" />}
        placeholder={<FormattedMessage id="app.fields.workout_placeholder" />}
        error={error}
        disabled={disabled}
        value={value}
        onChange={onChange}
        options={options}
    />
}

const FitnessGoals = ({ error, disabled, value, onChange }) => {
    const options = [
        { value: "start", label: <FormattedMessage id="app.fields.fitness_goals_start" /> },
        { value: "get_back", label: <FormattedMessage id="app.fields.fitness_goals_get_back" /> },
        { value: "stay_active", label: <FormattedMessage id="app.fields.fitness_goals_stay_active" /> },
        { value: "fat_burner", label: <FormattedMessage id="app.fields.fitness_goals_fat_burner" /> },
        { value: "toning", label: <FormattedMessage id="app.fields.fitness_goals_toning" /> },
        { value: "flexibility", label: <FormattedMessage id="app.fields.fitness_goals_flexibility" /> },
        { value: "strength", label: <FormattedMessage id="app.fields.fitness_goals_strength" /> },
        { value: "mobility", label: <FormattedMessage id="app.fields.fitness_goals_mobility" /> },
        { value: "cardio_endurance", label: <FormattedMessage id="app.fields.fitness_goals_cardio_endurance" /> },
        { value: "core_strenght", label: <FormattedMessage id="app.fields.fitness_goals_core_strength" /> },
        { value: "dance", label: <FormattedMessage id="app.fields.fitness_goals_dance" /> },
        { value: "self_defense", label: <FormattedMessage id="app.fields.fitness_goals_self_defense" /> },
        { value: "goals", label: <FormattedMessage id="app.fields.fitness_goals" /> },
    ]

    return <MultiSelect
        label={<FormattedMessage id="app.fields.fitness_goals" />}
        placeholder={<FormattedMessage id="app.fields.fitness_goals_placeholder" />}
        error={error}
        disabled={disabled}
        value={value}
        onChange={onChange}
        options={options}
    />
}

const Height = ({ error, disabled, value, onChange, mts }) => {
    return <Field error={error} disabled={disabled} id="height" value={value} onChange={onChange} label="app.fields.height" decorator={mts ? "app.fields.mts" : "app.fields.feet"} />;
}

const OptIn = ({ errors, dateFormat, tainted, stats, onOptOut, onSave, inProgress, error, kgs, mts, onChange }) => {
    return <div className={styles.form}>
        <div className={styles.formSectionTitle}>
            <FormattedMessage id="app.titles.profile_personal_info" />
        </div>
        <GroupBox>
            <div className={styles.doubleItemProfile}>
                <Weight error={errors.weight} disabled={inProgress} value={stats.weight} kgs={kgs} color="secondary" onChange={(value) => {
                    onChange("weight", value);
                }} />
                <Height error={errors.height} disabled={inProgress} value={stats.height} mts={mts} onChange={(value) => {
                    onChange("height", value);
                }} />
            </div>
            <div className={styles.doubleItemProfile}>
                <Gender error={errors.gender} disabled={inProgress} value={stats.gender} onChange={(value) => {
                    onChange("gender", value);
                }} />
                <Birthday dateFormat={dateFormat} error={errors.birthday} disabled={inProgress} value={stats.birthday} onChange={(value) => {
                    onChange("birthday", value);
                }} />
            </div>
        </GroupBox>
        <div className={styles.formSectionTitle}>
            <FormattedMessage id="app.titles.profile_training_info" />
        </div>
        <GroupBox>
            <DaysInWeek error={errors.days_in_week} disabled={inProgress} value={stats.days_in_week} onChange={(value) => {
                onChange("days_in_week", value);
            }} />
            <FitnessLevel error={errors.fitness_level} disabled={inProgress} value={stats.fitness_level} onChange={(value) => {
                onChange("fitness_level", value);
            }} />
            <FitnessGoals error={errors.fitness_goals} disabled={inProgress} value={stats.fitness_goals} onChange={(value) => {
                onChange("fitness_goals", value);
            }} />
            <TrainningPreferences error={errors.training_preferences} disabled={inProgress} value={stats.training_preferences} onChange={(value) => {
                onChange("training_preferences", value);
            }} />
            <Workout error={errors.workout} disabled={inProgress} value={stats.workout} onChange={(value) => {
                onChange("workout", value);
            }} />
        </GroupBox>
    </div >
}

const UserProfile = ({ deateFormat, stats, onCancel, onSave, inProgress, errors, kgs, mts, dateFormat }) => {
    // console.log("--UP -------------------");
    // console.log("MTS: " + mts);
    // console.log("KGS: " + kgs);
    // console.log("DF: " + dateFormat);

    const intl = useIntl();
    const [originalStateStats, setStateStats] = useState(stats || { empty: true, days_in_week: 1 });
    const [stateErrors, setStateErrors] = useState(errors || {});
    const lastErrorsProp = useRef(errors);

    const stateStats = { ...originalStateStats };
    stateStats.days_in_week = stateStats.days_in_week || 2;

    useEffect(() => {
        if (lastErrorsProp.current !== errors) {
            setStateErrors(errors || {});
        }
    }, [errors]);

    const empty = stateStats.empty;
    const optIn = stateStats.optIn || false;
    const tainted = stateStats.tainted;
    const hasErrors = Object.keys(stateErrors).length !== 0;

    const onChange = (field, value) => {
        setStateStats((stateStats) => ({
            ...stateStats,
            tainted: true,
            [field]: value
        }))
        setStateErrors((errors) => {
            const newErrors = { ...errors };
            delete newErrors[field];
            return newErrors;
        });
    }

    const showOptut = !empty;
    const onOptOut = () => {
        setStateStats((stats) => ({ ...stats, optIn: false, tainted: true, empty: true }));
    }

    return <MaterialUiTheme>
        <RadicalModal onClose={onCancel} title={<FormattedMessage id="app.titles.profile" />}>
            <div className={styles.root}>
                <div className={styles.explain}>
                    <p><FormattedMessage id="app.messages.explain_profile_paragraph_1" /></p>
                    <p><FormattedMessage id="app.messages.explain_profile_paragraph_2" /></p>
                </div>

                {empty && <div className={styles.form}>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={inProgress}
                                checked={optIn}
                                onChange={(event) => setStateStats((stateStats) => ({
                                    ...stateStats,
                                    optIn: event.target.checked
                                }))}
                                name="optIn"
                                color="primary"
                            />
                        }
                        label={intl.formatMessage({ id: "app.fields.opt_in_profile" })}
                    />
                </div>}

                {(optIn || !empty) && <OptIn
                    dateFormat={dateFormat}
                    onChange={onChange}
                    stats={stateStats}
                    onSave={onSave}
                    inProgress={inProgress}
                    errors={stateErrors || {}}
                    kgs={kgs}
                    mts={mts}
                    tainted={tainted}
                />}



                {<div className={styles.form}>
                    {tainted && hasErrors && <div className={styles.errorWarning}>
                        <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;<FormattedMessage id="app.messages.please_review_errors" />
                        {stateErrors.global && <div className={styles.errorGlobal}>{stateErrors.global}</div>}
                    </div>}

                    <div className={styles.buttons}>
                        {showOptut && <div className={styles.control}>
                            <Button disabled={inProgress} variant="contained" color="secondary" onClick={onOptOut}>
                                <FormattedMessage id="app.fields.opt_out_profile" />
                            </Button>
                        </div>}

                        {tainted && !inProgress && <div className={styles.control}>
                            <Button disabled={inProgress} variant="contained" color="primary" onClick={() => (onSave(stateStats))}>
                                <FormattedMessage id="app.fields.save_changes" />
                            </Button>
                        </div>}

                        {tainted && inProgress && <div className={styles.control}>
                            <Button disabled variant="contained" color="primary" onClick={() => (onSave(stateStats))}>
                                <FormattedMessage id="app.fields.saving_changes" />
                            </Button>
                        </div>}
                    </div>
                </div>}
            </div>
        </RadicalModal>
    </MaterialUiTheme>
}

export default UserProfile;