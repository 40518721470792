import useWindowSize from "./useWindowSize";

function useSliderStyle(slider) {
    const windowSize = useWindowSize();

    if (slider === "main") {
        // 360 a 499 : (166x93) (229.55x129.13) 2 slides
        // 500 a 799 : (153x86) (245x138) 3 slides
        // 800 a 1099: (184x104) (253x142) 4 slides
        // 1100 a 1399: (202x114) (257x135) 5 slides
        // 1400 en adelante: 214 x120.  6 slides

        if (windowSize.width) {
            if (windowSize.width <= 499) {
                return {
                    spaceBetween: 5,
                    slidesPerView: 2,
                    key: "sl499"
                }
            }

            if (windowSize.width <= 799) {
                return {
                    spaceBetween: 5,
                    slidesPerView: 3,
                    key: "sl799"
                }
            }

            if (windowSize.width <= 1099) {
                return {
                    spaceBetween: 5,
                    slidesPerView: 4,
                    key: "sl1099"
                }
            }

            if (windowSize.width <= 1399) {
                return {
                    spaceBetween: 5,
                    slidesPerView: 5,
                    key: "sl1399"
                }
            }

            return {
                spaceBetween: 5,
                slidesPerView: 6,
                key: "sl1400"
            }
        }
    }

    // console.log(slider);
    // console.log(windowSize);
    if (slider === "metadata") {
        if (windowSize.width) {
            if (windowSize.width <= 360) {
                return {
                    spaceBetween: 4,
                    slidesPerView: 1,
                    key: "sl1360"
                }
            }

            if (windowSize.width <= 640) {
                return {
                    spaceBetween: 5,
                    slidesPerView: 2,
                    key: "sl640"
                }
            }

            if (windowSize.width <= 960) {
                return {
                    spaceBetween: 6,
                    slidesPerView: 4,
                    key: "sl960"
                }
            }
        }
    }

    if (slider === "metadata_wide") {
        if (windowSize.width) {
            if (windowSize.width <= 360) {
                return {
                    spaceBetween: 4,
                    slidesPerView: 1,
                    key: "sl360"
                }
            }

            if (windowSize.width <= 640) {
                return {
                    spaceBetween: 5,
                    slidesPerView: 2,
                    key: "sl640"
                }
            }

            if (windowSize.width <= 960) {
                return {
                    spaceBetween: 6,
                    slidesPerView: 5,
                    key: "sl960"
                }
            }
        }
    }

    if (slider === "training_plan") {
        if (windowSize.width) {
            if (windowSize.width <= 360) {
                return {
                    spaceBetween: 4,
                    slidesPerView: 2,
                    key: "sl360"    
                }
            }

            if (windowSize.width <= 640) {
                return {
                    spaceBetween: 5,
                    slidesPerView: 3,
                    key: "sl640"
                }
            }

            if (windowSize.width <= 960) {
                return {
                    spaceBetween: 8,
                    slidesPerView: 4,
                    key: "sl960"
                }
            }

            return {
                spaceBetween: 8,
                slidesPerView: 6,
                key: "sl961"
            }
        }
    }

    return {
        spaceBetween: 8,
        slidesPerView: 5,
        key: "sldefault"
    }
}

export default useSliderStyle;