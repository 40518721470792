import { useMemo } from 'react';
import { useSelector } from 'react-redux'
import { propertySelectors } from "./../store";

function useMultiVideoProgress(ids) {
    const forVideosProgresSelector = useMemo(
        () => (propertySelectors.progress.forVideos()),
        []
    );

    return useSelector(state => forVideosProgresSelector(state, ids));
}

export default useMultiVideoProgress;