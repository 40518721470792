import styles from './NextVideo.module.scss';
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'


function NextVideo({ name, image, onNextClick })  {
    const intl = useIntl();

    return <div className={styles.root}>
        <div className={styles.title}>{intl.formatMessage({ id: "app.messages.continue_with_next_video" })}</div>
        <div className={styles.nextVideo} onClick={onNextClick }>
            <div className={styles.image}>
                <img src={image} alt={name} />
                <FontAwesomeIcon icon={faPlayCircle} />
            </div>
            <div className={styles.name}>
                {name}
            </div>
        </div>
    </div>
}

export default NextVideo;