// martin.burak@cipherastudio.com", "e1bc28"

import React, { useCallback, useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from "react-intl";
import date from 'date-and-time';
import { selectors } from '../../store';
import { useSelector } from 'react-redux';
import 'date-fns';
import styles from './CreateYourAccount.module.scss';
import LoginContainer from '../LoginContainer';
import UxService from '../../services/UxService';
import Button from '@material-ui/core/Button';
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReCAPTCHA from 'react-google-recaptcha';
import Environment from '../../services/Environment';
import SessionService from '../../services/SessionService';

function ErrorMsg({
    inProgress,
    error,
}) {
    console.log("ERRROR", error);
    const intl = useIntl();
    let message = "";

    if (!inProgress) {
        if (error) {
            message = (error.message || "Unknonwn Error");
        }
        if (message.substring(0, 2) === "t:") {
            message = intl.formatMessage({ id: message.substring(2) });
        }
    }

    return (
        <div className={styles.message}>
            {message}
        </div>
    );
}

function Success({
    email,
}) {
    const intl = useIntl();
    const navigation = useRadicalNavigation();

    const onClick = useCallback(() => {
        navigation.login(null, false, false, email);
    }, [navigation, email]);

    return (
        <>
            <div className={styles.textTitle}>
                {intl.formatMessage({ id: "app.messages.welcome" })}
            </div>

            <div className={styles.textMessage}>
                {intl.formatMessage({ id: "app.messages.create_account_done_text_1" })}
            </div>

            <div className={styles.textMessage}>
                {intl.formatMessage({ id: "app.messages.create_account_done_text_2" })}
            </div>

            <Button variant="contained" type="submit" onClick={onClick}>
                {intl.formatMessage({ id: "app.fields.sign_in" })}
            </Button>
        </>
    );
}

function Create({ email }) {
    const intl = useIntl();
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState(null);

    const createAccount = UxService.Session.hooks.useCreateAccount();

    const locale = useSelector(selectors.locale.currentLocale);

    const recaptchaRef = React.useRef();
    const refEmail = React.useRef();
    const refPassword = React.useRef();
    const refPasswordConfirmation = React.useRef();

    const [emailValue, setEmailValue] = useState(email);
    const onEmailChange = useCallback((event) => {
        setEmailValue(event.target.value);
    }, [setEmailValue])

    const [hasPassword, sethasPassword] = useState(false);
    const onhasPasswordChange = useCallback((event) => {
        sethasPassword(event.target.checked);
    }, [sethasPassword])

    const [password, setPassword] = useState("");
    const onPasswordChange = useCallback((event) => {
        setPassword(event.target.value);
    }, [setPassword])

    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const onPasswordConfirmationChange = useCallback((event) => {
        setPasswordConfirmation(event.target.value);
    }, [setPasswordConfirmation])

    const onCreateAccount = useCallback((email, pwd, captcha) => {
        return SessionService.LoginFlow.createAccount(email, pwd, captcha).catch(() => {
            setInProgress(false);
            // taken care by the service
        });
    }, []);

    const onSubmit = useCallback(() => {
        setInProgress(true);
        let newLocalError = null;

        if (emailValue.trim() === "") {
            newLocalError = intl.formatMessage({ id: "app.errors.email_complete" });
            refEmail.current?.focus();
        }

        if (hasPassword && (password.trim() === "" || passwordConfirmation.trim() === "")) {
            newLocalError = intl.formatMessage({ id: "app.errors.password_and_confirmation_complete" });
            refPassword.current?.focus();
        }

        if (hasPassword && password !== passwordConfirmation) {
            newLocalError = intl.formatMessage({ id: "app.errors.password_and_confirmation_match" });
            refPasswordConfirmation.current?.focus();
        }

        if (newLocalError) {
            setError(new Error(newLocalError));
            setInProgress(false);
        } else {
            setError(null);
            console.log("RECAPTCHA");
            console.log(recaptchaRef.current);
            console.log("0000000000");
            recaptchaRef.current?.reset();
            recaptchaRef.current?.executeAsync().then((token) => {
                return onCreateAccount(emailValue, hasPassword ? password : null, token);
            }).finally(() => {
                setInProgress(false);
            });
        }
    }, [intl, setError, onCreateAccount, setInProgress, emailValue, hasPassword, password, passwordConfirmation]);

    if (createAccount?.current?.ok) {
        return <Success email={emailValue} />;
    }

    return (
        <div className={styles.fieldsContainer}>
            <div className={styles.form}>
                <div className={styles.field}>
                    <TextField
                        name="email"
                        fullWidth
                        inputRef={refEmail}
                        required id="standard-required"
                        label={intl.formatMessage({ id: "app.fields.email" })}
                        disabled={inProgress}
                        value={emailValue}
                        onChange={onEmailChange}
                    />
                </div>
                <div className={styles.field}>
                    {!hasPassword && <div className={{}}>{intl.formatMessage({ id: "app.messages.create_account_magic_link_text" })}</div>}

                    <FormControlLabel
                        control={<Checkbox color="primary" checked={hasPassword} onChange={onhasPasswordChange} />}
                        label={<FormattedMessage id="app.messages.create_account_password_text" />}
                    />
                </div>
                {hasPassword && (
                    <>
                        <div className={styles.field}>
                            <TextField
                                name="password"
                                inputRef={refPassword}
                                fullWidth
                                type="password"
                                required
                                label={intl.formatMessage({ id: "app.fields.password" })}
                                disabled={inProgress}
                                value={password}
                                onChange={onPasswordChange}
                            />
                        </div>
                        <div className={styles.field}>
                            <TextField
                                name="passwordConfirmation"
                                inputRef={refPasswordConfirmation}
                                fullWidth
                                secured
                                type="password"
                                required
                                label={intl.formatMessage({ id: "app.fields.password_confirmation" })}
                                disabled={inProgress}
                                value={passwordConfirmation}
                                onChange={onPasswordConfirmationChange}
                            />
                        </div>
                    </>
                )}
                <ErrorMsg inProgress={inProgress} error={error || createAccount?.error} />
                <div style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" onClick={onSubmit}>
                        <FormattedMessage id="app.fields.create_account" />
                    </Button>
                </div>
                <ReCAPTCHA
                    hl={locale}
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={Environment.loginFlowRecaptcha}
                />
            </div>
        </div>
    )
}

function InactiveAccount({ email }) {
    const intl = useIntl();
    const navigation = useRadicalNavigation();

    const onClick = useCallback(() => {
        navigation.checkout(false);
    }, [navigation]);

    return (
        <div className={styles.fieldsContainer}>
            <div className={styles.textMessage}>{intl.formatMessage({ id: "app.messages.create_account_user_inactive_text_1" })}</div>
            <div className={styles.textMessage}>{intl.formatMessage({ id: "app.messages.create_account_user_inactive_text_2" })}</div>

            <Button variant="contained" type="submit" onClick={onClick} style={{ width: "100%", marginTop: 15 }}>
                {intl.formatMessage({ id: "app.fields.checkout_button" })}
            </Button>
        </div>
    )
}

function ActiveAccount({ email }) {
    const intl = useIntl();
    const navigation = useRadicalNavigation();

    const onClick = useCallback(() => {
        navigation.login(null, false, false, email);
    }, [navigation, email]);

    return (
        <div className={styles.fieldsContainer}>
            <div className={styles.textMessage}>{intl.formatMessage({ id: "app.messages.create_account_user_active_text_1" })}</div>
            <div className={styles.textMessage}>{intl.formatMessage({ id: "app.messages.create_account_user_active_text_2" })}</div>

            <Button variant="contained" type="submit" onClick={onClick} style={{ width: "100%", marginTop: 15 }}>
                {intl.formatMessage({ id: "app.fields.sign_in" })}
            </Button>
        </div>
    )
}

function CreateYourAccount({ tenant, goToRoot }) {
    const intl = useIntl();

    const identify = UxService.Session.hooks.useIdentify();
    const nonActiveCredentials = UxService.Session.hooks.useNonActiveCredentials();

    const canCreate = nonActiveCredentials && !nonActiveCredentials.inactive;
    const inactive = nonActiveCredentials && !canCreate;
    const active = !!identify?.current;
    const email = active ? identify.current.email : nonActiveCredentials.email;

    return (
        <LoginContainer tenant={tenant} goToRoot={goToRoot}>
            {/* <div className={styles.textMessage}>
                {intl.formatMessage({ id: "app.messages.create_account_text" })}
            </div> */}

            {canCreate && (
                <Create email={email} />
            )}

            {inactive && (
                <InactiveAccount email={email} />
            )}

            {active && (
                <ActiveAccount email={email} />
            )}

        </LoginContainer>
    );
}

export default CreateYourAccount;