import {
    ThemeProvider,
    createMuiTheme
} from '@material-ui/core/styles';
import Environment from "../../services/Environment";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: Environment.brand === "rhf" ? "#97bd45" : "#5fb1df",

        },
        text: {
            primary: "#fff"
        },
    },
    typography: {
        fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif;",
    },
    overrides: {
        MuiCheckbox: {
            root: {
                color: Environment.brand === "rhf" ? "#97bd45" : "#5fb1df",
            }
        },
        MuiTypography: {
            body1: {
                fontSize: "16px",
            },
        },
        MuiList: {
            root: {
                background: "#333",
                fontSize: '16px',
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: '16px',
            }
        },
        MuiInputLabel: {
            // Name of the rule
            root: {
                // Some CSS
                color: '#e5e5e5',
                fontSize: '16px',
            },
        },
        MuiInputBase: {
            // Name of the rule
            input: {
                // Some CSS
                color: '#e5e5e5',
                fontSize: '16px',
            },
        },
        MuiFormLabel: {
            filled: {
                backgroundColor: "rgba(0,0,0,0)",
            },
            root: {
                fontSize: "16px",
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: "2px solid #888",
                }
            },
        },
        MuiIconButton: {
            root: {
                padding: "0px",
            }
        },
        MuiButton: {
            label: {
                fontSize: "12px",
            },
            contained: {
                backgroundColor: Environment.brand === "rhf" ? "#97bd45" : "#5fb1df",
                color: "#fff",
                '&:hover': {
                    backgroundColor: Environment.brand === "rhf" ? "#638025" : "#3d7da2",
                },
                '&.Mui-disabled': {
                    backgroundColor: "#333",
                    color: "#aaa",
                },
            },
            test: {
                backgroundColor: "transparent",
                borderColor: Environment.brand === "rhf" ? "#97bd45" : "#5fb1df",
                color: Environment.brand === "rhf" ? "#97bd45" : "#5fb1df",
            }
        },
        MuiFormControl: {
            root: {
                alignItems: "center",
                width: "100%",
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: "12px",
            }
        }
    },
});

const themeRftv = createMuiTheme({
    palette: {
        primary: {
            main: "#eb3423",

        },
        text: {
            primary: "#fff"
        },
    },
    typography: {
        fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif;",
    },
    overrides: {
        MuiTypography: {
            body1: {
                fontSize: "16px",
            },
        },
        MuiList: {
            root: {
                background: "#333",
                fontSize: '16px',
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: '16px',
            }
        },
        MuiInputLabel: {
            // Name of the rule
            root: {
                // Some CSS
                color: '#e5e5e5',
                fontSize: '16px',
            },
        },
        MuiInputBase: {
            // Name of the rule
            input: {
                // Some CSS
                color: '#e5e5e5',
                fontSize: '16px',
            },
        },
        MuiFormLabel: {
            filled: {
                backgroundColor: "rgba(0,0,0,0)",
            },
            root: {
                fontSize: "16px",
            }
        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: "2px solid #888",
                }
            },
        },
        MuiIconButton: {
            root: {
                padding: "0px",
            }
        },
        MuiButton: {
            label: {
                fontSize: "12px",
            },
            contained: {
                backgroundColor: "#eb3423",
                color: "#fff",
                '&:hover': {
                    backgroundColor: "#f56153",
                }
            },
        },
        MuiFormControl: {
            root: {
                alignItems: "center",
                width: "100%",
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: "12px",
            }
        }
    },
});

export default function MaterialUiThemeLogin({ children }) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}