import { hooksFromSelectors } from "../stores/utils";
import { selectors } from "../store";

const locale = {
    current: "en",
    default: "en"
};

// These values come from Rhf:: Common:: ImagesService.new.derivative_sizes
// const imageSizes = {
//     slide: {
//         xs: [148, 83], s: [222, 124], m: [296, 166], l: [592, 332], xl: [1184, 664]
//     },
//     slide_vertical: {
//         xs: [148, 249], s: [222, 373], m: [296, 498], l: [592, 996], xl: [1184, 1992]
//     },
//     video_preview: {
//         xs: [148, 83], s: [222, 124], m: [296, 166], l: [592, 332], xl: [1184, 664]
//     },
//     outstander: {
//         xs: [600, 300], s: [800, 400], m: [1200, 600], l: [2400, 1200], xl: [4800, 2400]
//     }
// }

const tryValue = (obj, field, locale) => {
    return obj[`${field}_${locale}`];
}

// TODO: desterrar useImage para aca
const sessionImages = {};

const UxService = {
    formatDate(date) {
        return (new Date(date)).toString();
    },

    videoName(video, slide) {
        if (!video || !slide) {
            return "UNKNOWN";
        }

        const videoName = this.localeValue(video, "name");
        
        if (video.id === slide.id) {
            return videoName;
        } else {
            return this.slideName(slide) + " - " + videoName;
        }
    },

    slideName(slide) {
        return this.localeValue(slide, "name");
    },

    setLocale(newLocale) {
        locale.current = newLocale;
    },

    selectImage(images) {
        for (let h = 0; h < images.length; h++) {
            if (sessionImages[images[h]]) {
                return images[h];
            }
        }
        const ranking = images.map((img) => ({
            ranking: parseInt(localStorage.getItem("images:" + img)) || 0,
            img,
        }));

        const sorted = ranking.sort((a, b) => (a.ranking - b.ranking));
        localStorage.setItem("images:" + sorted[0].img, sorted[0].ranking + 1);

        sessionImages[sorted[0].img] = true
        
        return sorted[0].img;
    },

    toSrcSet(image, sizes) {
        const srcset = []
        for (let key in sizes) {
            srcset.push(image.variants[key] + " " + sizes[key] + "w");
        }

        return {
            default: image.variants.m || image.default,
            srcset: srcset.join(", "),
            blurhash: image.blurhash
        };
    },

    selectImageSet(images, sizes) {
        for (let h = 0; h < images.length; h++) {
            if (sessionImages[images[h].default]) {
                return this.toSrcSet(images[h], sizes);
            }
        }
        const ranking = images.map((img) => ({
            ranking: parseInt(localStorage.getItem("images:" + img.default)) || 0,
            img,
        }));

        const sorted = ranking.sort((a, b) => (a.ranking - b.ranking));
        localStorage.setItem("images:" + sorted[0].img.default, sorted[0].ranking + 1);

        sessionImages[sorted[0].img.default] = true

        // - build the srcset
        return this.toSrcSet(sorted[0].img, sizes);
    },
        

    localeImage(obj, field) {
        let rv = tryValue(obj, field, locale.current);
        if (rv && rv.length > 0) {
            return this.selectImage(rv);
        }

        rv = obj[field];
        if (rv && rv.length > 0) {
            return this.selectImage(rv);
        }

        rv = tryValue(obj, field, locale.default);
        if (rv && rv.length > 0) {
            return this.selectImage(rv);
        }

        return null;
    },

    localeImageSet(obj, field, fallback, sizes) {
        let rv = tryValue(obj, field, locale.current + "_set");
        if (rv && rv.length > 0) {
            return this.selectImageSet(rv, sizes);
        }

        rv = tryValue(obj, field, locale.default + "_set");
        if (rv && rv.length > 0) {
            return this.selectImageSet(rv, sizes);
        }

        return null;
    },

    localeSourceLang(obj, field) {
        const sources = obj[field];
        if (sources) {
            let rv = sources[locale.current];
            if (rv) {
                return locale.current;
            }

            return locale.default;
        } else {
            return null;
        }
    },

    localeSource(obj, field) {
        const sources = obj[field];
        if (sources) {
            let rv = sources[locale.current];
            if (rv) {
                return rv;
            }

            return sources[locale.default];
        } else {
            return null;
        }
    },

    localeValue(obj, field) {
        let rv = tryValue(obj, field, locale.current);
        if (rv) {
            return rv;
        }

        rv = obj[field];
        if (rv) {
            return rv;
        }

        rv = tryValue(obj, field, locale.default);
        if (rv) {
            return rv;
        }
    },

    Session: {
        hooks: hooksFromSelectors(selectors.session),
    }
}


export default UxService;