import { createSlice, createSelector } from '@reduxjs/toolkit'

const initialState = {
    selectedSeason: {},
    trainings: {}
};

const slidesSlice = createSlice({
    name: 'slides',
    initialState,
    reducers: {
        changeSeason: {
            reducer: (state, action) => {
                state.selectedSeason[action.payload.slideId] = action.payload.season;
            },
            prepare: (slideId, season) => {
                return { payload: { slideId, season } }
            },
        },
        initiaizeTrainings: {
            reducer: (state, action) => {
                state.trainings = { ...action.payload.trainings };
            },
            prepare: ( trainings ) => {
                return { payload: { trainings } }
            },
        },
        addSeenToTraining: {
            reducer: (state, action) => {
                const training = state.trainings[action.payload.slideId];
                const dates = training.dates || {};
                const seen = training.seen || [];
                const ts = Math.floor(Date.now() / 1000);

                state.trainings[action.payload.slideId] = {
                    ...training,
                    seen: [...new Set([...seen, action.payload.seenId])],
                    dates: {
                        ...dates,
                        [action.payload.seenId]: (dates[action.payload.seenId] || ts)
                    }
                 };
            },
            prepare: (slideId, seenId) => {
                return { payload: { slideId, seenId } }
            },
        },

        addTraining: {
            reducer: (state, action) => {
                state.trainings[action.payload.slideId] = { id: action.payload.trainingId, seen: [], dates: {} };
            },
            prepare: (slideId, trainingId) => {
                return { payload: { slideId, trainingId } }
            },
        },
        removeTraining: {
            reducer: (state, action) => {
                state.trainings[action.payload.slideId] = null;
            },
            prepare: (slideId) => {
                return { payload: { slideId } }
            },
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    }
})


const selectors = (getRoot) => ({
    selectedSeasons: createSelector(getRoot, data => data.selectedSeason),
    trainings: createSelector(getRoot, data => data.trainings),
});

slidesSlice.selectors = selectors;
export default slidesSlice;
