import { useIntl } from "react-intl";
import Button from '@material-ui/core/Button'
import { useState } from "react";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import SessionService from "../../services/SessionService";

const SelfPanelableButton = ({ license, slug, franchiseCode, type }) => {
    const intl = useIntl();
    const navigation = useRadicalNavigation();
    const valid = type === "seller";
    const [error,setError] = useState(null);
    const [inProgress,setInProgress] = useState(false);

    const onPanel = () => {
        setError(null);
        setInProgress(true);
        SessionService.startPortal().then(() => {
            setError(null);
            setInProgress(false);
        }).catch((err) => {
            setError(err);
            setInProgress(false);
        })
    }

    if (valid) {
        return (
            <>
                <Button disabled={inProgress} variant="contained" onClick={onPanel}>
                    {intl.formatMessage({ id: "app.fields.panel_me" })}
                </Button>
                {error && <div>ERROR {error.toString()}</div>}
            </>
        );
    } else {
        return <div></div>;
    }
}

export default SelfPanelableButton;