// martin.burak@cipherastudio.com", "e1bc28"

import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

import styles from './MagicLinkExchanger.module.scss';
import SessionService from "../../services/SessionService";
import LoadingWithText from "../LoadingWithText";
import UxService from '../../services/UxService';
import ErrorWithText from "../../components/ErrorWithText";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function MagicLinkExchanger({ continueAsGuest, tenant, locale, goToRoot }) {
    const intl = useIntl();
    const navigation = useRadicalNavigation();

    const query = useQuery();
    const redirect = query.get("redirect");
    const lang = query.get("lang");
    const target = query.get("target");
    const { code } = useParams();

    const { error } = UxService.Session.hooks.useSignIn() || {};
    const [lastCode, setLastCode] = useState(null);

    useEffect(() => {
        if (lang) {
            SessionService.setLocale(lang);
        }
    }, [lang])

    useEffect(() => {
        console.log("CALL EFFECT", code, redirect)
        if (code && code !== lastCode) {
            setLastCode(code);
            SessionService.LoginFlow.logInWithMagicLink(code, redirect).then((res) => {

                if (target === "manage-account") {
                    navigation.subscription(res.tenant.slug, true);
                } else if (target === "tv-link") {
                    navigation.tvLink();
                } else if (target === "checkout-account") {
                    navigation.checkout(false);
                } else { 
                    console.log(res);
                    (res.welcomed && !res.tyc.update) ? navigation.main() : navigation.welcome();
                }
            }).catch((error) => {
                // - the service should've put the state in the store
                console.log(error);
            });
        }
    }, [code, redirect, navigation, target, tenant, lastCode, setLastCode]);

    let textSmall = intl.formatMessage({ id: "app.messages.sign_in_progress" });
    let textBig = intl.formatMessage({ id: "app.messages.doing_magic_1" });

    if (error) {
        let message = (error.message || "Unknonwn Error");
        if (message.substring(0,2) === "t:") {
            message = intl.formatMessage({ id: message.substring(2)});
        }
        textBig = intl.formatMessage({ id: "app.errors.magic_link_failed" })
        textSmall = message;

        return (
            <ErrorWithText
                center={true}
                textSmall={textSmall}
                textBig={textBig}
            />
        );
    }

    return (
        <LoadingWithText
            center={true}
            textSmall={textSmall}
            textBig={textBig}
        />
    );
}

export default MagicLinkExchanger;