
import styles from './TrainingPlan.module.scss';
// import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { selectors } from "../../store"
import { useState } from "react";
import ProfileService from "../../services/ProfileService";
import { FormattedMessage, FormattedDate, } from "react-intl";
import clsx from "clsx";
import { useSelector } from 'react-redux'
import { faSpinner, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import useSliderStyle from "../../hooks/useSliderStyle";
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Environment from '../../services/Environment';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

function ConfirmDialog({ open, text, onAccept, onClose }) {
    if (!open) {
        return <div />;
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"><FormattedMessage id="app.titles.confirmation" /></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        <FormattedMessage id="app.fields.cancel" />
                    </Button>
                    <Button onClick={onAccept} color="primary" autoFocus>
                        <FormattedMessage id="app.fields.ok" />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const calculateCalories = (calories, gender, length) => {
    if (calories) {
        if (gender === "M" || gender === "O") {
            return calories.male;
        } else if (gender === "F") {
            return calories.male;
        } else {
            return Math.floor((parseInt(calories.male) + parseInt(calories.female)) / 2);
        }
    } else {
        let sampleLength = 3600;
        let sampleCalories = 800;

        // - try with length
        if (length <= 60) {
            sampleLength = 60;
            sampleCalories = 5
        } else if (length <= 300) {
            sampleLength = 300;
            sampleCalories = 40
        } else if (length <= 900) {
            sampleLength = 900;
            sampleCalories = 150
        } else if (length <= 1800) {
            sampleLength = 1800;
            sampleCalories = 300
        }

        return Math.floor((sampleCalories * length) / sampleLength);

    }
};

function Seen({ seen, date, number, calories }) {
    const year = (new Date()).getYear();

    return <div className={styles.planInfoItem}>
        <div className={clsx(styles.planInfoItemNumber, !seen && styles.planInfoItemNumberNotSeen)}>{number}</div>
        {seen && <div>
            <p className={styles.planInfoItemDate}>
                {year === date.getYear() ? <FormattedDate
                    value={date}
                    month="long"
                    day="2-digit"
                /> : <FormattedDate
                    value={date}
                    year="numeric"
                    month="long"
                    day="2-digit"
                />}
            </p>
            <p className={styles.planInfoItemCalories}>
                <FormattedMessage id="app.messages.x_calories" values={{ calories }} />
            </p>
        </div>}
    </div>
}
function TrainingPlan({ slideId, seasons }) {
    const trainingPlan = (useSelector(selectors.slides.trainings) || {})[slideId];
    const trainingPlanSeen = trainingPlan.seen || [];
    const trainingPlanDates = trainingPlan.dates || {};

    const userStats = useSelector(selectors.session.profileStats);

    const totalSeen = trainingPlanSeen.length;
    const [actionStatus, setActionStatus] = useState({ inProgress: false });
    const allChapters = seasons.map((s) => (s.chapters)).flat();

    const inProgress = actionStatus.inProgress;
    const inProgressMsg = actionStatus.inProgressMsg;
    const error = actionStatus.error;
    const sliderStyle = useSliderStyle("training_plan");

    const onDismiss = () => {
        setActionStatus({
            ...actionStatus,
            inProgress: false,
            confirming: false,
            error: null
        })
    }

    const onRestart = () => {
        setActionStatus({
            ...actionStatus,
            inProgress: true,
            inProgressMsg: <FormattedMessage id="app.messages.restarting_training" />,
            confirming: false,
            error: null
        })
        ProfileService.restartTraining(slideId).then(() => {
            setActionStatus({
                ...actionStatus,
                inProgress: false,
                error: null
            })
        }).catch(() => {
            setActionStatus({
                ...actionStatus,
                inProgress: false,
                error: <FormattedMessage id="app.messages.restarting_training_error" />
            })
        });
    };

    const onStop = () => {
        setActionStatus({
            ...actionStatus,
            inProgress: true,
            inProgressMsg: <FormattedMessage id="app.messages.stoping_training" />,
            confirming: false,
            error: null
        })
        ProfileService.stopTraining(slideId).then(() => {
            setActionStatus({
                ...actionStatus,
                inProgress: false,
                error: null
            })
        }).catch(() => {
            setActionStatus({
                ...actionStatus,
                inProgress: false,
                error: <FormattedMessage id="app.messages.stoping_training_error" />
            })
        });
    };

    const onStopConfirm = () => {
        setActionStatus({
            ...actionStatus,
            inProgress: false,
            confirming: true,
            confrimText: <FormattedMessage id="app.messages.confirm_stop_training" />,
            confirmAccept: onStop,
            error: null
        })
    }

    const onRestartConfirm = () => {
        setActionStatus({
            ...actionStatus,
            inProgress: false,
            confirming: true,
            confrimText: <FormattedMessage id="app.messages.confirm_restart_training" />,
            confirmAccept: onRestart,
            error: null
        })
    }


    if (inProgress) {
        return <div className={styles.planInfoInProgress}>
            <div className={styles.inProgress}>
                <FontAwesomeIcon icon={faSpinner} pulse />&nbsp;{inProgressMsg}
            </div>
        </div>;
    }

    const navigation = (allChapters.length > sliderStyle.slidesPerView)

    return <div className={styles.root}>
        <div className={styles.trainingPlanProgress}><FormattedMessage id="app.titles.training_plan_progress" /></div>
        <div className={styles.planTitleContainer}>
            <div className={styles.planTitle}>
                <FormattedMessage id="app.titles.training_plan" values={{ total: allChapters.length, seen: totalSeen }} />
            </div>
        </div>

        {error && <div className={styles.error}>
            <FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;
            <FormattedMessage id="app.buttons.start_training_error" />
        </div>}

        <div className={styles.planInfoWrapper}>
            {totalSeen > 0 && <Swiper
                navigation={navigation}
                {...sliderStyle}
            >
                {allChapters.map((s, ix) => {
                    const seen = (trainingPlan.seen.indexOf(s.id) >= 0);
                    const date = seen && (new Date(trainingPlanDates[s.id] * 1000));
                    const calories = calculateCalories(s.calories, userStats.gender, (s.duration || 5) * 60);

                    return <SwiperSlide key={s.id}>
                        <Seen seen={seen} number={ix + 1} date={date} calories={calories} />
                    </SwiperSlide>
                })}
            </Swiper>}

            {totalSeen <= 0 && <div className={styles.planInfoWrapper}>
                <div className={styles.planZeroSeen}>
                    <FormattedMessage id="app.messages.training_zero_seen" />
                </div>
            </div>}
        </div>
        <div className={styles.planInfoButtons}>
            <div className={Environment.brand === "rtv" ? styles.buttonRF : styles.buttonRH} onClick={onStopConfirm}>
                {/*<FontAwesomeIcon icon={faTimesCircle} />*/}<FormattedMessage id="app.fields.stop" />
            </div>

            {/*totalSeen > 0 && <div className={styles.button} onClick={onRestartConfirm}>
                    <FontAwesomeIcon icon={faRedo} /><FormattedMessage id="app.fields.restart" />
                </div>*/}
        </div>

        <ConfirmDialog open={actionStatus.confirming} text={actionStatus.confrimText} onAccept={actionStatus.confirmAccept} onClose={onDismiss} />

    </div>
}

export default TrainingPlan;