import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx';
import styles from './LoadingWithText.module.scss';


function LoadingWithText({ center, small, medium, large, textSmall, textBig, onBigClick, onSmallClick }) {
    
    const _large = large || (!small && !medium && !large);
    const _small = !_large && small;
    const _medium = !_large && medium;

    return (<div className={clsx(styles.root, center && styles.rootCenter)}>
        <div className={clsx(styles.spinner,_small && styles.spinnerS, _medium && styles.spinnerM)}><FontAwesomeIcon icon={faSpinner} pulse /></div>
        {textSmall && <div onClick={onSmallClick} className={clsx(styles.msgSmall, _medium && styles.msgSmallM, _small && styles.msgSmallS)}>{textSmall}</div>}
        {textBig && <div onClick={onBigClick} className={clsx(styles.msgBig, _medium && styles.msgSmallM, _small && styles.msgBigS)}>{textBig}</div>}
    </div>);
}

export default LoadingWithText;