import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faEmptyHeart } from '@fortawesome/free-regular-svg-icons';
import Environment from '../../../services/Environment';

import styles from './FavButton.module.scss';

function FavButton({ onClick, className, checked }) {
    return (
        <span className={styles.root + " " + (className || "")} onClick={() => onClick(!checked)}>
            <FontAwesomeIcon icon={checked ? faHeart : faEmptyHeart} color={checked ? Environment.brandColor : "white"} />
        </span>
    );
}

export default FavButton;