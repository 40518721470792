import {
    ThemeProvider,
    createMuiTheme
} from '@material-ui/core/styles';
import Environment from "../../services/Environment";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: Environment.brand === "rhf" ? "#97bd45" : "#5fb1df",

        },
        text: {
            primary: "#fff"
        },
        secondary: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff",
        }
    },
    typography: {
        fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif;",
    },
    overrides: {
        // Style sheet name ⚛️
        MuiFormControl: {
            // Name of the rule
            root: {
                // Some CSS
                borderRadius: '20px',
            },
            marginNormal: {
                marginTop: "0px",
                marginBottom: "0px",
                width: "100%",
            },
        },
        MuiInputLabel: {
            // Name of the rule
            root: {
                // Some CSS
                color: '#e5e5e5',
                fontSize: '16px',
            },
        },
        MuiInputBase: {
            // Name of the rule
            input: {
                // Some CSS
                color: '#e5e5e5',
                fontSize: '20px',
            },
        },
        MuiInputAdornment: {
            root: {
                fontSize: '16px',
            },
        },
        MuiFormLabel: {
            filled: {
                backgroundColor: "#222",
            },
            root: {
                fontSize: "16px",
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: "#333",
                fontSize: "16px",
                maxWidth: "200px",
            }
        },
        MuiMenuItem: {
            root: {
                backgroundColor: "#333",
                fontSize: "16px",
            }
        },
        MuiFormHelperText: {
            root: {
                marginTop: "0px",
            },
        },
        MuiTypography: {
            body1: {
                fontSize: "16px",
            }
        },
        MuiSlider: {
            valueLabel: {
                fontSize: "12px",
                fontWeight: "700",
            },
            markLabelActive: {
                fontSize: "14px",
            },
            markLabel: {
                fontSize: "14px",
                color: "#666",
            },

        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: "2px solid #e5e5e5",
                }
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#e5e5e5",
                fontSize: "20px",
            }
        },
        MuiIconButton: {
            root: {
                padding: "0px",
            }
        },
        MuiButton: {
            label: {
                fontSize: "12px",
            }
        },
        MuiButtonBase: {
            root: {
                margin: "0 10px",
            },

        },
        MuiBadge: {
            badge: {
                color: "#fff",
                fontWeight: "700",
                backgroundColor: Environment.brand === "rhf" ? "#97bd45" : "#5fb1df",
                padding: 0,
                height: "15px",
                minWidth: "15px",
                fontSize: "12px",
            }
        },
        MuiTypography: {
            subtitle1: {
                fontSize: "24px",
            },
            h4: {
                fontSize: "20px",
            },
            body1: {
                fontSize: "12px",
                fontWeight: "700",
            },
            body2: {
                fontSize: "12px",
            },
            caption: {
                fontSize: "12px !important",
                color: "#888 !important",
                fontWeight: "700 !important",
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                //backgroundColor:"#e5e5e5",
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: "12px",
            }
        },
        MuiSelect: {
            selectMenu: {
                minHeight: "0px !important",
            }
        }
    },
});

const themeRftv = createMuiTheme({
    palette: {
        primary: {
            main: "#eb3423",

        },
        text: {
            primary: "#fff"
        },
        secondary: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff",
        }
    },
    typography: {
        fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif;",
    },
    overrides: {
        // Style sheet name ⚛️
        MuiFormControl: {
            // Name of the rule
            root: {
                // Some CSS
                borderRadius: '20px',
            },
            marginNormal: {
                marginTop: "0px",
                marginBottom: "0px",
                width: "100%",
            },
        },
        MuiInputLabel: {
            // Name of the rule
            root: {
                // Some CSS
                color: '#e5e5e5',
                fontSize: '16px',
            },
        },
        MuiInputBase: {
            // Name of the rule
            input: {
                // Some CSS
                color: '#e5e5e5',
                fontSize: '20px',
            },
        },
        MuiInputAdornment: {
            root: {
                fontSize: '16px',
            },
        },
        MuiFormLabel: {
            filled: {
                backgroundColor: "#222",
            },
            root: {
                fontSize: "16px",
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: "#333",
                fontSize: "16px",
                maxWidth: "200px",
            }
        },
        MuiMenuItem: {
            root: {
                backgroundColor: "#333",
                fontSize: "16px",
            }
        },
        MuiFormHelperText: {
            root: {
                marginTop: "0px",
            },
        },
        MuiTypography: {
            body1: {
                fontSize: "16px",
            }
        },
        MuiSlider: {
            valueLabel: {
                fontSize: "12px",
                fontWeight: "700",
            },
            markLabelActive: {
                fontSize: "14px",
            },
            markLabel: {
                fontSize: "14px",
                color: "#666",
            },

        },
        MuiInput: {
            underline: {
                '&:before': {
                    borderBottom: "2px solid #e5e5e5",
                }
            },
        },
        MuiSvgIcon: {
            root: {
                color: "#e5e5e5",
                fontSize: "20px",
            }
        },
        MuiIconButton: {
            root: {
                padding: "0px",
            }
        },
        MuiButton: {
            label: {
                fontSize: "12px",
            }
        },
        MuiButtonBase: {
            root: {
                margin: "0 10px",
            },

        },
        MuiBadge: {
            badge: {
                color: "#fff",
                fontWeight: "700",
                backgroundColor: "#eb3423",
                padding: 0,
                height: "15px",
                minWidth: "15px",
                fontSize: "12px",
            }
        },
        MuiTypography: {
            subtitle1: {
                fontSize: "24px",
            },
            h4: {
                fontSize: "20px",
            },
            body1: {
                fontSize: "12px",
                fontWeight: "700",
            },
            body2: {
                fontSize: "12px",
            },
            caption: {
                fontSize: "12px !important",
                color: "#888 !important",
                fontWeight: "700 !important",
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                //backgroundColor:"#e5e5e5",
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: "12px",
            }
        },
        MuiSelect: {
            selectMenu: {
                minHeight: "0px !important",
            }
        }
    },
});

export default function MaterialUiTheme({ children }) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}