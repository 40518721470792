import { useSelector } from 'react-redux';

export function asyncActionsDefaultValue(name) {
  return {
    [name]: {
      tainted: false,
      inProgress: false,
      error: null,
      success: false,
      stalled: false,
    },
  }
}

export function asyncActions(name, eqArgs = ((a,b) => (a === b))) {
  return {
    [`${name}Initialize`]: (state) => {
      state[name] = {
        tainted: false,
        inProgress: false,
        error: null,
        current: null,
        success: false,
        stalled: false,
      };
    },

    [`${name}Start`]: {
      reducer: (state, action) => {
        state[name].last = state[name].current;

        state[name].tainted = true;
        state[name].inProgress = true;
        state[name].success = false;
        state[name].error = null;
        if (!action.payload.keepCurrent) {
          state[name].current = null;
        }
        state[name].args = action.payload.args;
        state[name].stalled = false;
      },
      prepare: (args, keepCurrent = false) => ({ payload: { args, keepCurrent }}),
    },

    [`${name}Success`]: {
      reducer: (state, action) => {
        state[name].tainted = true;
        state[name].inProgress = false;
        state[name].success = true;
        state[name].current = action.payload.current;
        state[name].error = null;
      },
      prepare: (current) => ({ payload: { current }}),
    },

    [`${name}Stalled`]: {
      reducer: (state, action) => {
        if (eqArgs(state[name].args, action.payload.stallArgs) || action.payload.forceStall) {
          state[name].stalled = (true);
        }
      },
      prepare: (stallArgs, forceStall) => ({ payload: { stallArgs, forceStall } }),
    },

    [`${name}Failed`]: {
      reducer: (state, action) => {
        state[name].tainted = true;
        state[name].inProgress = false;
        state[name].success = false;
        state[name].error = action.error;
      },
      prepare: (error) => ({ error }),
    },
  }
}

export function hooksFromSelectors(selectorsToHook) {
  const rv = {};

  Object.entries(selectorsToHook).forEach(([selector, fn]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    rv[`use${selector[0].toUpperCase()}${selector.slice(1)}`] = () => useSelector(fn);
  });

  return rv;
};