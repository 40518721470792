//<ShareButton className={styles.shareButton} onClick={onShare} {...CollectionsService.shareDataForSlide(slideId, shareTitle)} />

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCircle, faPlayCircle, faLock } from '@fortawesome/free-solid-svg-icons';

import LazyImage from "../LazyImage";

import FavButton from "./../Buttons/FavButton";
// import ShareButton from "./../Buttons/ShareButton";
import useFavFromSlideId from "./../../hooks/useFavFromSlideId";
import ProfileService from "./../../services/ProfileService";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
// import CollectionsService from "../../services/CollectionsService";
import styles from './SwiperCard.module.scss';
import { useIntl } from "react-intl";
import Environment from '../../services/Environment';
import clsx from "clsx";
import { useRef } from "react";

// EXPLAIN: a hack to set the z-index stack of the parent of the slide in
// the front. As we are manipulating the DOM directlty this is a very
// hacky way to do it
const sendAllToBack = () => {
    const allContainers = document.getElementsByClassName('rhf-swiper-container');
    for (let i = 0, len = allContainers.length | 0; i < len; i = i + 1 | 0) {
        const swipers = allContainers[i].getElementsByClassName("swiper-container-initialized");
        if (swipers.length > 0) {
            swipers[0].style.zIndex = 1;
        }
    }
}

function SwiperCard({ blurhash, unavailableForGuest, cardsPerSwiper, large, name, img, imgSrcSet, slideId, description, index, enumerate, externalOpen, showLiveBand, showIsNewBand, isVisible, firstRightInvisible, alwaysVisible, metadata }) {
    const intl = useIntl();
    // const shareTitle = intl.formatMessage({ id: "app.messages.share_message" }, { name });
    const liveTitle = intl.formatMessage({ id: "app.messages.live_title" }, { name });

    const navigation = useRadicalNavigation();
    const fav = useFavFromSlideId(slideId);

    const rootRef = useRef(null);

    const onFav = (checked) => {
        ProfileService.favSlide(slideId, checked);
    }

    // const onShare = () => {
    //     alert("SHARE!");
    // }

    const handleMouseOver = () => {
        sendAllToBack();

        if (rootRef.current) {
            const parent = rootRef.current.closest(".swiper-container-initialized");
            if (parent) {
                parent.style.zIndex = 20;
            }
        }
    }

    const handleClick = () => {
        navigation.library.item(slideId);
    }

    // const addDefaultSrc = (ev) => {
    //     ev.target.src = Environment.defaultImages.slide
    //     ev.target.srcset = null;
    // };

    const handlePlay = () => {
        if (!externalOpen) {
            navigation.library.continue(slideId);
        } else {
            window.open(externalOpen, '_blank');
        }
    }

    if (!img) {
        img = showLiveBand ? Environment.defaultImages.stream : Environment.defaultImages.slide;
    }

    let showMetadata = false, metadataDifficulty = "-", metadataBodyFocus = "-";
    if (metadata && !metadata.no_exercise) {
        if ((metadata.difficulty || []).length > 0) {
            metadataDifficulty = metadata.difficulty.map((v) => (intl.formatMessage({ id: `app.metadata.difficulty.${v}` }))).join("-");
        }

        if ((metadata.body_focus || []).length > 0) {
            metadataBodyFocus = metadata.body_focus.map((v) => (intl.formatMessage({ id: `app.metadata.body_focus.${v}` }))).join(",");
        }

        showMetadata = true;
    }

    const blurhashToUse = blurhash || "LGFFaXYk^6#M@-5c,1J5@[or[Q6.";


    return <div ref={rootRef} onMouseOver={handleMouseOver} className={clsx(styles.rhfSwiperSlide, large && styles.rhfSwiperSlideLarge, isVisible && styles.rhfSwiperSlideVisible, firstRightInvisible && styles.rhfSwiperSlideLastVisible)}>
        {large && enumerate && <div className={styles.index}>
            {index + 1}
        </div>}

        {showLiveBand && <div className={styles.liveTitle}>
            <FontAwesomeIcon icon={faCircle} />&nbsp;{liveTitle}
        </div>}

        {showIsNewBand && <div className={Environment.brand === "rhf" ? styles.isNewTitle : styles.isNewTitleRfTv}>
            {intl.formatMessage({ id: "app.messages.is_new_title" })}
        </div>}


        <div className={styles.imgContainer}>
            <div className={styles.imgAspect}>
                <div className={styles.img}>


                    <LazyImage blurhash={blurhashToUse} alt={name} src={img} onClick={handleClick} srcSet={imgSrcSet} sizes={cardsPerSwiper ? (100 / cardsPerSwiper * 1.55) + "vw" : "100vw"} />


                </div>
            </div>
        </div>
        <div className={styles.infoContainer}>

            <div className={clsx(styles.infoBar, alwaysVisible && styles.infoBarAlwaysVisible)}>
                <div className={styles.nameBar}>
                    <div className={styles.actions}>
                        <div className={styles.playButton} onClick={handlePlay}>
                            <FontAwesomeIcon icon={faPlayCircle} />
                        </div>
                    </div>

                    <div className={styles.nameCaption} onClick={handleClick}>
                        {name}
                    </div>

                    <div className={styles.actions}>
                        {unavailableForGuest && <FontAwesomeIcon icon={faLock} size={'1x'} color={Environment.brandColor} />}
                        <FavButton className={styles.favButton} checked={fav} onClick={onFav} />
                    </div>
                </div>
            </div>

            {showMetadata && <div className={styles.infoBarExtraContainer}>
                <div className={styles.infoBarExtraItem}>
                    <div className={styles.infoBarExtraItemTitle}>{intl.formatMessage({ id: "app.fields.difficulty" })}:<span className={styles.infoBarExtraItemText}>&nbsp;{metadataDifficulty}</span></div>
                </div>

                <div className={styles.infoBarExtraItem}>
                    <div className={styles.infoBarExtraItemTitle}>{intl.formatMessage({ id: "app.fields.body_focus" })}:<span className={styles.infoBarExtraItemText}>&nbsp;{metadataBodyFocus}</span></div>
                </div>
            </div>}
        </div>
    </div>
}

export default SwiperCard;