import React from "react";
import styled from "styled-components";
import LazyLoad from "react-lazyload";
import { Blurhash } from "react-blurhash";
import styles from './LazyImage.module.scss';
import clsx from "clsx";

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
`;

const LazyImage = ({ src, alt, onClick, srcSet, sizes, blurhash }) => {
    const refPlaceholder = React.useRef();
    const refImg = React.useRef();
    const [isLoaded, setIsLoaded] = React.useState(false);

    const removePlaceholder = () => {
       // refPlaceholder.current.remove();
        setIsLoaded(true);
    };

    const removeImage= () => {
        refImg.current.remove();
    };

    return (
        <ImageWrapper>
            <Placeholder ref={refPlaceholder}>
                <Blurhash
                    hash={blurhash}
                    width="99%"
                    height="99%"
                />
            </Placeholder>
            <LazyLoad once offset={300} height="100%" width="100%">
                <img
                    className={clsx(isLoaded && styles.animation)}
                    style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        opacity: isLoaded ? 1 : 0,
                    }}
                    ref={refImg}
                    onLoad={removePlaceholder}
                    onError={removeImage}
                    src={src}
                    alt={alt}
                    onClick={onClick}
                    srcSet={srcSet}
                    sizes={sizes}
                    width="100%" height="100%"
                />
            </LazyLoad>
        </ImageWrapper>
    );
};

export default LazyImage;