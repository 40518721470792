import ReactGA from 'react-ga4';
import log from 'loglevel';
import BitSet from "bitset";
import Environment from "./Environment";
import CollectionsService from "../services/CollectionsService";
import Server from "./Server";
import { store, actions } from "../store";

log.info("[Instrumentation] cookies accepted: ", Environment.cookiesAccepted);
if (Environment.cookiesAccepted) {
    ReactGA.initialize(Environment.uaId);
}

const registerGaEvent = (event) => {
    if (Environment.cookiesAccepted) {
        ReactGA.event(event);
    }
}

const progressQueue = {};
const completeQueue = {}
const playSessionsQueue = {};
const playSessions = {};
let locale = null;

const InstrumentationService = {
    setLocale(l) {
        locale = l;
    },
    
    queueProgress(slideId, videoId, progress, seconds, playSession, trainingPlanId) {
        progressQueue[`${slideId}@${videoId}`] = { slideId, videoId, progress, seconds: Math.round(seconds), trainingPlanId };
        playSessionsQueue[playSession.playSessionId] = { ...playSession, played: playSession.played.toString(16) }
    },

    reset() {
        const wipe = (obj) => {
            for (const field in obj) {
                if (obj.hasOwnProperty(field)) {
                    delete obj[field];
                }
            }
        }

        wipe(progressQueue);
        wipe(completeQueue);
        wipe(playSessionsQueue);
        wipe(playSessions);
    },

    queueComplete(slideId) {
        completeQueue[slideId] = slideId;
    },

    startUpdate() {
        setInterval(() => {
            this.updateProgress();
        }, 10000);
    },

    updateProgress() {
        const toUpdateProgress = { ...progressQueue };
        const toUpdatePlaySessions = { ...playSessionsQueue };
        const toUpdateComplete = { ...completeQueue }

        const fieldsToArray = (toUpdate, target) => {
            const data = []
            for (var field in toUpdate) {
                if (target.hasOwnProperty(field)) {
                    data.push(toUpdate[field]);
                }
            }
            return data;
        }

        const removeFromQueue = (src, target) => {
            for (var field in src) {
                if (target.hasOwnProperty(field)) {
                    delete target[field];
                }
            }
        }

        const dataProgress = fieldsToArray(toUpdateProgress, progressQueue);
        const dataPlaySessions = fieldsToArray(toUpdatePlaySessions, playSessionsQueue);
        const dataComplete = fieldsToArray(toUpdateComplete, completeQueue);

        if (dataProgress.length + dataPlaySessions.length > 0) {
            Server.Progress.report(dataProgress, dataPlaySessions, dataComplete).then(() => {
                // - reset reported
                removeFromQueue(toUpdateProgress, progressQueue);
                removeFromQueue(toUpdatePlaySessions, playSessionsQueue);
                removeFromQueue(toUpdateComplete, completeQueue);
            }).catch(error => {
                // - nothing to do here, is a best effor thing 
                log.warn("[Instrumentation] Progress Report Failed");
                log.warn(error);
            });
        }

    }
}

const Video = {
    resetSlideVideoProgress(slideId) {
        const videoIds = CollectionsService.videoIdsForSlide(slideId);
        store.dispatch(actions.progress.resetForVideos(videoIds));
    },

    completedSlide(slideId, trainingPlanId) {
        log.info("[Instrumentation] slide " + slideId + " has been completed");

        // - remove from continue watching local
        if (!trainingPlanId) {
            this.resetSlideVideoProgress(slideId)
        }
        store.dispatch(actions.collections.removeFromContinueWatching(slideId));
        
        // - remove from continue watching server
        InstrumentationService.queueComplete(slideId);
    },

    track(slideId, videoId, position, seconds, playSessionId, lang, trainingPlanId = null) {
        log.info("[Instrumentation] track " + videoId + " " + position + "% (" + seconds + "s), PlaySession " + playSessionId);

        registerGaEvent({
            category: 'Video',
            action: 'View',
            label: videoId,
            value: position
        });
        
        // 1 - calculate bitset for seconds partitioned every 30 seconds
        const playedUpdatePostion = Math.floor(seconds / 10);
        const playedUpdate = new BitSet();
        playedUpdate.set(playedUpdatePostion, 1);

        // 2 - get current bitset for playSessionId
        const now = Date.now();
        const playSessionData = playSessions[playSessionId] || {
            playSessionId,
            started: now,
            slideId,
            videoId,
            lang: lang || locale,
            trainingPlanId
        };

        playSessionData.lastUpdate = now;
        if (playSessionData.played) {
            playSessionData.played = playSessionData.played.or(playedUpdate);
        } else {
            playSessionData.played = playedUpdate;
        }

        // console.log(playedUpdatePostion);
        // console.log(playedUpdate.toString());
        // console.log(playSessionData.played.toString());
        // console.log(playSessionData.played.toString(16));

        // - add seen to training plans
        if (trainingPlanId && position > 95) {
            const action = actions.slides.addSeenToTraining(slideId, videoId);
            store.dispatch(action);
        }

        playSessions[playSessionId] = playSessionData;
       
        store.dispatch(actions.progress.update(videoId, position, seconds, playSessionData ));

        InstrumentationService.queueProgress(slideId, videoId, position, seconds, playSessionData, trainingPlanId );
    },
}

const Collection = {
    page(page) {
        registerGaEvent({
            category: 'Collection',
            action: 'Page',
            value: page
        });
    },

    slide(slideId) {
        registerGaEvent({
            category: 'Slide',
            action: slideId
        });
    },

    sharedSlideContents(slideId) {
        registerGaEvent({
            category: 'Shared-Slide-Contents',
            action: slideId
        });
    },

    sharedSlideInfo(slideId) {
        registerGaEvent({
            category: 'Shared-Slide-Info',
            action: slideId
        });
    },

    fav(slideId, value) {
        registerGaEvent({
            category: 'Fav',
            action: slideId,
            value: value ? 1 : 0
        });
    }
}

const Navigation = {
    track(location) {
        // ReactGA.pageview(location);
        ReactGA.send({ hitType: "pageview", page: location });
    }
}

InstrumentationService.Video = Video;
InstrumentationService.Navigation = Navigation;
InstrumentationService.Collection = Collection;

InstrumentationService.startUpdate();

export default InstrumentationService;