import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useIntl } from "react-intl";

import ItemCompleted from "./../../ItemCompleted";
import LoadingWithText from "../../LoadingWithText";
import Suggestions from "../../Suggestions";
import NextVideo from "../../NextVideo";

import ItemPlay from "./../../ItemPlay";
import CollectionsService from "../../../services/CollectionsService";
import InstrumentationService from "../../../services/InstrumentationService";
import { actions, selectors } from "./../../../store";
import useVideoProgress from "../../../hooks/useVideoProgress";
import useRadicalNavigation from "../../../hooks/useRadicalNavigation";
import useSuggestionsFromId from "../../../hooks/useSuggestionsFromId";
import UxService from "../../../services/UxService";
import { useSelector } from 'react-redux'
import styles from './LibraryPlay.module.scss';

// import styles from './LibraryPlay.module.scss';

const calculateTime = (seconds) => {
    if (seconds < 60) {
        return Math.floor(seconds) + " seconds";
    }

    return Math.floor(seconds / 60) + ":" + Math.floor(seconds % 60) + " mins"

}

const calculateCalories = (calories, gender, length) => {
    if (calories) {
        if (gender === "M" || gender === "O") {
            return calories.male;
        } else if (gender === "F") {
            return calories.male;
        } else {
            return Math.floor((parseInt(calories.male) + parseInt(calories.female)) / 2);
        }
    } else {
        let sampleLength = 3600;
        let sampleCalories = 800;

        // - try with length
        if (length <= 60) {
            sampleLength = 60;
            sampleCalories = 5
        } else if (length <= 300) {
            sampleLength = 300;
            sampleCalories = 40
        } else if (length <= 900) {
            sampleLength = 900;
            sampleCalories = 150
        } else if (length <= 1800) {
            sampleLength = 1800;
            sampleCalories = 300
        }

        return Math.floor((sampleCalories * length) / sampleLength);

    }
};

const NotForYouOverlay = ({ onMoreInfo, onClose, guest }) => {
    const navigation = useRadicalNavigation();
    const profile = useSelector(selectors.session.profile);
    const intl = useIntl();
    const tenant = profile.tenant;

    const onManageYourAccount = (e) => {
        navigation.subscription(profile.user);
        onClose();
    };

    const onStartFreeTrial = (e) => {
        navigation.createYourAccount(true);
    };

    const onSignIn = () => {
        navigation.loginToTenant(tenant, true);
    };

    return <div onClick={onClose}>
        <div style={{ maxWidth: '100%', color: "#e5e5e5", minHeight: "100vh", zIndex: 0, position: "relative", width: "100%" }} className={[styles.row]}>

            <div className={styles.imageContainer} />

            {guest && (
                <div className={styles.textContainer}>
                    <div className={styles.gradintText}></div>
                    <div className={styles.text}>
                        <img className={styles.logo} src="https://drrbcrbjxn8ae.cloudfront.net/mailers/assets/img/RTV-logo-header-2.png" alt="Radical Fitness" />
                        <h1 className={styles.title}>{intl.formatMessage({ id: "app.messages.watch_more_on_rftv" })}</h1>

                        <div className={styles.signUpContainer}>
                            <a onClick={(e) => {
                                onStartFreeTrial();
                                e.preventDefault();
                                return false;
                            }} className={styles.button}>
                                {intl.formatMessage({ id: "app.messages.start_your_free_trial" })}
                            </a>
                        </div>
                        <div className={styles.signUpContainer}>
                            <a onClick={(e) => {
                                onClose();
                                e.preventDefault();
                                return false;
                            }} className={styles.button}>
                                {intl.formatMessage({ id: "app.messages.keep_browsing" })}
                            </a>
                        </div>

                        <div className={styles.logInContainer}>
                            <p className={styles.logInText}> Already subscribed?</p>
                            <a href="#" onClick={(e) => {
                                onSignIn();
                                e.preventDefault();
                                return false;
                            }} className={styles.button2}>
                                {intl.formatMessage({ id: "app.messages.back_to_sign_in" })}
                            </a>
                        </div>
                    </div>
                </div>
            )}

            {!guest && (
                <div className={styles.textContainer}>
                    <div className={styles.gradintText}></div>
                    <div className={styles.text}>
                        <img className={styles.logo} src="https://drrbcrbjxn8ae.cloudfront.net/mailers/assets/img/RTV-logo-header-2.png" alt="Radical Fitness" />
                        <h1 className={styles.title}>{intl.formatMessage({ id: "app.messages.watch_more_on_rftv" })}</h1>

                        <div className={styles.signUpContainer}>
                            <a onClick={(e) => {
                                onManageYourAccount();
                                e.preventDefault();
                                return false;
                            }} className={styles.button}>
                                {intl.formatMessage({ id: "app.titles.manage_your_account" })}
                            </a>
                        </div>
                        <div className={styles.signUpContainer}>
                            <a onClick={(e) => {
                                onClose();
                                e.preventDefault();
                                return false;
                            }} className={styles.button}>
                                {intl.formatMessage({ id: "app.messages.keep_browsing" })}
                            </a>
                        </div>

                    </div>
                </div>
            )}
        </div>


    </div>;
}
const LibraryPlay = ({ slideId, videoId, onClose, onTrack, restart, trainingPlanId }) => {
    const video = CollectionsService.video(videoId);
    const isGuest = useSelector(selectors.session.isGuest);
    const freeTrialEnded = UxService.Session.hooks.useFreeTrialEnded();
    const abortCantSee = !video.guest_visible && (isGuest || freeTrialEnded);

    console.log(video);

    const next = CollectionsService.next(slideId, videoId);
    const nextVideo = next && CollectionsService.video(next.videoId);

    const dispatch = useDispatch();
    const intl = useIntl();

    const progress = useVideoProgress(videoId);
    const navigation = useRadicalNavigation();
    const userStats = useSelector(selectors.session.profileStats);

    const [localProgress] = useState(progress);
    const [lastTrack, setLastTrack] = useState(0);
    const [completed, setCompleted] = useState(false);
    const [lastVideo, setLastVideo] = useState({ slideId, videoId });


    // EXPLAIN: this check is necesary for the following scenario:
    // react re-uses the player wihtout unmounting the component
    // If we comment out this code, then the new video will show
    // completed from start-up
    if (lastVideo.slideId !== slideId || lastVideo.videoId !== videoId) {
        setLastVideo({ slideId, videoId });
        setCompleted(false);
    }

    const suggestionsStatus = useSuggestionsFromId(slideId, videoId);
    const loadSuggestions = () => {
        dispatch(actions.collections.suggestions({ slideId, videoId })).catch((error) => {
            console.log(error);
        })
    }

    const handleComplete = () => {
        if (!suggestionsStatus && !next) {
            loadSuggestions();
        }

        if (!next) {
            InstrumentationService.Video.completedSlide(slideId, trainingPlanId);
        }
        setCompleted(true);
    };

    const handleOnTrack = (e) => {
        setLastTrack(e.seconds);
        if (e.percent > 95 && !next && !suggestionsStatus) {
            loadSuggestions();
        }

        onTrack(e);
    }

    // - add to continue watching
    useEffect(() => {
        if (!abortCantSee) {
            return dispatch(actions.collections.updateContinueWatchingWith(slideId));
        }
    }, [slideId, dispatch, abortCantSee]);

    if (abortCantSee) {
        return <NotForYouOverlay onClose={onClose} guest={isGuest} />;
    }

    if (!completed) {
        return <ItemPlay
            onComplete={handleComplete}
            id={videoId}
            onClose={onClose}
            onTrack={handleOnTrack}
            metadata={video.metadata}
            title={UxService.localeValue(video, "name")}
            description={UxService.localeValue(video, "description")}
            richDescription={UxService.localeValue(video, "long_description")}
            source={UxService.localeSource(video, "sources")}
            sourceLang={UxService.localeSourceLang(video, "sources")}
            at={restart ? 0 : (localProgress.progress >= 98 ? 0 : localProgress.seconds)} />;
    } else {
        let more = null;
        const stats = {
            no_exercise: video.no_exercise,
            calories: !video.no_exercise && calculateCalories(video.calories, userStats.gender, lastTrack),
            time: calculateTime(lastTrack)
        };

        if (next) {
            const onNext = () => {
                navigation.library.play(slideId, next.videoId, true);
            };

            more = <NextVideo image={UxService.localeImage(nextVideo, "images_video_preview")} name={UxService.localeValue(nextVideo, "name")} onNextClick={onNext} />
        } else {
            more = <div>
                {suggestionsStatus && suggestionsStatus.inProgress && <LoadingWithText
                    small={true}
                    textSmall={intl.formatMessage({ id: "app.messages.please_wait" })}
                    textBig={intl.formatMessage({ id: "app.messages.retrieving_suggestions" })}
                />}

                {suggestionsStatus && suggestionsStatus.success && <Suggestions
                    suggestions={suggestionsStatus.slides}
                    onSuggestionClick={(slideId) => (navigation.library.item(slideId))}
                />}

                {((suggestionsStatus && suggestionsStatus.error) || !suggestionsStatus) && <div></div>}
            </div>;
        }

        return <ItemCompleted stats={stats} onClose={onClose}>
            {more}
        </ItemCompleted>;
    }
}

export default LibraryPlay;