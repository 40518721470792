import styles from "./RadicalImage.module.scss";
import LazyLoad from 'react-lazyload';
import LazyImage from "../LazyImage";

// TODO: define sizes using codes
// eg radical modal, radical_modal % 20, etc

function parseSizes(sizes) {
    if (!sizes) {
        return null;
    }

    // - this should mimic the breakpoints for radicalModal
    if (sizes === "radicalModal") {
        return "(max-width: 849px) and (min-width:641px) 95vw, (max-width:640px) 100vw, 850px";
    }

    if (sizes === "chapterVideoPreview") {
        return "(max-width: 640px) 95vw, 170px";
    }

    return null;
}
function RadicalImage({ img, imgSrcSet, blurhash, onClick, sizes }) {
    const blurhashToUse = blurhash || "LGFFaXYk^6#M@-5c,1J5@[or[Q6.";
      
    return (
        <div className={styles.imgContainer}>
            <div className={styles.imgAspect}>
                <div className={styles.img}>
                    <LazyImage blurhash={blurhashToUse} alt="img" src={img} onClick={onClick} srcSet={imgSrcSet} sizes={parseSizes(sizes) || "100vw"} />
                </div>
            </div>
        </div>
    );
}

export default RadicalImage;