import { createSlice, createSelector } from '@reduxjs/toolkit'

const initialState = {
    notifications: [],
    removed: [],
};

const notificationsSlide = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        dismiss: {
            reducer: (state, action) => {
                state.notifications = state.notifications.filter((n) => (n.id !== action.payload.notificationId));
                state.removed = [...state.removed, action.payload.notificationId];
            },
            prepare: (notificationId) => {
                return { payload: { notificationId } }
            },
        },
        initialize: {
            reducer: (state, action) => {
                state.notifications = [...action.payload.notifications];
            },
            prepare: (notifications) => {
                return { payload: { notifications } }
            },
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    }
})

const selectors = (getRoot) => ({
    all: createSelector(getRoot, data => data.notifications.filter((n) => (data.removed.indexOf(n.id) < 0))),
    short: createSelector(getRoot, data => (data.notifications.filter((n) => (data.removed.indexOf(n.id) < 0)).slice(0, 5) )),
});

notificationsSlide.selectors = selectors;

export default notificationsSlide;
