// martin.burak@cipherastudio.com", "e1bc28"

import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import ReCAPTCHA from "react-google-recaptcha"
import log from 'loglevel';
import { useIntl, FormattedMessage } from "react-intl";
import rhfLogo from '../../assets/logo_apaisado_RH.png';
import { selectors, actions } from "../../store"
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import { useLocation } from "react-router-dom";

import styles from './Login.module.scss';
import SessionService from "../../services/SessionService";
import ProfileService from "../../services/ProfileService";
import CollectionsService from "../../services/CollectionsService";
import Environment from "../../services/Environment";
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import LoadingWithText from "../LoadingWithText";
import MaterialUiThemeLogin from "../MaterialUiThemeLogin";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Flag from "react-flagpack";
import { translations } from "./../../translations";
import backdrop from '../Welcome/res/img/rhf_back.jpg';
import UxService from '../../services/UxService';
import LoginContainer from '../LoginContainer';

const updateStores = (dispatch, profile) => {
    const sessionProfile = { ...profile };
    delete sessionProfile.progress;
    delete sessionProfile.fav;
    delete sessionProfile.outstanders;
    delete sessionProfile.trainings;

    ProfileService.startUpdate();

    dispatch(actions.session.success(sessionProfile));
    dispatch(actions.slides.initiaizeTrainings(profile.trainings));
    dispatch(actions.progress.initialize(profile.progress));
    dispatch(actions.fav.initialize(profile.fav.slides));
    dispatch(actions.notifications.initialize(profile.notifications));

    CollectionsService.setOutstanders(profile.outstanders);
}

const localeToFlag = (locale) => {
    if (locale === "en") {
        return "US";
    }

    if (locale === "es") {
        return "ES";
    }

    if (locale === "fr") {
        return "FR";
    }

    if (locale === "ja") {
        return "JP";
    }

    if (locale === "zh") {
        return "TW";
    }

    return "US";
}
const LocaleSelect = ({ value, onChange }) => {
    const intl = useIntl();
    const label = "";

    const id = label;
    const labelId = `${label}-id`;

    const options = Object.keys(translations).map((locale) => ({
        value: locale,
        label: intl.formatMessage({ id: `app.locales.${locale}` }),
    }));

    return <div className={styles.control}>
        <FormControl fullWidth>
            <InputLabel id={labelId}>
                {label}
            </InputLabel>
            <Select
                labelId={labelId}
                id={id}
                value={value || ""}
                onChange={(event) => (onChange(event.target.value))}
            >
                {options.map((o) => (

                    <MenuItem key={o.value} value={o.value}>
                        <div className={styles.selectMenuItem}>
                            <Flag code={localeToFlag(o.value)} />
                            <div className={styles.selectMenuItemText}>{o.label}</div>
                        </div>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </div>;

}

function RecoveringSession() {
    return (<div className={styles.recoverSession}>
        <FontAwesomeIcon icon={faSpinner} pulse />&nbsp;
        <FormattedMessage id="app.messages.recovering_session" />
    </div>);
}

function ErrorMsg({
    inProgress,
    error,
}) {
    const intl = useIntl();
    let message = "";

    if (!inProgress) {
        if (error) {
            message = (error.message || "Unknonwn Error");
        }
        if (message.substring(0, 2) === "t:") {
            message = intl.formatMessage({ id: message.substring(2) });
        }
    }

    return (
        <div className={styles.fieldSignIn}>
            {message}
        </div>
    );
}

function Form({ buttonIsContinue, allowNonActive, tenant, onGuestClick, onSignUpClick, defaultEmail, setDefaultEmail, onResetPassword, redirect }) {
    const [step, setStep] = React.useState("email");
    const navigation = useRadicalNavigation();

    const { error } = UxService.Session.hooks.useSignIn() || {};

    useEffect(() => {
        if (error?.restartFlow && step !== "email") {
            setStep("email");
        }
    }, [error, step, setStep]);

    const onBackToEmail = useCallback(() => {
        setStep("email");
        SessionService.LoginFlow.cancel();
    }, [setStep])

    return (
        <>
            {step === "email" && (
                <FormIdentify
                    allowNonActive={allowNonActive}
                    onChangeStep={setStep}
                    setDefaultEmail={setDefaultEmail}
                    defaultEmail={defaultEmail}
                    tenant={tenant}
                    buttonIsContinue={buttonIsContinue}
                />
            )}

            {step === "password" && (
                <FormPassword
                    onChangeStep={setStep}
                    onCancel={onBackToEmail}
                    onResetPassword={onResetPassword}
                    tenant={tenant}
                    buttonIsContinue={buttonIsContinue}
                />
            )}
            {step === "magic_link" && (
                <MagicLink
                    redirect={redirect}
                    onCancel={onBackToEmail}
                    onResetPassword={onResetPassword}
                    tenant={tenant}
                />
            )}
        </>
    );
}

const initObserver = () => {
    // Find the captcha window by first getting a list of iFrames.
    // After that we find the correct iFrame based on the src attribute
    // The actualy DIV that hides it, is a grandparent. So we get the
    // parentNode prop 2 times.
    const recaptchaWindow = [
        ...document.getElementsByTagName('iframe'),
    ]?.find((x) => x.src.includes('google.com/recaptcha/api2/bframe'))
        ?.parentNode?.parentNode;

    // Make sure it is defined (it was found in the doc) before we add the observer
    if (recaptchaWindow) {
        new MutationObserver(() => {
            // ReCaptcha changes these 3 props when going invisible.
            // To solve this, we put an observer on the attributes and 
            // check if one of these 3 properties changed from their
            // initial value.
            if (
                recaptchaWindow.style.visibility !== 'visible' ||
                recaptchaWindow.style.opacity !== '1' ||
                recaptchaWindow.style.top !== '10px'
            ) {
                // If changed, put back on default values.
                recaptchaWindow.style.opacity = '1';
                recaptchaWindow.style.visibility = 'visible';
                recaptchaWindow.style.top = '10px';
            }
        }).observe(recaptchaWindow, {
            attributeFilter: ['style'],
        });
    }
};

function LoginForm({ includeRecaptcha, onSubmit, FieldsComponent, fieldsProps, error }) {
    const recaptchaRef = React.useRef();
    const locale = useSelector(selectors.locale.currentLocale);
    const [localInProgress, setLocalInProgress] = useState(false);

    const onLocalSubmit = useCallback((args) => {
        setLocalInProgress(true);

        let submitPromise = null;
        if (includeRecaptcha) {
            recaptchaRef.current?.reset();
            submitPromise = recaptchaRef.current?.executeAsync().then((token) => {
                return onSubmit(args, token)
            }).catch((error) => {
                console.log("ERROR CAPTCHA");
                console.log(error);
            });
        } else {
            submitPromise = onSubmit(args);
        }

        submitPromise.finally(() => {
            recaptchaRef.current?.reset();
            setLocalInProgress(false);
        });

        return false;

    }, [recaptchaRef, includeRecaptcha, onSubmit]);

    return (
        <form className={styles.form}>
            <div>
                <FieldsComponent {...fieldsProps} inProgress={localInProgress} onSubmit={onLocalSubmit} />
                {includeRecaptcha && (
                    <div className={styles.recaptcha}>
                        <ReCAPTCHA
                            hl={locale}
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={Environment.loginFlowRecaptcha}
                        />
                    </div>
                )}
                <ErrorMsg inProgress={localInProgress} error={error} />
                <div className={styles.field}>
                    <div className={styles.select}>
                        <LocaleSelect value={locale} onChange={(locale) => {
                            SessionService.setLocale(locale);
                        }} />
                    </div>
                </div>
            </div>
        </form>
    );

}

function FormIdentityFields({ buttonIsContinue, inProgress, onSubmit, defaultEmail }) {
    const intl = useIntl();
    const [email, setEmail] = React.useState(defaultEmail || "");

    const onEmailChange = useCallback((event) => {
        setEmail(event.target.value)
    }, [setEmail])

    const onMySubmit = useCallback(() => {
        onSubmit({ email });
    }, [email, onSubmit]);

    return (
        <>
            <div className={styles.field}>
                <TextField
                    autoFocus
                    name="email"
                    fullWidth
                    required id="standard-required"
                    label={intl.formatMessage({ id: "app.fields.email" })}
                    disabled={inProgress}
                    value={email}
                    onChange={onEmailChange}
                />
            </div>

            <div className={styles.fieldSignIn}>
                <Button disabled={inProgress} variant="contained" type="submit" onClick={onMySubmit}>
                    {!buttonIsContinue
                        ? intl.formatMessage({ id: !inProgress ? "app.fields.sign_in" : "app.messages.sign_in_progress" })
                        : intl.formatMessage({ id: !inProgress ? "app.fields.continue" : "app.messages.in_progress" })
                    }
                </Button>
            </div>
        </>
    );
}

function FormIdentify({ buttonIsContinue, allowNonActive, tenant, defaultEmail, onChangeStep, setDefaultEmail }) {
    const { error } = UxService.Session.hooks.useIdentify() || {};

    const fieldsProps = React.useMemo(() => (
        {
            defaultEmail,
            setDefaultEmail,
            buttonIsContinue,
        }
    ), [defaultEmail, setDefaultEmail, buttonIsContinue]);

    const onSubmit = useCallback(({ email }, captcha) => {
        return SessionService.LoginFlow.identify(email, tenant, captcha, allowNonActive).then(({ methods }) => {
            if (methods.indexOf("password") >= 0) {
                onChangeStep("password");
            } else {
                onChangeStep("magic_link");
            }
            setDefaultEmail(email);
        }).catch(() => {
            // do nothing - the error should be in the store now
        });
    }, [tenant, setDefaultEmail, onChangeStep, allowNonActive]);

    return (
        <LoginForm
            error={error}
            onSubmit={onSubmit}
            includeRecaptcha
            FieldsComponent={FormIdentityFields}
            fieldsProps={fieldsProps}
        />
    );
}

function FormPasswordFields({ tenant, inProgress, onSubmit, onCancel, onForgotPassword, buttonIsContinue }) {
    const intl = useIntl();
    const [password, setPassword] = useState("");

    const onPasswordChange = useCallback((event) => {
        setPassword(event.target.value)
    }, [setPassword])

    const onMySubmit = useCallback(() => {
        onSubmit({ password });
    }, [password, onSubmit]);

    return (
        <>
            <div className={styles.field}>
                <TextField
                    autoFocus
                    name="password"
                    fullWidth
                    required id="standard-required"
                    label={intl.formatMessage({ id: "app.fields.password" })}
                    disabled={inProgress}
                    type="password"
                    value={password}
                    onChange={onPasswordChange}
                />
            </div>

            <div className={styles.fieldSignIn}>
                <Button disabled={inProgress} variant="contained" type="submit" onClick={onMySubmit}>
                    {!buttonIsContinue
                        ? intl.formatMessage({ id: !inProgress ? "app.fields.sign_in" : "app.messages.sign_in_progress" })
                        : intl.formatMessage({ id: !inProgress ? "app.fields.continue" : "app.messages.in_progress" })
                    }
                </Button>

                <div className={styles.forgot} onClick={onForgotPassword}>
                    <span>{intl.formatMessage({ id: "app.messages.forgot_password" })}</span>
                </div>
            </div>

            <div className={styles.buttonCancel}>

                <Button disabled={inProgress} variant="contained" onClick={onCancel}>
                    {intl.formatMessage({ id: "app.fields.cancel" })}
                </Button>
            </div>
        </>
    );
}

function MagicLink({ tenant, onCancel, onResetPassword, redirect }) {
    const { error } = UxService.Session.hooks.useMagicLinkRequest() || {};
    const identify = UxService.Session.hooks.useIdentify() || {};
    const email = identify?.current?.email;
    const token = identify?.current?.token;

    const fieldsProps = React.useMemo(() => (
        {
            onCancel,
            onResetPassword,
        }
    ), [onCancel, onResetPassword]);

    const onSubmit = useCallback(({ password }) => {
        return SessionService.LoginFlow.requestMagicLink(token, email, redirect, tenant).catch(() => {
            // do nothing - the error should be in the store now
        });
    }, [email, tenant, redirect, token]);

    return (
        <LoginForm
            error={error}
            onSubmit={onSubmit}
            FieldsComponent={MagicLinkFields}
            fieldsProps={fieldsProps}
        />
    );
}


function MagicLinkFields({ tenant, inProgress, onSubmit, onCancel, onResetPassword }) {
    const intl = useIntl();

    const { current } = UxService.Session.hooks.useMagicLinkRequest() || {};
    const email = (UxService.Session.hooks.useIdentify() || {})?.current?.email || "";
    const sent = (current?.ok);

    const onMySubmit = useCallback(() => {
        onSubmit({});
    }, [onSubmit]);

    return (
        <>
            <div className={styles.forgotPasswordContainer}>
                <div className={styles.textMessages}>
                    {intl.formatMessage({ id: !sent ? "app.messages.welcome_send_magic_link" : "app.messages.magic_link_sent", name: email })}
                </div>

                {!sent && (
                    <Button disabled={inProgress} variant="contained" type="submit" onClick={onMySubmit}>
                        {intl.formatMessage({ id: !inProgress ? "app.messages.request_magic_link" : "app.messages.request_magic_link_in_progress" })}
                    </Button>
                )}

                {!sent && (
                    <div className={styles.forgot} onClick={onResetPassword}>
                        <span>{intl.formatMessage({ id: "app.messages.create_new_password" })}</span>
                    </div>
                )}

                <Button disabled={inProgress} variant="contained" onClick={onCancel}>
                    {intl.formatMessage({ id: !sent ? "app.fields.cancel" : "app.fields.back_to_sign_in" })}
                </Button>
            </div>
        </>
    );
}

function FormPassword({ tenant, onCancel, onChangeStep, buttonIsContinue }) {
    const { error } = UxService.Session.hooks.useSignIn() || {};
    const identify = UxService.Session.hooks.useIdentify() || {};
    const email = identify?.current?.email;
    const token = identify?.current?.token;

    const onForgotPassword = useCallback(() => {
        onChangeStep("magic_link");
    }, [onChangeStep]);

    const fieldsProps = React.useMemo(() => (
        {
            onCancel,
            onForgotPassword,
            buttonIsContinue,
        }
    ), [onCancel, onForgotPassword, buttonIsContinue]);

    const onSubmit = useCallback(({ password }) => {
        return SessionService.LoginFlow.logInWithPassword(token, email, password, tenant).catch(() => {
            // do nothing - the error should be in the store now
        });
    }, [email, tenant, token]);

    return (
        <LoginForm
            error={error}
            onSubmit={onSubmit}
            FieldsComponent={FormPasswordFields}
            fieldsProps={fieldsProps}
        />
    );
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Login({ justifyContent, title, subtitle, allowNonActive, continueAsGuest, tenant, locale, goToRoot, prefillEmail, forceRedirect }) {
    const intl = useIntl();
    const query = useQuery();
    const redirect = forceRedirect || query.get("redirect");
    const account = query.get("account");
    const lang = query.get("lang");

    useEffect(() => {
        if (lang) {
            SessionService.setLocale(lang);
        }
    }, [lang])

    const [internalInProgress, setInternalInProgress] = useState(true);
    const [internalRedirecting, setInternalRedirecting] = useState(false);
    const dispatch = useDispatch();
    const authenticated = useSelector(selectors.session.authenticated);
    const reseted = useSelector(selectors.session.reseted);
    const inProgress = useSelector(selectors.session.inProgress);
    const error = useSelector(selectors.session.error);
    const tainted = useSelector(selectors.session.tainted);

    const navigation = useRadicalNavigation();
    const guestClickHandler = useCallback((() => {
        return SessionService.guestSignIn(tenant).then((profile) => {
            updateStores(dispatch, profile);
        }).catch((error) => {
            log.debug(error);
            dispatch(actions.session.failed(error?.serialize() || error.toString()));
        });
    }), [dispatch, tenant]);

    // const onSignUpClick = () => {
    //     navigation.checkout(tenant.slug);
    // }

    useEffect(() => {
        if (!tainted && !reseted) {
            if (continueAsGuest && tenant.allow_guest) {
                guestClickHandler();
            }
        }
    }, [continueAsGuest, tenant, guestClickHandler, tainted, reseted]);

    // EXPLAIN: on mount do the following:
    // 1 - if the user is authenticated, then redirect to the main screen
    // 2 - if the user isn't authenticated, then try to recover the 
    //     session using a refresh token
    // 3 - if the session recovery fails, then show the UI in order to
    //     allow the user to sign-in
    useEffect(() => {
        if (authenticated) {
            console.log("REDIRECT: " + redirect);
            setInternalRedirecting(true);
            if (redirect === "tv-link") {
                navigation.tvLink();
            } else {
                navigation.landing();
            }
        } else {
            if (!tainted && !reseted && !continueAsGuest) {
                dispatch(actions.session.startSignIn());
                SessionService.recoverSession(tenant).then((profile) => {
                    if (profile.recovered) {
                        setInternalRedirecting(true);
                        updateStores(dispatch, profile);
                    } else {
                        dispatch(actions.session.recoverSessionFailed());
                        setInternalInProgress(false);
                    }
                }).catch((error) => {
                    log.debug(error);
                    dispatch(actions.session.failed(error.serialize()));
                    setInternalInProgress(false);
                });
            } else {
                if (!inProgress) {
                    setInternalInProgress(false);
                }
            }
        }
    }, [authenticated, continueAsGuest, reseted, navigation, tainted, tenant, dispatch, inProgress, redirect])

    const onResetPassword = useCallback(() => {
        navigation.recoverPassword(tenant.type !== "seller" && tenant.franchiseCode, tenant.slug);
    }, [navigation, tenant]);

    const onGoBack = useCallback(() => {
        if (tenant.type === "seller") {
            navigation.rtvLanding(tenant);
        }
    }, [navigation, tenant]);

    // console.log("----");
    // console.log(tainted);
    // console.log(reseted);
    // console.log(authenticated);
    // console.log(internalInProgress);
    // console.log(internalRedirecting);
    // console.log("----");

    const [defaultEmail, setDefaultEmail] = useState(prefillEmail || account);

    if (authenticated || internalInProgress || internalRedirecting) {
        return <LoadingWithText
            center={true}
            textSmall={intl.formatMessage({ id: "app.messages.loading_gym" })}
            textBig={intl.formatMessage({ id: "app.messages.loading_gym_more" })}
        />;
    }

    if (continueAsGuest && tenant.allow_guest && !error) {
        return (
            <LoadingWithText
                center={true}
                textSmall={intl.formatMessage({ id: "app.messages.loading_gym" })}
                textBig={intl.formatMessage({ id: "app.messages.loading_gym_more" })}
            />
        );
    }

    return (
        <LoginContainer tenant={tenant} justifyContent={justifyContent} goToRoot={goToRoot} title={title} subtitle={subtitle}>
            <div className={styles.fieldsContainer}>

                {tainted && (
                    <Form
                        buttonIsContinue={!!title}
                        redirect={redirect}
                        allowNonActive={allowNonActive}
                        tenant={tenant}
                        onResetPassword={onResetPassword}
                        defaultEmail={defaultEmail}
                        setDefaultEmail={setDefaultEmail}
                    />
                )}

                {!tainted && <RecoveringSession />}
            </div>
            <div className={styles.message}>
                {reseted && <div><FormattedMessage id="app.messages.session_expired" /></div>}
            </div>
        </LoginContainer>
    );
}

export default Login;