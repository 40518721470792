import React, { useLayoutEffect, useRef, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
    Switch,
    Route,
    useRouteMatch,
    useParams
} from "react-router-dom";

import AppHistory from "../AppHistory";
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import Outstander from "../Outstander";
import RadicalSwiper from "../RadicalSwiper";
import RadicalHeader from "../RadicalHeader";
import ItemDetail from "../ItemDetail";
import ItemContinue from "../ItemContinue";
import LoadingRadicalSwipers from "../LoadingRadicalSwipers"
import LibraryPlay from "../main/LibraryPlay";
import AppNotifications from "../AppNotifications"
import FreeTrialOffer from "../FreeTrialOffer";
import AppUserProfile from "../AppUserProfile";
import { actions, selectors } from "../../store"

import useRadicalNavigation from "./../../hooks/useRadicalNavigation";
import useImageSelector from "./../../hooks/useImageSelector";
import useSlideFromId from "./../../hooks/useSlideFromId";
import useFavFromSlideId from "./../../hooks/useFavFromSlideId";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import InstrumentationService from "./../../services/InstrumentationService";
import SessionService from "./../../services/SessionService";
import CollectionsService from "./../../services/CollectionsService";
import ProfileService from "./../../services/ProfileService";
import UxService from "./../../services/UxService";
import styles from './Library.module.scss';
import AppSubscription from '../AppSubscription';
import Environment from '../../services/Environment';

function Footer() {
    const year = (new Date()).getFullYear();

    return <div className={styles.footer}>
        {Environment.brand === "rhf" ? "© Radical Home" : "Radical Fitness"} - {year}
    </div>
}
function MultipleLibraryItemContinue({ slideId }) {
    const navigation = useRadicalNavigation();
    const trainingPlan = (useSelector(selectors.slides.trainings) || {})[slideId];
    const trainingPlanId = trainingPlan && trainingPlan.id;

    const onTrack = (itemId, { percent, seconds, lang, playSessionId }) => {
        InstrumentationService.Video.track(slideId, itemId, percent, seconds, playSessionId, lang, trainingPlanId);
    };

    const onClose = () => {
        navigation.main();
    }

    return <CSSTransition appear={true} in={true} timeout={200} classNames="popup-fade">
        <ItemContinue slideId={slideId} onClose={onClose} onTrack={onTrack} />
    </CSSTransition>
}

function LibraryItemContinue() {
    const { slideId } = useParams();

    const slide = useSlideFromId(slideId);

    if (slide.type === "video") {
        return <LibraryItemPlay itemId={slideId} slideId={slideId} />
    } else {
        return <MultipleLibraryItemContinue slideId={slideId} />
    }
}

function LibraryItemDetail({ }) {
    const { slideId } = useParams();
    const navigation = useRadicalNavigation();

    const dispatch = useDispatch();
    const selectedSeasons = useSelector(selectors.slides.selectedSeasons);
    const trainingPlan = (useSelector(selectors.slides.trainings) || {})[slideId];
    const trainingPlanId = trainingPlan && trainingPlan.id;
    const trainingPlanSeen = trainingPlan && (trainingPlan.seen || []);

    const fav = useFavFromSlideId(slideId);
    const slide = useSlideFromId(slideId);

    const single = (slide && slide.type === "video");
    const legacyImage = useImageSelector(slide && slide.images);
    const unavailableForGuest = slide.guest_unavailable;

    if (!slide) return <div />;

    const onClose = () => {
        navigation.main();
    }

    const onPlay = (videoId, restart) => {
        if (!slide.live) {
            navigation.library.play(slideId, videoId, restart);
        } else {
            window.open(slide.stream, '_blank');
        }
    }

    const onFav = (checked) => {
        ProfileService.favSlide(slideId, checked);
    }

    const onShare = () => {
        alert("share");
    }

    const season = (selectedSeasons[slideId]);
    const onSeasonChange = (season) => {
        dispatch(actions.slides.changeSeason(slideId, season));
    }

    // - both appear and in should be true for the animation to work on mount
    const name = UxService.localeValue(slide, "name")
    const description = UxService.localeValue(slide, "description")

    // const image = UxService.localeImage(slide, "images_slide") || legacyImage;
    const image = UxService.localeImageSet(slide, "images_slide", null, {
        xs: 148,
        s: 222,
        m: 296,
        l: 592,
        xl: 1184,
    });

    return <CSSTransition appear={true} in={true} timeout={200} classNames="popup-fade">
        <ItemDetail unavailableForGuest={unavailableForGuest} blurhash={image && image.blurhash} id={slideId} trainingPlanSeen={trainingPlanSeen} trainingPlanId={trainingPlanId} isTrainingPlan={slide.is_training_plan} metadata={slide.metadata} onShare={onShare} imageSrcSet={image && image.srcset} name={name} image={(image && image.default) || legacyImage} description={description} fav={fav} onFav={onFav} single={single} onClose={onClose} onPlay={onPlay} season={season} onSeasonChange={onSeasonChange} />
    </CSSTransition>
}

function LibraryFreeTrialOffer() {
    const navigation = useRadicalNavigation();

    const onClose = useCallback(() => {
        navigation.main();
    }, [navigation]);

    return <CSSTransition appear={true} in={true} timeout={200} classNames="popup-fade">
        <FreeTrialOffer onClose={onClose} />
    </CSSTransition>
}

function LibraryItemPlay({ itemId, slideId, playSessionId }) {
    const routeParams = useParams();
    const navigation = useRadicalNavigation();

    const playSlideId = slideId || routeParams.slideId;
    const playItemId = itemId || routeParams.itemId;
    const trainingPlan = (useSelector(selectors.slides.trainings) || {})[playSlideId];
    const trainingPlanId = trainingPlan && trainingPlan.id;

    const onTrack = ({ percent, seconds, lang, playSessionId }) => {
        InstrumentationService.Video.track(playSlideId, playItemId, percent, seconds, playSessionId, lang,  trainingPlanId);
    };

    const onClose = () => {
        if (playItemId === playSlideId) {
            navigation.main();
        } else {
            navigation.library.item(playSlideId);
        }
    }

    return <CSSTransition appear={true} in={true} timeout={200} classNames="popup-fade">
        <LibraryPlay slideId={playSlideId} videoId={playItemId} onClose={onClose} onTrack={onTrack} trainingPlanId={trainingPlanId} />
    </CSSTransition>
}
function AppOutstander() {
    const navigation = useRadicalNavigation();

    const outstanders = useSelector(selectors.collections.outstanders);
    const [currentIndex, setCurrentIndex] = useState(0);


    const urlMatch = useRouteMatch({
        path: "/library",
        strict: true,
        sensitive: true
    });
    const pause = !urlMatch.isExact;


    const onPrev = () => {
        setCurrentIndex((index) => {
            if (index <= 0) {
                return outstanders.length - 1;
            } else {
                return index - 1;
            }
        });
    };

    const onNext = () => {
        setCurrentIndex((index) => {
            if (index < outstanders.length - 1) {
                return index + 1;
            } else {
                return 0;
            }
        });
    }

    const onPlay = (slide) => {
        navigation.library.continue(slide.id);
    }

    const onMoreInfo = (slide) => {
        navigation.library.item(slide.id);
    }

    return <Outstander pause={pause} slides={outstanders} onPlay={onPlay} onMoreInfo={onMoreInfo} onPrev={onPrev} onNext={onNext} selectedIndex={currentIndex} />
}

function HeaderAlert() {
    const navigation = useRadicalNavigation();
    const license = UxService.Session.hooks.useLicense();
    const show = !!license.news?.text;
    const { user } = UxService.Session.hooks.useProfile() || {};
  
    const onPress = useCallback(() => {
      // if (license?.news?.link) {
        // navigation.offer();
        navigation.subscription(user, false);
        //}
    }, [user, navigation]);
  
    if (!show) {
        return null;
    }

    const { text } = license.news;

    return (
        <div className={styles.libraryAlert} onClick={onPress}>
            {text}
        </div>
    );
}

function Library() {
    const dispatch = useDispatch();

    const match = useRouteMatch();
    const hide = !match.isExact;
    const wasHidden = useRef(hide);
    const lastScroll = useRef();

    const profile = useSelector(selectors.session.profile);
    const navigation = useRadicalNavigation();


    const collections = useSelector(selectors.collections.all);
    const currentPage = useSelector(selectors.collections.currentPage);
    const lastPageReached = useSelector(selectors.collections.lastPageReached);
    const shouldFetchOnMount = useSelector(selectors.collections.shouldFetchOnMount);
    const fetching = useSelector(selectors.collections.fetching);
    const lastFetchFailed = useSelector(selectors.collections.lastFetchFailed);

    // console.log(match);
    // console.log("-------");
    // console.log(wasHidden.current);
    // console.log(hide);
    // console.log("------/");


    if (!wasHidden.current && hide) {
        // console.log("HIDDING!");
        // console.log(window.pageYOffset);
        lastScroll.current = window.pageYOffset;
        wasHidden.current = true;
    } else if (wasHidden.current && !hide) {
        // console.log("UNHIDDING!");
        // console.log(lastScroll.current);
        wasHidden.current = false;
    }

    useLayoutEffect(() => {
        if (hide) {
            //console.log("Library 0,0");
            window.scrollTo(0, 0);
        } else if (!hide && lastScroll.current) {
            //console.log("Library 0,lastScroll");
            window.scrollTo(0, lastScroll.current);
        }
    }, [hide]);

    const loadNextPage = useCallback(() => {
        CollectionsService.appendPage(currentPage + 1);
    }, [currentPage]);

    useEffect(() => {
        if (shouldFetchOnMount) {
            loadNextPage();
        }
    }, [loadNextPage, shouldFetchOnMount])

    const loader = useRef(null);

    const showLoading = shouldFetchOnMount || fetching;
    const mutableHandleObserver = useRef(null);

    mutableHandleObserver.current = () => {
        if (!showLoading && !lastPageReached && !lastFetchFailed) {
            loadNextPage();
        }
    }

    // - intersection observer
    useEffect(() => {
        var options = {
            root: null,
            rootMargin: "20px",
            threshold: 1.0
        };

        const handleObserver = (entities) => {
            const target = entities[0];
            if (target.isIntersecting && mutableHandleObserver.current) {
                mutableHandleObserver.current()
            }
        }

        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current)
        }
    }, [dispatch]);



    const style = hide ? {
        top: "-" + lastScroll.current + "px",
    } : {};

    const onProfile = () => {
        navigation.profile();
    };

    const onHistory = () => {
        navigation.history();
    };

    const onSearch = () => {
        navigation.search();
    };

    const onSignOut = () => {
        SessionService.signOut();
    };

    const onSubscription = () => {
        navigation.subscription(profile.user);
    };

    return (
        <div className="app">


            <div className="library">
                <div style={style} className={clsx({ hide: hide, unhide: !hide })}>
                    <RadicalHeader gymLogo={profile.branding.wide_img} onHistory={onHistory} onSubscription={onSubscription} onProfile={onProfile} onSearch={onSearch} onSignOut={onSignOut} gymName={profile.gym.name} />
                    <HeaderAlert />
                    <AppOutstander />

                    <div className="library-swipers">
                        {collections.length > 0 && collections.map((collection) => (
                            <RadicalSwiper large={collection.large} collection={collection} key={collection.id} />
                        ))}

                        {showLoading && <LoadingRadicalSwipers fetching={fetching} currentPage={currentPage} />}

                        <div className="scroll-loading" ref={loader} style={{ display: showLoading && !lastPageReached ? "none" : "initial" }}>
                            {lastFetchFailed && <div className={styles.loadingContentError} onClick={loadNextPage}>
                                <FontAwesomeIcon icon={faTimesCircle} />&nbsp;
                                <FormattedMessage id="app.messages.loading_content_error" />
                            </div>}
                        </div>

                        {lastPageReached && <Footer />}
                    </div>
                </div>

                <Switch>
                    <Route path={`${match.path}/profile`}>
                        <AppUserProfile />
                    </Route>
                    <Route path={`${match.path}/offer`}>
                        <LibraryFreeTrialOffer />
                    </Route>
                    <Route path={`${match.path}/account`}>
                        <AppSubscription />
                    </Route>
                    <Route path={`${match.path}/item/:slideId`}>
                        <LibraryItemDetail />
                    </Route>
                    <Route path={`${match.path}/continue/:slideId`}>
                        <LibraryItemContinue />
                    </Route>
                    <Route path={`${match.path}/play/:slideId/:itemId`}>
                        <LibraryItemPlay />
                    </Route>
                    <Route path={`${match.path}/notifications`}>
                        <AppNotifications />
                    </Route>
                    <Route path={`${match.path}/history`}>
                        <AppHistory />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}

export default Library;