import { FormattedMessage } from "react-intl";
import styles from "./SwiperTitle.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faPlus, faMinus, faHeart } from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx';
import Environment from '../../services/Environment';

function SwiperTitle({ title, onClick, expanded, showExpansion, isNew, showFavIcon }) {
    const handleClick = onClick ? onClick : () => { };
    return <div className={styles.rhfSwiperTitleContainer} >
        <div onClick={showExpansion ? handleClick : null}>
            <div className={clsx(styles.rhfSwiperTitle, showExpansion && styles.rhfSwiperTitleShowExpansion)}>{title} {showFavIcon && <FontAwesomeIcon icon={faHeart} />}</div>
        </div>
        {isNew && <div className={Environment.brand === "rhf" ? styles.isNew : styles.isNewRfTv}><FormattedMessage id="app.messages.is_new_title" /></div>}
        {showExpansion && <div className={styles.rhfSwiperTitleMore} onClick={handleClick}>
            <span>
                &nbsp;
                {expanded && <FontAwesomeIcon icon={faMinus} />}
                {!expanded && <FontAwesomeIcon icon={faPlus} />}
                {/*expanded && <FontAwesomeIcon icon={faChevronLeft} />}
                 &nbsp;
                <FormattedMessage id={`app.messages.${expanded ? "see_less" : "see_more"}`} />
                &nbsp;{!expanded && <FontAwesomeIcon icon={faChevronRight} />*/}
            </span>
        </div>}
    </div>
}

export default SwiperTitle;