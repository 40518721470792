import { configureStore } from '@reduxjs/toolkit'
import collectionsSlice from "./stores/collections";
import sessionSlice from "./stores/session";
import slidesSlice from "./stores/slides";
import progressSlice from "./stores/progress";
import favSlice from "./stores/fav";
import historySlice from "./stores/history";
import notificationsSlice from "./stores/notifications";
import localeSlice from "./stores/locale";

const slices = [
    collectionsSlice,
    sessionSlice,
    slidesSlice,
    progressSlice,
    historySlice,
    favSlice,
    notificationsSlice,
    localeSlice
];

const slicesReducers = {};
export const selectors = {};
export const actions = {};
export const propertySelectors = {};

slices.forEach((slice) => {
    slicesReducers[slice.name] = slice.reducer;
    actions[slice.name] = slice.actions;

    if (slice.selectors) {
        selectors[slice.name] = slice.selectors((state) => (state[slice.name]))
    }

    if (slice.propertySelectors) {
        propertySelectors[slice.name] = slice.propertySelectors((state) => (state[slice.name]))
    }
});

export const store = configureStore({
    reducer: {
        ...slicesReducers
    }
});

export const resetStore = () => {
    slices.forEach((slice) => {
        store.dispatch(slice.actions.reset());
    });
}


export const serializeErrorForAction = (error) => {
    if (error.serialize) {
      return error.serialize();
    }
  
    return {
      message: error.message,
      network: false,
      notFound: false,
      unexpected: true,
    };
  };
  
export const msgErrorForAction = (msg) => {
    const error = new Error(msg);
    return serializeErrorForAction(error);
};