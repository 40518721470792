import { createSlice, createSelector } from '@reduxjs/toolkit'

function serializeDate(d) {
    if (!d) return null;

    return d.toISOString().substring(0, 10);
}

function deserializeDate(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8, 10);

    return new Date(year, month - 1, day, 0, 0, 0, 0);
}

const initialState = {
    date: serializeDate(new Date()),
    view: "month"
};

const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        changeDate: {
            reducer: (state, action) => {
                state.date = action.payload.date;
            },
            prepare: (date) => {
                return { payload: { date: serializeDate(date) } }
            },
        },
        changeView: {
            reducer: (state, action) => {
                state.view = action.payload.view;
                state.date = action.payload.date || state.date;
            },
            prepare: (view, date) => {
                return { payload: { view, date: serializeDate(date) } }
            },
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    }
})

const selectors = (getRoot) => ({
    date: createSelector(getRoot, data => deserializeDate(data.date)),
    view: createSelector(getRoot, data => data.view),
});


historySlice.selectors = selectors;

export default historySlice;
