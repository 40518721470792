import { useState } from 'react';
import { useIntl, FormattedMessage } from "react-intl";
import styles from "./ShareLanding.module.scss";
import useImageSelector from '../../hooks/useImageSelector';
import ItemDetail from "../ItemDetail";
import { Helmet } from "react-helmet";
//import MaterialUiTheme from "../MaterialUiTheme"
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import UxService from "../../services/UxService";
import radicalLogo from '../../assets/logo_apaisado_RH.png';
import CollectionsService from "../../services/CollectionsService";
import Environment from '../../services/Environment';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: Environment.brand === "rhf" ? "#97bd45" : "#5fb1df",

        },
        text: {
            primary: "#fff"
        },
    },
    typography: {
        fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif;",
    },
    overrides: {

        MuiPaper: {
            root: {
                backgroundColor: "#333",
                fontSize: "16px",
                maxWidth: "200px",
            }
        },

        MuiIconButton: {
            root: {
                padding: "0px",
            }
        },
        MuiButton: {
            label: {
                fontSize: "12px",
            }
        },
        MuiButtonBase: {
            root: {
                margin: "0 10px",
            },

        },

        MuiTypography: {
            subtitle1: {
                fontSize: "24px",
            },
            h4: {
                fontSize: "20px",
            },
            h6: {
                fontSize: "16px",
            },
            body1: {
                fontSize: "14px",
                //fontWeight:"700",

            },
            body2: {
                fontSize: "12px",
            },
            caption: {
                fontSize: "12px !important",
                color: "#888 !important",
                fontWeight: "700 !important",
            },
            colorTextSecondary: {
                color: "#aaa",
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                //backgroundColor:"#e5e5e5",
            }
        }
    },
});

const InfoDialog = ({ open, onClose, onLogin }) => {
    return <Dialog onClose={onClose} open={open} fullWidth={true} classes={{ root: styles.MuiDialogRoot }}>
        <DialogTitle id="simple-dialog-title"> <FormattedMessage id="app.messages.shared_dialog_title" /></DialogTitle>
        <DialogContent>
            <DialogContentText>
                <FormattedMessage id="app.messages.shared_dialog_explain" />
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { }} variant="contained" color="primary">
                <FormattedMessage id="app.messages.shared_continue_to_log_in" />
            </Button>
            <Button onClick={() => { }} variant="contained" color="primary" autoFocus>
                <FormattedMessage id="app.messages.shared_i_want_an_account" />
            </Button>
        </DialogActions>
    </Dialog>
}
const ShareLanding = ({ gym, slide, onLogin }) => {
    const intl = useIntl();
    const [showPopUp, setShowPopUp] = useState();

    const onPlay = () => {
        setShowPopUp(true);
    };

    const [season, onSeasonChange] = useState();

    const legacyImg = useImageSelector(slide.images);
    const img = UxService.localeImage(slide, "images_slide") || legacyImg;
    const name = UxService.localeValue(slide, "name");
    const description = UxService.localeValue(slide, "description");

    return <ThemeProvider theme={theme}><div className={styles.root}>
        <Helmet>
            <title>{intl.formatMessage({ id: "app.messages.shared" }, { gymName: gym.name, label: name })}</title>
            <meta property="og:url" content={CollectionsService.shareUrlForSlide(gym, slide.id)} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={name} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={img} />
        </Helmet>

        <div className={styles.header}>
            <div className={styles.logosContainer}>
                <img src={gym.wide_img} className={styles.logo} alt="logo" />
                <img src={radicalLogo} className={styles.logo} alt="logo" />
            </div>

            <div className={styles.title}>
                <FormattedMessage id="app.messages.shared" values={{ gymName: gym.name, label: name }} />
            </div>
        </div>

        <ItemDetail id={slide.id} season={season} onSeasonChange={onSeasonChange} onPlay={onPlay} name={name} image={img} single={slide.type !== "list"} shared={true} />

        <InfoDialog open={showPopUp} onLogin={onLogin} onClose={() => (setShowPopUp(false))} />
    </div><div className={styles.imageTransition}></div></ThemeProvider>
}

export default ShareLanding;