// martin.burak@cipherastudio.com", "e1bc28"

import React, { useCallback } from 'react';
import styles from './LoginContainer.module.scss';
import Environment from "../../services/Environment";
import MaterialUiThemeLogin from "../MaterialUiThemeLogin";
import Button from '@material-ui/core/Button';
import useRadicalNavigation from '../../hooks/useRadicalNavigation';
import rhfLogo from '../../assets/logo_apaisado_RH.png';
import rfLogo from '../../assets/logo_apaisado_RF.png';
import { useIntl, FormattedMessage } from "react-intl";


function LoginContainer({ children, tenant, goToRoot, title, subtitle, justifyContent
}) {
    const navigation = useRadicalNavigation();

    const onGoBack = useCallback(() => {
        if (tenant.type === "seller") {
            navigation.rtvLanding(tenant);
        }
    }, [navigation, tenant]);

    const intl = useIntl();



    return (
        <MaterialUiThemeLogin>
            <div className={styles.loginContainer} >


                <div className={styles.loginImage} />

                <div className={!justifyContent ? styles.formContainer : styles.formContainerCheckout} style={{ borderLeftColor: Environment.brandColor }}>
                    {Environment.brand === "rhf" && <img onClick={goToRoot} src={rhfLogo} className={styles.logo} style={goToRoot ? { cursor: "pointer" } : {}} alt="logo" />}
                    {tenant.img && (
                        <div onClick={onGoBack} className={styles.logoContainer} style={tenant.type === "seller" ? { cursor: "pointer" } : {}}>
                            <img src={Environment.isTv ? rfLogo : tenant.img} alt={tenant?.name || "tenant logo"} className={Environment.isTv ? styles.logoImgSizeRf : styles.logoImgSize} />
                        </div>
                    )}
                    <div>
                        <div className={styles.sectionTitle}>{title}</div>
                    </div>

                    <div className={styles.textMessage}>{subtitle}</div>
                    {children}
                </div>


                {/* <div className={styles.cookiesWrapper}>
                    <div className={styles.cookiesContainer}>
                        <div className={styles.cookiesTitle}>{intl.formatMessage({ id: "app.titles.cookies_title" })}</div>

                        <div className={styles.cookiesAligment}>
                            <div className={styles.cookiesMessage}>
                                {intl.formatMessage({ id: "app.messages.cookies_message" })}
                            </div>
                            <div className={styles.cookiesButtonsContainer}>
                                <Button variant="contained" className={styles.cookiesButton}>
                                    {intl.formatMessage({ id: "app.buttons.cookies_accept" })}
                                </Button>
                                <Button variant="contained" className={styles.cookiesButton} >
                                    {intl.formatMessage({ id: "app.buttons.cookies_reject" })}
                                </Button>
                            </div>
                        </div>

                    </div>

                </div> */}



            </div>
        </MaterialUiThemeLogin >
    );
}

export default LoginContainer;