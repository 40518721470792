import { useQuery } from 'react-query';
import { useState } from 'react';
import { selectors } from "../../store"
import { useSelector } from 'react-redux'
import LibraryPlay from "./../main/LibraryPlay";
import CollectionsService from "../../services/CollectionsService";

import useMultiVideoProgress from "../../hooks/useMultiVideoProgress"
import styles from './ItemContinue.module.scss';

const Complete = ({ slideData, onClose, onTrack }) => {
    const ids = slideData.seasons.map((season) => (season.chapters.map((c) => c.id))).flat();
    const trainingPlan = (useSelector(selectors.slides.trainings) || {})[slideData.id];
    const trainingPlanId = trainingPlan && trainingPlan.id;
    const trainingPlanSeen = trainingPlan && (trainingPlan.seen || []);

    const videosProgress = useMultiVideoProgress(ids);
    const [startVideo] = useState(() => (
        CollectionsService.startVideoForslide(slideData, videosProgress, trainingPlanId, trainingPlanSeen))
    );
    
    return <LibraryPlay trainingPlanId={trainingPlanId} slideId={ slideData.id } videoId={ startVideo.videoId } onClose={onClose} onTrack={(progress) => {
        onTrack(startVideo.videoId, progress);
    }} />
}

const ItemContinue = ({ slideId, onClose, onTrack }) => {
    const continueQuery = useQuery(['collectionService:slide', slideId], () => CollectionsService.slide(slideId));

    return <>
        { continueQuery.isLoading && <div className={styles.root}>Loading ITEM CONTINUE</div>}
        { continueQuery.isError && <div className={styles.root}>ERROR ITEM CONTINUE</div>}
        { continueQuery.isSuccess && <Complete onClose={onClose} onTrack={onTrack} slideData={continueQuery.data} />}
    </>;
}

export default ItemContinue;