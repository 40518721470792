import styles from "./MultiSelect.module.scss";
import clsx from "clsx";
import { default as Select2 } from 'react-select';
import Environment from '../../services/Environment';

const MultiSelect = ({ label, value, options, error, onChange, disabled }) => {
    const showError = error;
    const curatedValue = value || [];

    const customStyles = {
        control: (provided, { isFocused, isSelected, isMulti }) => {

            const rv = { ...provided };
            rv.background = "#0b0b0b";

            if (showError) {
                rv.borderColor = "#f44336"
            }
            rv.borderBottomWidth = "2px";
            if (isFocused) {
                rv.borderBottomColor = Environment.brand === "rhf" ? "#97bd45" : "#5fb1df";
                rv["&:hover"] = Environment.brand === "rhf" ? "#97bd45" : "#5fb1df";

            }
            if (isSelected) {
                rv.borderBottomColor = Environment.brand === "rhf" ? "#97bd45" : "#5fb1df";
                rv.boxShadow = "0 !important";

            }


            rv.borderTop = "0px";
            rv.borderLeft = "0px";
            rv.borderRight = "0px";
            rv.borderRadius = "0px";
            rv.boxShadow = 'none';
            return rv;
        },

        valueContainer: (provided) => {
            const rv = { ...provided };
            rv.paddingLeft = 0;
            return rv;
        },

        placeholder: (provided, { isFocused }) => {
            const rv = { ...provided };
            if (showError) {
                rv.color = "#888";
            }
            rv.fontSize = "16px";
            return rv;
        },

        multiValueRemove: (provided) => {
            const rv = { ...provided };
            rv.color = "black"
            rv.cursor = "pointer"
            return rv;
        },
        multiValueLabel: (provided) => {
            const rv = { ...provided };
            rv.fontSize = "16px";
            return rv;
        },
        multiValue: (provided) => {
            const rv = { ...provided };
            rv.fontSize = "16px";
            return rv;
        },
        input: (provided) => {
            const rv = { ...provided };
            rv.fontSize = "16px";
            return rv;
        },

        option: (provided, { data, isDisabled, isFocused, isSelected }) => {
            const rv = { ...provided };
            rv.background = isFocused ? "#444444" : "#333333";
            return rv;
        },

        menu: (provided) => {
            const rv = { ...provided };
            rv.background = "#333333";
            rv.zIndex = "100000";
            rv.fontSize = "16px";
            return rv;
        },

        menuList: (provided) => {
            const rv = { ...provided };
            rv.background = "#333333";
            rv.zIndex = "100000";
            return rv;
        }
    }

    const handleChange = (values) => {
        onChange(values.map((e) => (e.value)));
    }

    const optionValues = curatedValue.map((v) => (options.find((o) => (o.value == v))));
    return <div className={styles.multiSelect}>
        {curatedValue.length > 0 && <span className={clsx(styles.multiSelectLabel, showError && styles.multiSelectLabelError)}>
            {label}
        </span>}
        <Select2
            disabled
            styles={customStyles}
            isMulti
            value={optionValues}
            onChange={handleChange}
            options={options}
            placeholder={label}
            className="basic-multi-select"
            classNamePrefix="select"
        />
        {showError && <div className={styles.multiSelectError}>
            {error}
        </div>}
    </div>
}


export default MultiSelect;