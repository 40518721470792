import { useIntl, FormattedMessage } from "react-intl";
import RadicalModal from "./../RadicalModal";
import styles from "./Subscription.module.scss";
import date from 'date-and-time';
import 'date-fns';
import MaterialUiTheme from "../MaterialUiTheme"
import CheckoutButton from "../CheckoutButton";
import SelfPanelableButton from "../SelfPanelableButton";

const ProfileData = ({ license, branding }) => {
    const intl = useIntl();

    const formatDate = (dateYMD) => {
        return intl.formatDate(date.parse(dateYMD, 'YYYY-MM-DD'), {
            dateStyle: "long"
        })
    }


    return <div >
        <div className={styles.infoContainer}>
            <div>
                {branding.img && <img className={styles.logo} alt="branding" src={branding.img} />}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {branding.free && <p><FormattedMessage id="app.profile.courtesy_by" values={{ name: branding.name }} /></p>}

                {!branding.free && (
                    <>
                        <div className={styles.itemContainer}>
                            <div className={styles.itemTitle}><FormattedMessage id="app.profile.provided_by" /></div>
                            <div className={styles.itemValue}>{branding.name}</div>
                        </div>

                        <div className={styles.itemContainer}>
                            <div className={styles.itemTitle}><FormattedMessage id="app.profile.account_type" /></div>
                            <div className={styles.itemValue}><FormattedMessage id={`app.profile.license_type.${license.license_type}`} /></div>
                        </div>
                        {license.in_trial &&
                            <div className={styles.itemContainer}>
                                <div className={styles.itemTitle}><FormattedMessage id="app.profile.license_in_trial" /></div>
                                <div className={styles.itemValue}></div>
                            </div>
                        }
                        {license.with_dates &&
                            <div className={styles.itemContainer}>
                                <div className={styles.itemTitle}><FormattedMessage id="app.profile.license_period" /></div>
                                <div className={styles.itemValue}>{formatDate(license.with_dates.from)} - {formatDate(license.with_dates.to)}</div>
                            </div>
                        }
                    </>
                )}
            </div>

        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
            {license.with_dates?.renewal && <p><FormattedMessage id="app.profile.license_renewal" values={{ date: formatDate(license.with_dates.renewal) }} /></p>}
            {!license.with_dates?.renewal && <p><FormattedMessage id="app.profile.license_will_end" values={{ date: formatDate(license.with_dates.to) }} /></p>}
            <div >
                {license.license_data?.checkoutable && (
                    <CheckoutButton license={license} slug={branding.slug} type={branding.type} franchiseCode={branding.franchise_code} />
                )}
                {(true || license.license_data?.self_panelable) && (
                    <SelfPanelableButton license={license} slug={branding.slug} type={branding.type} franchiseCode={branding.franchise_code} />
                )}
            </div>
        </div>
    </div>;
}

const Subscription = ({ license, selfServePortalEnabled, onCancel, branding }) => {
    console.log(license);
    return <MaterialUiTheme>
        <RadicalModal onClose={onCancel} title={<FormattedMessage id="app.titles.subscription" />}>
            <div className={styles.root}>
                <ProfileData branding={branding} license={license} />
            </div>
        </RadicalModal>
    </MaterialUiTheme>
}

export default Subscription;