import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit'
import Server from "../services/Server";

// function sleep(ms) {
//     // add ms millisecond timeout before promise resolution
//     return new Promise(resolve => setTimeout(resolve, ms))
// }

const appendPage = createAsyncThunk(
    'appendPage',
    (page, thunkAPI) => {
        return Server.Collections.page(page);
    }
);

const suggestions = createAsyncThunk(
    'suggestions',
    ({ slideId, videoId }, thunkAPI) => {
        return Server.Collections.suggestions(slideId, videoId);
    }
);

const translateCollections = (collections) => {
    return collections.map(collection => ({
        ...collection,
        slides: collection.slides.map((s) => (s.id))
    }));
}

const translateSlides = (slides) => {
    const rv = {}
    slides.forEach((s) => (rv[s.id] = s))
    return rv;
}

const translateCollectionsSlides = (collections) => {
    const rv = {}
    collections.map(collection =>
        collection.slides.forEach((s) => (rv[s.id] = s))
    );
    return rv;
}

const slideVideoId = ({ slideId, videoId }) => {
    return slideId + ":" + videoId;
}

const addSlide = (state, index, slideId) => {
    if (index >= 0) {
        state.loaded[index].slides = [slideId, ...state.loaded[index].slides.filter((sid) => (sid !== slideId))];
    }
}

const removeSlide = (state, index, slideId) => {
    if (index >= 0) {
        state.loaded[index].slides = [...state.loaded[index].slides.filter((sid) => (sid !== slideId))];
    }

}

const indexForCode = (state, code) => {
    return state.loaded.findIndex((l) => (l.code === code));
}

const initialState = {
    loaded: [],
    outstanders: [],
    currentPage: -1,
    fetch: { tainted: false },
    slides: {},
    suggestions: {}
};

const collectionsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        updateContinueWatchingWith: {
            reducer: (state, action) => {
                const slideId = action.payload.slideId;
                addSlide(state, indexForCode(state, "CW"), slideId);
            },
            prepare: (slideId) => {
                return { payload: { slideId } }
            },
        },
        removeFromContinueWatching: {
            reducer: (state, action) => {
                const slideId = action.payload.slideId;
                removeSlide(state, indexForCode(state, "CW"), slideId);
            },
            prepare: (slideId) => {
                return { payload: { slideId } }
            },
        },
        updateFavoritesWith: {
            reducer: (state, action) => {
                const slideId = action.payload.slideId;
                addSlide(state, indexForCode(state, "FV"), slideId);
            },
            prepare: (slideId) => {
                return { payload: { slideId } }
            },
        },
        removeFromFavorites: {
            reducer: (state, action) => {
                const slideId = action.payload.slideId;
                removeSlide(state, indexForCode(state, "FV"), slideId);
            },
            prepare: (slideId) => {
                return { payload: { slideId } }
            },
        },
        appendSlides: {
            reducer: (state, action) => {
                state.slides = { ...state.slides, ...translateSlides(action.payload.slides) };
            },
            prepare: (slides) => {
                return { payload: { slides } }
            },
        },
        updateOutstandersWith: {
            reducer: (state, action) => {
                state.outstanders = [...action.payload.outstanders];
                state.slides = { ...state.slides, ...translateSlides(action.payload.outstanders) };
            },
            prepare: (outstanders) => {
                return { payload: { outstanders } }
            },
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    },
    extraReducers: {
        [appendPage.pending]: (state, action) => {
            state.fetch.tainted = true;
            state.fetch.inProgress = true;
        },
        [appendPage.rejected]: (state, action) => {
            state.fetch.inProgress = false;
            state.fetch.success = false;
            state.fetch.error = action.error || action.payload;
        },
        [appendPage.fulfilled]: (state, action) => {
            state.fetch.inProgress = false;
            state.fetch.success = true;
            state.loaded = [...state.loaded, ...translateCollections(action.payload.collections)];
            state.slides = { ...state.slides, ...translateCollectionsSlides(action.payload.collections) };
            state.currentPage = action.meta.arg;
            state.fetch.lastPageReached = action.payload.lastPageReached;
        },
        [suggestions.pending]: (state, action) => {
            state.suggestions[slideVideoId(action.meta.arg)] = { inProgress: true }
        },
        [suggestions.rejected]: (state, action) => {
            state.suggestions[slideVideoId(action.meta.arg)] = { inProgress: false, success: false, error: action.error || action.payload}
        },
        [suggestions.fulfilled]: (state, action) => {
            state.suggestions[slideVideoId(action.meta.arg)] = { inProgress: false, success: true, slides: action.payload }
            state.slides = { ...state.slides, ...translateCollectionsSlides([{ slides: action.payload }])};
        },
    }
})


const selectors = (getRoot) => ({
    all: createSelector(getRoot, data => data.loaded),
    outstanders: createSelector(getRoot, data => data.outstanders),
    currentPage: createSelector(getRoot, data => data.currentPage),
    shouldFetchOnMount: createSelector(getRoot, data => data.currentPage < 0 && !data.fetch.tainted),
    fetching: createSelector(getRoot, data => data.fetch.inProgress),
    lastFetchFailed: createSelector(getRoot, data => data.fetch.tainted && !data.fetch.inProgress && !data.fetch.success),
    lastPageReached: createSelector(getRoot, data => data.fetch.lastPageReached),
});


const propertySelectors = (getRoot) => ({
    slide: () => {
        return createSelector(getRoot, (_, slideId) => (slideId), (root, slideId) => root.slides[slideId]);
    },
    suggestions: () => {
        return createSelector(getRoot, (_, args) => (args), (root, args) => root.suggestions[slideVideoId(args)]);
    },

});

collectionsSlice.selectors = selectors;
collectionsSlice.propertySelectors = propertySelectors;
collectionsSlice.actions.appendPage = appendPage;
collectionsSlice.actions.suggestions = suggestions;


export default collectionsSlice;
