import { createSlice, createSelector } from '@reduxjs/toolkit'

const initialState = {
    slides: {}
};

const favSlice = createSlice({
    name: 'fav',
    initialState,
    reducers: {
        updateSlide: {
            reducer: (state, action) => {
                state.slides[action.payload.slideId] = action.payload.isFav;
            },
            prepare: (slideId, isFav) => {
                return { payload: { slideId, isFav } }
            },
        },
        initialize: {
            reducer: (state, action) => {
                state.slides = { ...action.payload.slides};
            },
            prepare: (slides) => {
                return { payload: { slides } }
            },
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    }
})

const selectors = (getRoot) => ({
});

const propertySelectors = (getRoot) => ({
    forSlide: () => {
        return createSelector(getRoot, (_, slideId) => (slideId), (data, slideId) => data.slides[slideId] || false);
    }
});

favSlice.selectors = selectors;
favSlice.propertySelectors = propertySelectors;

export default favSlice;
