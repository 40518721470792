
import styles from './Suggestions.module.scss';
import { useIntl } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import UxService from "../../services/UxService";

function Suggestion({ suggestion, onSuggestionClick }) {
    const image = UxService.localeImage(suggestion, "images_slide")
    const name = UxService.localeValue(suggestion, "name");

    return <div className={styles.suggestion} key={suggestion.id} onClick={() => {
        onSuggestionClick(suggestion.id)
    }}>
        <div className={styles.image}>
            <img src={image} alt={name} />
            <FontAwesomeIcon icon={faPlayCircle} />
        </div>
        <div className={styles.name}>
            {name}
        </div>
    </div>
}
function Suggestions ({ suggestions, onSuggestionClick })  {
    const intl = useIntl();

    return <div className={styles.root}>
        <div className={styles.title}>{intl.formatMessage({ id: "app.messages.suggestions_you_can_try" })}</div>
        <div className={ styles.suggestions }>
            {suggestions.map((suggestion) => (<Suggestion suggestion={suggestion} onSuggestionClick={onSuggestionClick}/> ))}
        </div>
    </div>
}

export default Suggestions;