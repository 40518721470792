
import Server from "./Server";
import CollectionsService from "./CollectionsService";

const SearchService = {
    search(page, q, metadata) {
        return Server.Search.search(page, q, metadata).then((rv) => {
            if (rv.hits && rv.hits.length > 0) {
                CollectionsService.appendSlides(rv.hits.map((hit) => ({
                    id: hit.slide_id,
                    ...hit,
                })));
            }

            return rv;
        });
    }
}

export default SearchService;