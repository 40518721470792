import React from 'react';
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group';
import Welcome from "../Welcome";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import { selectors } from "../../store";

const AppWelcome = () => {
    const navigation = useRadicalNavigation();
    const profile = (useSelector(selectors.session.profile) || {});
    const isGuest = useSelector(selectors.session.isGuest);

    const onlyTyc = profile.welcomed && profile.tyc.update;
    const version = profile.tyc.version;

    const backToLibrary = () => {
        console.log("backToLibrary");
        navigation.main();
    };

    const onProfile = () => {
        navigation.profile();
    };

    return <CSSTransition appear={true} in={true} timeout={200} classNames="popup-profile-fade">
        <Welcome isGuest={isGuest} tycRaw={profile.tyc.text} onProfile={onProfile} onCancel={backToLibrary} onlyTyc={onlyTyc} tycVersion={version}/>
    </CSSTransition>
}

export default AppWelcome;