import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Subscription from "../Subscription";
import ProfileService from "../../services/ProfileService";
import SessionService from "../../services/SessionService";
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import { useIntl } from "react-intl";

import { selectors } from "../../store"
import { useSelector } from 'react-redux'


const AppSubscription = (props) => {
    const navigation = useRadicalNavigation();
    const backToLibrary = () => {
        navigation.main();
    };

    const license = useSelector(selectors.session.license);
    const branding = useSelector(selectors.session.branding);

    console.log("PEPE" + JSON.stringify(branding));

    return <CSSTransition appear={true} in={true} timeout={200} classNames="popup-profile-fade">
        <Subscription branding={branding} license={license} onCancel={backToLibrary} {...props} />
    </CSSTransition>
}

export default AppSubscription;