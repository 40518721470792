import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faExclamationTriangle, faRunning  } from '@fortawesome/free-solid-svg-icons'

import styles from './StartTrainingButton.module.scss';

function StartTrainingButton({ onClick, className, loading, error }) {
    return (
        <div onClick={loading ? null : onClick} className={styles.root + " " + (className ? className : "")}>
            {!loading && <FontAwesomeIcon icon={faRunning} />}
            {loading && <FontAwesomeIcon icon={faSpinner} pulse />}
            {loading && <FormattedMessage id="app.buttons.starting_training" />}
            {!loading && <FormattedMessage id="app.buttons.start_training" />}
            {error && <span className={styles.error}>
                <br />
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <FormattedMessage id="app.buttons.start_training_error" />
            </span>}
        </div>
    );
}

export default StartTrainingButton;