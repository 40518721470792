// import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser, faBell, faCalendar, faBars, faTrash, faArrowRight, faCircle } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { FormattedMessage, FormattedDate } from "react-intl";
import Popup from "reactjs-popup";
import { useSelector } from 'react-redux'
import { selectors } from "../../store"
import Badge from '@material-ui/core/Badge';

import styles from './RadicalHeader.module.scss';
import rhfLogo from '../../assets/logo_apaisado_RH.png';
import rfLogo from '../../assets/logo_apaisado_RF.png';
import UxService from "./../../services/UxService";
import ProfileService from "./../../services/ProfileService"
import useRadicalNavigation from "../../hooks/useRadicalNavigation";
import clsx from "clsx";
import { CSSTransition } from 'react-transition-group';
import MaterialUiTheme from "../MaterialUiTheme"
import Environment from "../../services/Environment";

import Flag from "react-flagpack";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useIntl } from "react-intl";
import { translations } from "./../../translations";
import SessionService from "../../services/SessionService";

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    icon: {
        display: "none",

    },
    select: {
        '&:before': {
            borderBottom: "0px",
            height: "0px",
        },
        '&:after': {
            borderBottom: "0px",
        },
        '&:hover': {
            borderBottom: "0px",
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: "0px",
        },
        width: "30px",
        '&.MuiSelect-select': {
            //paddingTop:"0px",
        }
    }

});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: Environment.brand === "rhf" ? "#97bd45" : "#5fb1df",

        },
        text: {
            primary: "#fff"
        },
    },
    typography: {
        fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif;",
    },
    overrides: {
        // Style sheet name ⚛️
        /*MuiInput:{
            underline:{
                '&:before': {
                    borderBottom:"0px",
                },
                '&:after': {
                    borderBottom:"0px",
                },
                '&:hover': {
                    borderBottom:"0px",
                },
                '&:hover:not(.Mui-disabled):before': {
                    borderBottom:"0px",
                },

            },
        },
        MuiSvgIcon:{
            root:{
                display:"none",
            }
        },
        MuiSelect:{
            select:{
                '&.MuiSelect-select':{
                    padding:"0px",
                }

            }
        },*/


    },
});

const localeToFlag = (locale) => {
    if (locale === "en") {
        return "US";
    }

    if (locale === "es") {
        return "ES";
    }

    if (locale === "fr") {
        return "FR";
    }

    if (locale === "ja") {
        return "JP";
    }

    if (locale === "zh") {
        return "TW";
    }

    return "US";
}
const LocaleSelect = ({ value, onChange }) => {
    const classes = useStyles();
    const intl = useIntl();
    const label = "";

    const id = label;
    const labelId = `${label}-id`;

    const options = Object.keys(translations).map((locale) => ({
        value: locale,
        label: intl.formatMessage({ id: `app.locales.${locale}` }),
    }));

    return <div className={styles.control}>

        <Select
            labelId={labelId}
            id={id}
            value={value || ""}
            onChange={(event) => (onChange(event.target.value, window.location.reload()))}
            classes={{ icon: classes.icon, select: classes.select }}
            className={classes.select}
        >
            {options.map((o) => (

                <MenuItem key={o.value} value={o.value}>
                    <div className={styles.selectMenuItem}>
                        <Flag code={localeToFlag(o.value)} />
                    </div>
                </MenuItem>
            ))}
        </Select>
    </div>;

}

function Notification({ notification, onDismiss, handleClose }) {
    const navigation = useRadicalNavigation();
    const showImg = notification.link && notification.link.slide_data;
    const image = showImg && UxService.localeImageSet(notification.link.slide_data, "images_slide", null, {
        xs: 148,
        s: 222,
        m: 296,
        l: 592,
        xl: 1184,
    });

    const follow = () => {
        if (notification.link) {
            if (notification.link.internal) {
                navigation.link(notification.link);
            } else {
                window.open(notification.link.to, "_blank");
            }
        }
        handleClose();
    }

    return <div key={notification.id} className={styles.menuItem}>

        <div className={styles.text}>
            <div className={clsx(styles.title, notification.link && styles.titleLink)} onClick={follow}>{UxService.localeValue(notification, "title")}</div>

            {showImg && <div className={styles.img} onClick={follow}>
                <img src={image.default} srcSet={image.srcset} alt="preview" sizes="20vw" />
            </div>}

            <div className={styles.date} onClick={follow}><FormattedDate value={UxService.formatDate(notification.date)} /></div>
        </div>

        <div className={styles.actions}>
            <FontAwesomeIcon icon={faTrash} onClick={() => onDismiss(notification.id)} />
        </div>

    </div>
}

function MoreNotifications() {
    const navigation = useRadicalNavigation();

    return <div key="more-notifications" className={styles.menuItem} onClick={() => (navigation.notifications())}>
        <FormattedMessage id="app.messages.more_notifications" />
    </div>
}

function NoNotifications() {
    return <div key="no-notifications" className={styles.menuItem} >
        <FormattedMessage id="app.messages.no_notifications" />
    </div>
}

function NotificationsBadge({ children, side }) {
    const all = useSelector(selectors.notifications.all);

    const badgeContent = all.length < 100 ? all.length : "99+"



    if (side) {
        if (badgeContent !== 0) {
            return <span className={Environment.brand === "rhf" ? styles.round : styles.roundRf}>{badgeContent}</span>
        } else {
            return "";
        }
    }

    return <Badge badgeContent={badgeContent !== 0 ? badgeContent : null}>
        {children}
    </Badge>
}

function NotificationsMenu({ onlyIfNotification }) {
    const navigation = useRadicalNavigation();

    const handleClose = () => {
    };

    const all = useSelector(selectors.notifications.all);
    const notifications = useSelector(selectors.notifications.short);

    const onDismiss = (notificationId) => {
        // - it's a best effor thing
        ProfileService.dismissNotification(notificationId);
    };

    const bell = <FontAwesomeIcon onClick={() => (navigation.notifications())} icon={faBell} />;

    if (onlyIfNotification && notifications.length <= 0) {
        return "";
    } else if (onlyIfNotification && notifications.length > 0) {
        return bell;
    }


    const trigger = <NotificationsBadge>
        {bell}
    </NotificationsBadge>

    return <Popup
        trigger={<span><NotificationsBadge><FontAwesomeIcon /> {bell}</NotificationsBadge></span>}
        position="bottom right"
        on="hover"
        closeOnDocumentClick
        closeOnEscape
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: '0px', border: 'none' }}
        arrow={false}

    >
        <div className={styles.menu}>
            {notifications.length > 0 && notifications.map((n) => (<Notification onDismiss={onDismiss} handleClose={handleClose} notification={n} key={n.id} />))}
            {notifications.length <= 0 && <NoNotifications />}
            {all.length > notifications.length && <MoreNotifications />}
        </div>
    </Popup>
}

function ProfileMenu({ onSignOut, onProfile, isGuest, onSubscription }) {
    const handleClose = () => {

    };

    return (
        <Popup
            trigger={<span><FontAwesomeIcon icon={faUser} /></span>}
            position="bottom right"
            on="hover"
            closeOnDocumentClick
            closeOnEscape
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            contentStyle={{ padding: '0px', border: 'none' }}
            arrow={false}
        >
            <div className={styles.menu}>
                {!isGuest && (
                    <div className={styles.menuItem} onClick={() => {
                        handleClose();
                        onProfile();
                    }}>
                        <FormattedMessage id="app.titles.profile" />
                    </div>
                )}

                {!isGuest && onSubscription && (
                    <div className={styles.menuItem} onClick={() => {
                        handleClose();
                        onSubscription();
                    }}>
                        <FormattedMessage id="app.titles.subscription" />
                    </div>
                )}


                <div className={styles.menuItem} onClick={() => {
                    handleClose();
                    onSignOut();
                }}>
                    <FormattedMessage id="app.titles.sign_out" />
                </div>
            </div>
        </Popup>
    );
}

function SideMenu({ open, onSubscription, onClose, onSignOut, onProfile, isGuest }) {
    const navigation = useRadicalNavigation();
    const locale = useSelector(selectors.locale.currentLocale);

    if (!open) return <div />;

    return <CSSTransition appear={true} in={true} timeout={200} classNames="side-menu-fade">
        <div className={styles.sideMenu}>
            <div className={styles.margin} onClick={onClose}>
            </div>
            <div className={styles.selectables}>
                <div className={styles.close}>
                    <span className={styles.close} onClick={onClose}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </span>
                </div>
                <div className={styles.menuItem} onClick={() => {
                    navigation.search()
                    onClose();
                }}>


                    <div className={styles.menuItemText}><FormattedMessage id="app.titles.search_short" /></div> <div><FontAwesomeIcon icon={faSearch} /></div></div>
                {!isGuest &&
                    <div className={styles.menuItem} onClick={() => {
                        navigation.history()
                        onClose();
                    }}>
                        <div className={styles.menuItemText}><FormattedMessage id="app.titles.history" /></div> <div><FontAwesomeIcon icon={faCalendar} /></div></div>
                }
                {!isGuest &&
                    <div className={styles.menuItem} onClick={() => {
                        navigation.notifications()
                        onClose();
                    }}>
                        <NotificationsBadge side />
                        <div className={styles.menuItemText}><FormattedMessage id="app.titles.notifications" /></div>
                        <div>
                            <FontAwesomeIcon icon={faBell} />
                        </div>
                    </div>
                }

                {!isGuest &&
                    <div className={styles.menuItem} onClick={() => {
                        onProfile();
                        onClose();
                    }}>
                        <div className={styles.menuItemText}><FormattedMessage id="app.titles.profile" /></div>
                        <div><FontAwesomeIcon icon={faUser} /></div>
                    </div>
                }

                {!isGuest && onSubscription && (
                    <div className={styles.menuItem} onClick={() => {
                        onSubscription();
                        onClose();
                    }}>
                        <div className={styles.menuItemText}><FormattedMessage id="app.titles.subscription" /></div>
                        <div><FontAwesomeIcon icon={faUser} /></div>
                    </div>
                )}

                <div className={styles.menuItem} onClick={() => {
                    onSignOut();
                    onClose();
                }}>
                    <div className={styles.menuItemText}><FormattedMessage id="app.titles.sign_out" /></div> <div><FontAwesomeIcon icon={faArrowRight} /></div></div>
            </div>
        </div>
    </CSSTransition>
}
function RadicalHeader({ gymName, gymLogo, debug, onSignOut, onSearch, onProfile, onHistory, onSubscription }) {
    const refContainer = useRef();
    const logo = Environment.brand === "rhf" ? rhfLogo : false;
    const logoGym = Environment.brand === "rhf" ? gymLogo : rfLogo;
    const locale = useSelector(selectors.locale.currentLocale);
    const isGuest = useSelector(selectors.session.isGuest);


    const [sideMenuOpen, setSideMenuOpen] = useState(false);

    useEffect(() => {
        const handler = (e) => {
            let alpha = 1.0;
            const max = 400.0;
            if (window.pageYOffset < max) {
                alpha = 0.7 + window.pageYOffset / (max * 10);
            }
            if (refContainer.current) {
                if (alpha === 0.7) {
                    refContainer.current.style.background = "transparent";
                    refContainer.current.style.background = "linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0))";
                } else {
                    //refContainer.current.style.background = "rgba(0,0,0," + alpha + ")";
                    refContainer.current.style.backgroundColor = "#222222";
                }

            }
        }

        document.addEventListener('scroll', handler);
        return () => {
            document.removeEventListener('scroll', handler);
        }
    });

    return <ThemeProvider theme={theme}><div className={styles.root} ref={refContainer}>
        <SideMenu isGuest={isGuest} open={sideMenuOpen} onSubscription={onSubscription} onProfile={onProfile} onSignOut={onSignOut} onClose={() => (setSideMenuOpen(false))} />
        <div className={styles.left}>
            <div className={styles.logo}>
                {gymLogo && <img className={Environment.brand === "rhf" ? styles.logoGymImg : styles.logoSellerImg} src={logoGym} alt="logo"></img>}
                {!gymLogo && <div className={styles.radical}>{gymName}</div>}
                {/* <div className={styles.radical}> <FormattedMessage id="app.radical_home_fitness_title" /></div>
            <div className={styles.by}> <FormattedMessage id="app.radical_home_fitness_by" values={{ gymName }}/></div> */}
            </div>
        </div>

        <div className={styles.right}>
            {logo && <img className={styles.logoRadicalImg} src={logo} alt="logo"></img>}
            <div>

                <div className={styles.hamburgerMenuContainer}>
                    {/* <NotificationsMenu onlyIfNotification/> */}
                    <span className={styles.flag}><LocaleSelect value={locale} onChange={(locale) => { SessionService.setLocale(locale); }} /></span>
                    <span onClick={() => (setSideMenuOpen(true))}><FontAwesomeIcon icon={faBars} />
                        <div className={styles.notificationBadge}><NotificationsBadge side /></div>
                    </span>



                </div>
                <div className={styles.toolbar}>
                    <span><LocaleSelect value={locale} onChange={(locale) => { SessionService.setLocale(locale); }} /></span>
                    <span onClick={onSearch}><FontAwesomeIcon icon={faSearch} /></span>
                    {!isGuest && <span onClick={onHistory}><FontAwesomeIcon icon={faCalendar} /></span>}
                    {!isGuest && <MaterialUiTheme><NotificationsMenu /></MaterialUiTheme>}
                    <ProfileMenu onSubscription={onSubscription} onSignOut={onSignOut} onProfile={onProfile} isGuest={isGuest} />
                </div>
            </div>
        </div>


    </div></ThemeProvider>
}

export default RadicalHeader;